import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import cogoToast from 'cogo-toast';
import React, { Fragment, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Badge, Modal, ModalHeader } from 'reactstrap';
import PageHeader from '../../components/common/PageHeader';
import MainTable from '../../components/tables/MainTable';
import { paramsServices } from '../../services';
import ParamsForm from './ParamsForm';

const ParamsPage = ({ tabla }) => {
  const [initialdata] = useState({
  })
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ListData, setListData] = useState([]);
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialdata);
  const [data, setData] = useState({ columns: [] });
  const [cols, setCols] = useState([{ dataField: "", text: "" }]);
  const [currentSearch, setCurrentSearch] = useState("");
  const [keyTable, setKeyTable] = useState("");

  useEffect(() => {
    load();
    /*setCurrentSearch("");
    document.getElementById("fieldFilter").value = "";*/
  }, [/*window.location.href*/])

  const stateFormatter = status => {
    let color = '';
    let icon = '';
    let text = '';
    switch (String(status)) {
      case '1':
        color = 'success';
        icon = 'check';
        text = 'Activo';
        break;
      case '0':
        color = 'secondary';
        icon = 'ban';
        text = 'Inactivo';
        break;
      default:
        color = 'warning';
        icon = 'stream';
        text = 'Pendiente';
    }
    return (
      <Badge color={`soft-${color}`} className="rounded-capsule">
        {text}
      </Badge>
    );
  };

  const listFormatter = (val, col) => {
    return val ? col.options.find(i => i.value == val).label : "";
  };

  const actionsFormatter = (dataField, selected) => {
    if (!selected) return <></>
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        <Tooltip title="Editar">
          <IconButton
            onClick={() => actiononContact('edit', selected)}
          >
            <FontAwesomeIcon
              style={{ width: '20px' }}
              icon={faEdit}
              color="SteelBlue"
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton
            onClick={() => handleRemove(selected)}
          >
            <FontAwesomeIcon
              style={{ width: '20px' }}
              icon={faTrash}
              color="red"
            />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  let table = "";

  const getKeyTable = () => {
    //const str = window.location.href;
    const str = tabla;
    return str.substring(str.lastIndexOf('/') + 1);
  }

  const load = async () => {
    setLoading(true);

    table = getKeyTable();
    setKeyTable(table);

    const response = await paramsServices.getData(table);

    if (response.error) {
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    const columns = [];
    for (let i = 1; i < response.objResponse.columns.length; i++) {
      const reg = response.objResponse.columns[i];
      const col = {
        dataField: reg.columnName,
        text: reg.name,
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
        sort: true
      };
      if (reg.type == "CHECK") {
        col.formatter = val => stateFormatter(val);
      } else if (reg.type == "REF") {
        col.formatter = val => listFormatter(val, reg);
        const list = (await paramsServices.getData(reg.ref.table)).objResponse.data;
        reg.options = [];
        for (let j = 0; j < list.length; j++) {
          reg.options.push({
            record: list[j],
            value: list[j][0],
            label: list[j][reg.ref.index],
          });
        }
      } else if (reg.type == "ENUM") {
        col.formatter = val => listFormatter(val, reg);
        reg.options = [];
        for (let i in reg.enums) {
          reg.options.push({
            value: i,
            label: reg.enums[i],
          });
        }
      }
      columns.push(col);
    }
    columns.push({
      dataField: '',
      headerClasses: 'border-0',
      text: 'Acciones',
      classes: 'border-0 py-2 align-middle',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
      align: 'center',
      headerAlign: 'center'
    });

    setCols(columns);
    setData(response.objResponse);

    const data = [];
    for (let i = 0; i < response.objResponse.data.length; i++) {
      const item = response.objResponse.data[i];
      const row = { id: item[0] };
      for (let j = 1; j < response.objResponse.columns.length; j++) {
        row[response.objResponse.columns[j].columnName] = item[j];
      }
      data.push(row);
    }
    setListData(data);

    setLoading(false);
  }

  const MostrarFormulario = (e) => {
    setShowModal(false);
    setEstadoForm(EstadoForm);
    /*if (e === 'Nuevo') {
    } else */if (e === 'Cancelar') {
      setDataEdict(initialdata);
    } else if (e === 'Cargar') {
      load();
    } else if (e === 'Guardado') {
      setDataEdict(initialdata);
      load();
    } else if (e.perfil) {
      setDataEdict(e);
    }
  }

  const toggleModal = () => {
    setShowModal(false);
    MostrarFormulario("Cancelar");
  }

  const Consultar = async filter => {
    setCurrentSearch(filter);
    const filtered = [];
    for (let i = 0; i < data.data.length; i++) {
      const item = data.data[i];
      const row = { id: item[0] };
      let include = false;
      for (let j = 1; j < data.columns.length; j++) {
        const col = data.columns[j];
        let value = item[j];
        row[col.columnName] = value;
        if (!include && value != null) {
          value = col.type == "REF" || col.type == "ENUM" && value ? col.options.find(i => i.value == value).label
            : col.type == "CHECK" ? value ? 'activo' : 'inactivo' : value;
          if (String(value).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1) {
            include = true;
          }
        }
      }
      if (include) {
        filtered.push(row);
      }
    }
    setListData(filtered);
  }

  const actiononContact = async (action, e = null) => {
    setShowModal(true);
    setEstadoForm(!EstadoForm);
    /*if (action === 'add') {
    } else */if (action === 'edit') {
      setDataEdict(e);
    }
  };

  const handleRemove = row => {
    confirmAlert({
      title: 'Eliminar registro',
      message: '¿Desea eliminar el elemento seleccionado?',
      buttons: [
        {
          label: 'Sí',
          onClick: () => removeParam(row)
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const removeParam = async row => {
    const response = await paramsServices.delParam(getKeyTable(), row.id);

    if (response.error) {
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    cogoToast.info("Se eliminó el registro satisfactoriamente", { position: 'bottom-right' });

    load();
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} size="md" toggle={() => toggleModal()}>
        <ModalHeader>
          <div className="row pt-3 ">
            <div className="flex col-md-12">
              <h3>{DataEdict?.id ? "Editar" : "Agregar"} {data.name || ""}</h3>
            </div>
          </div>
        </ModalHeader>
        <ParamsForm
          MostrarFormulario={data => MostrarFormulario(data)}
          data={DataEdict}
          table={data}
          actiononContact={(action, data) => actiononContact(action, data)}
          keyTable={keyTable}
        />
      </Modal>
      <PageHeader
        title={data.name || ""}
        className="mb-3"
      />
      <MainTable
        data={ListData}
        columns={cols}
        keyField="id"
        handleAddNew={actiononContact}
        search={Consultar}
        className="px-2"
        loading={loading}
        currentSearch={currentSearch}
        pageSize="15"
        defaultSorted={[{ dataField: cols[0]?.dataField, order: 'asc' }]}
      />
    </Fragment>
  )

}

export default ParamsPage;
