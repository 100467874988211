import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const getAllCountries = (reqBody) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/pais/nom/${sesionToken}/*`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(res => res)
    .then(res => {
        return res.status === 0 ? res.objResponse : {error: true, msg: res.objError ? res.objError.msgError : res.message}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

export const worldServices = {
  getAllCountries,
}