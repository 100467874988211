import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cogoToast from 'cogo-toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr from 'react-flatpickr';
import { useForm } from 'react-hook-form';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import { EnvironmentContext } from '../../../context/Context';
import { InitCreditContext } from '../InitCreditContext';
// import RecaudoInvoice from '../invoice/RecaudoInvoice';
import MainTable from '../../../components/tables/MainTable';
import { getLocaleCalendarConfig } from '../../../helpers/utils';
import { creditServices } from '../../../services';

function InitCreditClientModal({ open, toggle, data }) {
  const context = useContext(InitCreditContext);
  const environment = useContext(EnvironmentContext);
  const { client } = context;
  const { setValue } = useForm();

  const [requesting, setRequesting] = useState();
  const [dataTable, setDataTable] = useState([]);

  const [invoiceProps, setInvoiceProps] = useState();
  const [success, setSuccess] = useState();

  const [periodoTyping, setPeriodTyping] = useState();
  const [dateTyping, setDateTyping] = useState();

  useEffect(() => {
    if (!data) return;
    setSuccess(null);
    setInvoiceProps(null);
    loadDataTable();
  }, [data])

  useEffect(() => {
    if (!periodoTyping) return;
    onChangeRecaudo(periodoTyping);
  }, [periodoTyping])

  useEffect(() => {
    if (!dateTyping) return;
    loadDataTable(null, dateTyping);
  }, [dateTyping])

  const columns = [
    {
      dataField: 'periodo',
      text: 'Periodo',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha Limite',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'fechaPago',
      text: 'Fecha Pago',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (dataField, selected) => dateInputFormatter(selected, dataField),
    },
    {
      dataField: '',
      text: 'Pago',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (dataField, selected) => currencyInputFormatter(selected, dataField),
    },
  ]

  const table = useRef();

  const currencyInputFormatter = (credit) => {
    return (
      <div className='pr-4'>
        <CurrencyInput
          className="form-control pl-2"
          allowNegativeValue={false}
          //name="recaudoValue"
          id={"recaudoValue" + credit.periodo}
          value={credit.pago}
          prefix='$'
          intlConfig={{ locale: 'es-CO', currency: 'COP' }}
          onValueChange={(e) => setPeriodTyping({ value: e || '0', id: credit.periodo })}
          decimalScale={2}
          maxLength={20}
          placeholder="$ 0.00"
          tabIndex="1"
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              const el = document.getElementById("payDate" + (credit.periodo + 1));
              if (!el) {
                if (table.current.nextPage()) {
                  setTimeout(() => {
                    const el = document.getElementById("payDate" + (credit.periodo + 1));
                    if (el) {
                      el._flatpickr.altInput.focus();
                    }
                  });
                }
              } else {
                el._flatpickr.altInput.focus();
              }
            }
          }} />
      </div>
    )
  }

  const dateInputFormatter = (credit) => {
    return (
      <div className='pr-4'>
        <Flatpickr
          tabIndex="1"
          className="form-control pl-2"
          placeholder='aaaa-mm-dd'
          //name="payDate"
          id={"payDate" + credit.periodo}
          value={credit.fechaPago}
          options={{
            dateFormat: "Y-m-d",
            altFormat: "Y-m-d",
            locale: getLocaleCalendarConfig(),
            allowInput: true,
            altInput: true,
            onClose: (date, dateStr) => {
              setDateTyping({ value: dateStr, id: credit.periodo });
              document.getElementById("recaudoValue" + credit.periodo).focus();
            }
          }}
        //onChange={}
        />
      </div>
    )
  }

  const onChangeRecaudo = (inputData) => {
    const { value, id } = inputData
    let valueParsed = !value ? 0 : value.replaceAll(',', '.');
    loadDataTable({ value: valueParsed, id });
  }

  const loadDataTable = async (inputValue, dateInput) => {
    let tempDataTable
    if (!inputValue && !dateInput) {
      const response = await creditServices.getCreditProyeccion(data.prestamo);
      if (!response || response.error) {
        cogoToast.error(response.msg,
          { position: 'bottom-left', heading: 'Error al cargar proyección del crédito' });
        return;
      }
      var temp = localStorage.getItem("inicializacion" + data.prestamo);
      if (temp) {
        temp = JSON.parse(temp);
      }

      tempDataTable = response.objResponse.map(item => ({
        periodo: item.periodo,
        fecha: item.fecha,
        fechaPago: temp?.[item.periodo]?.[0] || '',
        pago: temp?.[item.periodo]?.[1] || '',
        interes: item.interes,
      }));
    } else if (dateInput) {
      tempDataTable = dataTable.map(item => {
        return ({
          periodo: item.periodo,
          fecha: item.fecha,
          fechaPago: item.periodo == dateInput.id ? dateInput.value : item.fechaPago,
          pago: item.pago,
          interes: item.interes,
        });
      });
    } else {
      tempDataTable = dataTable.map(item => {
        return ({
          periodo: item.periodo,
          fecha: item.fecha,
          fechaPago: item.fechaPago,
          pago: item.periodo == inputValue.id ? `${inputValue.value}`.replaceAll('.', ',') : item.pago,
          interes: item.interes,
        });
      });
    }
    setDataTable(tempDataTable);
  };

  // const createInvoiceProps  = (payload) => {
  //   const selectedConcept = payload.isCash 
  //     ? cashConcepts.filter(concept => concept.value == payload.conceptoTesoreria)
  //     : [];
  //   const montoWord = numToWord(payload.valorRecaudo)


  //   const tempOwnProps = {
  //     client: data.nombreTercero,
  //     clientTypeId: client.terceroInformacion.tipoIdentificacion,
  //     clientId: client.terceroInformacion.identificacion ? formatterId(Number(client.terceroInformacion.identificacion)) : "",
  //     serial: payload.consecutivo,
  //     ciudad: payload.ciudad,
  //     idCredit: data.credito,
  //     amount: currencyFormat(payload.valorRecaudo || 0),
  //     concept: selectedConcept[0] ? selectedConcept[0].label : "",
  //     sumWord: montoWord || "",
  //     checkCash: payload.isCash,
  //     checkWireTransfer: payload.isWireTransfer,
  //     checkPSE: false,
  //     checkCheque: payload.isCheque,
  //     date: getTodayStr().split('-'),
  //     observations: payload.observacion
  //   }

  //   setInvoiceProps(tempOwnProps)
  // }

  const onChange = async e => {
    let { name, value } = e;
    setValue(name, value);
  }

  const onRecaudo = async () => {
    const payload = {
      prestamo: data.prestamo,
      recaudos: dataTable.filter(p => p.fechaPago).map(p => {
        const valorRecaudo = p.pago ? Number(p.pago.replaceAll(',', '.')) : 0
        const abonoCapital = valorRecaudo < p.interes ? 0 : valorRecaudo - p.interes;
        const abonoInteres = valorRecaudo < p.interes ? valorRecaudo : p.interes;
        return ({
          periodo: p.periodo,
          valorRecaudo,
          abonoCapital,
          abonoInteres,
          fecha: p.fechaPago,
        });
      })
    };

    setRequesting(true);

    const response = await creditServices.setInitCredit(payload);
    if (!response || response.error) {
      cogoToast.error(response.msg || "No se logro completar la operación",
        { position: 'bottom-left', heading: 'Error' });
      setRequesting(false);
      return;
    }

    cogoToast.success("Inicializar crédito",
      { position: 'bottom-left', heading: 'Operación exitosa' });
    setRequesting(false);
    toggle(true);
  }

  const onOk = () => {
    setInvoiceProps(null);
    setSuccess(false);
    toggle(true);
  }

  const incompleto = () => {
    return dataTable.find(i => (!i.pago || !i.fechaPago)
      && (i.periodo < dataTable.length
        || Math.round(Math.abs((new Date() - new Date(i.fecha)) / (24 * 60 * 60 * 1000))) > 30)
    ) != null;
  }

  const guardar = () => {
    var temp = {};
    dataTable.forEach(i => {
      temp[i.periodo] = [i.fechaPago, i.pago];
    });
    localStorage.setItem("inicializacion" + data.prestamo, JSON.stringify(temp));
    cogoToast.success("Guardar crédito", { position: 'bottom-left', heading: 'Operación exitosa' });
    toggle();
  }

  return (
    <Modal size="lg" isOpen={open}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={onOk}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
        <h5>{`Inicializar Crédito - ${data ? data.credito : ''}`}</h5>
        <span className='text-primary' style={{ fontSize: '0.8em' }}>{data ? data.nombreTercero : ''}</span>
      </ModalHeader>
      <ModalBody className="p-0">
        {requesting
          ? (
            <Row className="py-4 d-flex justify-content-center h-100">
              <Spinner color="primary" />
            </Row>
          ) : (
            <>
              {success ? (
                <div className="text-center mt-4">
                  <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success mr-1 fs-8" />
                  <h6 className="fs-2 m-3">
                    Inicializar Crédito Exitoso!
                  </h6>
                  <p className="fs--1 mb-0"></p>
                  {/* {invoiceProps && invoiceProps.checkCash && (
                      <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
                        <PDFDownloadLink document={<RecaudoInvoice title="Recibo de caja" data={invoiceProps} />} fileName={`recibo_caja-${invoiceProps.serial}.pdf`} >
                          {({ blob, url, loading, error }) =>
                            loading ? 'Cargando recibo...' : 'Descargar recibo de caja'
                          }
                        </PDFDownloadLink>
                      </ButtonIcon>
                    )} */}
                </div>
              ) : (
                <>
                  <MainTable
                    keyField="idx"
                    data={dataTable}
                    columns={columns}
                    pageSize={12}
                    className='mb-0 shadow-none'
                    rowClasses="fs--3"
                    headerClassName='bg-light'
                    noSearch
                    noAdd
                    ref={table}
                  />
                </>
              )}

            </>
          )
        }
      </ModalBody >
      <ModalFooter className="no-border">
        {success ? (
          <Button onClick={onOk} transform="shrink-3 down-2" color="primary" >
            Aceptar
          </Button>
        ) : (
          <Col className="text-right" >
            <Button onClick={() => toggle()} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
              Cancelar
            </Button>
            <Button onClick={guardar} className="mr-2" transform="shrink-3 down-2" color="primary" >
              Guardar
            </Button>
            <Button onClick={onRecaudo} transform="shrink-3 down-2" color="primary" disabled={incompleto()}>
              Inicializar
            </Button>
          </Col>
        )}
      </ModalFooter>
    </Modal >
  )
}

export default InitCreditClientModal

