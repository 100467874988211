import React, { useState, useEffect } from 'react'
import cogoToast from 'cogo-toast';
import { EnvironmentContext } from './context/Context';
import { generalServices } from './services';

const EnvironmentProvider = (props) => {
    const [pagadurias, setPagadurias] = useState([]);
    const [banks, setBanks] = useState([]);
    const [treasuries, setTreasuries] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [cashConcepts, setCashConcepts] = useState([]);
    const [cashLoteConcepts, setCashLoteConcepts] = useState([]);
    const [desembolsoConcepts, setDesembolsoConcepts] = useState([]);

    useEffect(() => {
        loadEnvironmentData();
    }, [])

    const loadEnvironmentData = () => {
      Promise.all([
        generalServices.getPagadurias(),
        generalServices.getBanks(),
        generalServices.getTreasuries(),
        generalServices.getBankAccount(),
        generalServices.getConcepts('O'),
        generalServices.getConcepts('L'),
        generalServices.getDesConcepts('C')
      ]).then((response) => {
          setPagadurias(response[0].objResponse);
          setBanks(response[1]);
          setTreasuries(response[2].objResponse);
          setBankAccounts(response[3].objResponse);
          setCashConcepts(response[4].objResponse);
          setCashLoteConcepts(response[5].objResponse);
          setDesembolsoConcepts(response[6].objResponse)
      }).catch((err) => {
          console.log(err);
      });
    }

    useEffect(() => { 
      if(!pagadurias[0]) return;

      if(pagadurias[0].razonSocial == undefined){
        loadPagadurias();
      }
    }, [pagadurias])

    useEffect(() => { 
      if(!banks[0]) return;
      if(!banks[0].banco){
        loadBanks();
      }
    }, [banks])

    useEffect(() => { 
      if(!treasuries[0]) return;
      if(!treasuries[0].tesoreria){
        loadTreasuries();
      }
    }, [treasuries])

    useEffect(() => { 
      if(!bankAccounts[0]) return;
      if(!bankAccounts[0].idBanco){
        loadBankAccounts();
      }
    }, [bankAccounts])

    useEffect(() => { 
      if(!cashConcepts[0]) return;
      if(!cashConcepts[0].conceptotesoreria){
        loadCashConcepts();
      }
    }, [cashConcepts])

    useEffect(() => { 
      if(!cashLoteConcepts[0]) return;
      if(!cashLoteConcepts[0].conceptotesoreria){
        loadCashLoteConcepts();
      }
    }, [cashLoteConcepts])

    useEffect(() => { 
      if(!desembolsoConcepts[0]) return;
      if(!desembolsoConcepts[0].naturaleza || desembolsoConcepts[0].naturaleza !== "C"){
        loadDesembolsoConcepts();
      }
    }, [desembolsoConcepts])

    const loadPagadurias = async () => {
      const res = await generalServices.getPagadurias();
      if(res.error || !res.objResponse) return;

      setPagadurias(res.objResponse);
    }

    const loadBanks = async () => {
      const res = await generalServices.getBanks();
      if(res.error || !res.objResponse) return;

      setBanks(res.objResponse);
    }

    const loadTreasuries = async () => {
      const res = await generalServices.getTreasuries();
      if(res.error || !res.objResponse) return;

      setTreasuries(res.objResponse);
    }

    const loadBankAccounts = async () => {
      const res = await generalServices.getBankAccount();
      if(res.error || !res.objResponse) return;

      setBankAccounts(res.objResponse);
    }

    const loadCashConcepts = async () => {
      const res = await generalServices.getConcepts('O');
      if(res.error || !res.objResponse) return;

      setCashConcepts(res.objResponse);
    }

    const loadCashLoteConcepts = async () => {
      const res = await generalServices.getConcepts('L');
      if(res.error || !res.objResponse) return;

      setCashLoteConcepts(res.objResponse);
    }

    const loadDesembolsoConcepts = async () => {
      const res = await generalServices.getDesConcepts('C');
      if(res.error || !res.objResponse) return;

      setDesembolsoConcepts(res.objResponse);
    }

    const value = {
        pagadurias,
        banks,
        treasuries,
        bankAccounts,
        cashConcepts,
        cashLoteConcepts,
        desembolsoConcepts,
        loadPagadurias,
      };

    return (
        <EnvironmentContext.Provider value={value}>
          {props.children}
        </EnvironmentContext.Provider>
    );
}

export default EnvironmentProvider