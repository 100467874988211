import React, { useState, Fragment } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { InfoMovesContext } from './InfoMovesContext';
import PageHeader from '../../components/common/PageHeader';
import MoveCaja from './childrens/MoveCaja';
import MoveBank from './childrens/MoveBank';
import MoveInit from './childrens/MoveInit';
import types from '../../data/moves/types';

const InfoMovesPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const [cajaMoves, setCajaMoves] = useState([]);
  const [bankMoves, setBankMoves] = useState([]);
  const [initMoves, setInitMoves] = useState([]);

  const toggleTab = id => {
    setActiveTab(id);
  }

  const handleCajaSubmit = (data) => {
    setCajaMoves(data)
  }

  const handleBankSubmit = (data) => {
    setBankMoves(data)
  }

  const handleInitSubmit = (data) => {
    setInitMoves(data)
  }

  const handleLoading = (value) => {
    setLoading(value)
  }

  return (
    <InfoMovesContext.Provider value={{
      loading,
      cajaMoves,
      bankMoves,
      initMoves,
      handleBankSubmit,
      handleCajaSubmit,
      handleInitSubmit,
      handleLoading,
    }}>
      <Fragment>
        <PageHeader title="Informe de Movimientos" className="mb-3" >
        </PageHeader>
        <div>
          <Nav tabs className="custom-nav">
            {types && types.map((type, idx) => (
              <NavItem className="flex-grow-1 text-center cursor-pointer">
                <NavLink onClick={() => toggleTab(type.id)} className={`p-3  ${activeTab == (idx+1) ? 'active' : '' }`}>
                  {type.altName}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <MoveCaja />
            </TabPane>
            <TabPane tabId="2">
              <MoveBank />
            </TabPane>
            <TabPane tabId="3">
              <MoveInit />
            </TabPane>
          </TabContent>
        </div>
      </Fragment>
    </InfoMovesContext.Provider>
  );
};

export default InfoMovesPage
