import { faArrowLeft, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import cogoToast from 'cogo-toast';
import React, { useEffect, useState } from 'react';
import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import MainTable from '../../components/tables/MainTable';
import { currencyFormat, percentageFormat, refFormat } from '../../helpers/tableFormatters';
import { creditServices, desembolsoServices } from '../../services';
import RefinancePage from '../refinance/RefinancePage';
import ClientInfo from './children/ClientInfo';
import CreditDetails from './CreditDetails';
import { StateContext } from './StateContext';

const CREDIT_STATE = {
  ALL: '0',
  OPEN: '2',
  CLOSED: '3'
}

const TABLE_TITLES = {
  '0': "Todos los Créditos",
  '2': "Créditos Activos",
  '3': "Créditos Cerrados"
}

const StatePage = () => {
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState();
  const [showRefinance, setShowRefinance] = useState();
  const [creditSelected, setCreditSelected] = useState();
  const [creditState, setCreditState] = useState(CREDIT_STATE.OPEN);
  const [creditSelectedInfo, setCreditSelectedInfo] = useState();
  const [client, setClient] = useState();
  const [clientCredits, setClientCredits] = useState([]);
  const [clientCreditsResume, setClientCreditsResume] = useState();
  const [tableTitle, setTableTitle] = useState("Créditos");

  const columns = [
    {
      dataField: 'idSolicitud',
      text: 'Solicitud',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField) => refFormat(dataField)
    },
    {
      dataField: 'credito',
      text: 'Crédito',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'fecha',
      text: 'Fecha',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'nombrePagaduria',
      text: 'Pagaduría',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'monto',
      text: 'Monto',
      headerClasses: 'border-0 text-right',
      classes: 'border-0 py-2 align-middle',
      align: 'right',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'tasa',
      text: 'Tasa',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => percentageFormat(value),
    },
    {
      dataField: 'plazo',
      text: 'Plazo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
    },
    {
      dataField: 'valorCuota',
      text: 'Valor Cuota',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'saldopro',
      text: 'Saldo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'estado',
      text: 'Estado',
      headerClasses: 'border-0 text-center',
      classes: 'border-0 py-2 align-middle text-center',
      sort: true,
      formatter: (value, prestamo) => value == 3 ? prestamo.valorActualizacion > 0 ? "Actualizado" : prestamo.valorUnificacion > 0 ? "Unificado"
        : "Finalizado" : value == 2 ? "Activo" : "Estudio",
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: 'Acciones',
      classes: 'border-0 py-2',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  useEffect(() => {
    if (!client) return;
    loadClientCredits();
    if (!creditState) return;
    setTableTitle(TABLE_TITLES[creditState])

  }, [client, creditState])

  useEffect(() => {
  }, [creditSelected])

  const actionsFormatter = (dataField, fieldSelected) => {
    if (!fieldSelected) return <></>
    return (
      <div style={{ textAlign: "center" }}>
        <IconButton
          onClick={() => handleCLickAction(fieldSelected)}
        >
          <FontAwesomeIcon
            style={{ width: '16px' }}
            icon={faEye}
            color="SteelBlue"
          />
        </IconButton>
      </div>
    )
  }

  const handleCLickAction = data => {
    setShowDetails(true);
    setCreditSelectedInfo(data);
    getCreditSelected(data);
  }

  const getCreditSelected = async (data) => {
    setLoading(true);
    const response = await desembolsoServices.getPrestamo(data.prestamo);
    if (!response || response.error || !response.objResponse) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar la información del crédito seleccionado' });
      setShowDetails(false);
      setLoading(false);
      return;
    }

    setCreditSelected(response.objResponse[0]);
    setLoading(false);
  }

  const onClientSelect = data => {
    setCreditState(CREDIT_STATE.OPEN);
    setClient(data);
  }

  const handleClean = () => {
    setClient(null);
    setCreditState(CREDIT_STATE.OPEN);
    setTableTitle("Créditos")
    setClientCredits([]);
    setClientCreditsResume(null);
    setCreditSelected(null);
  }

  const loadClientCredits = async () => {
    Promise.all([
      creditServices.getCreditsByClient(client.id, creditState),
      creditServices.getClientCrediResume(client.id)
    ]).then((response) => {
      if (!response || response.error) {
        cogoToast.error(response.msg,
          { position: 'bottom-left', heading: 'Error al cargar la información crediticia del cliente' });
        setClientCredits(null);
        setClientCreditsResume(null);
        return;
      }

      if (!response[0] || !response[1]) {
        cogoToast.error(response.msg,
          { position: 'bottom-left', heading: 'Error al cargar la información crediticia del cliente' });
        setClientCredits(null);
        setClientCreditsResume(null);
        return;
      }
      setClientCredits(response[0].objResponse);
      if (!response[1].objResponse[0] || response[1].objResponse[0].sumSaldos) {
        setClientCreditsResume(response[1].objResponse[0] || {});
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const handleBack = () => {
    if (showRefinance) {
      setShowRefinance(false);
      return;
    }
    setShowDetails(false);
    setCreditSelected(null);
    setCreditSelectedInfo(null);
  }

  const onSelectCreditState = value => {
    if (!value || !client) return;
    setCreditState(value);
  }

  const handleRefinance = () => {
    if (!creditSelected || !creditSelectedInfo || creditSelected.estado != 2) return;
    setShowRefinance(true);
  }

  const updateLoading = value => {
    setLoading(value);
  }

  const handleSuccessRefinance = () => {
    setShowRefinance(false);
    setShowDetails(false);
    loadClientCredits();
  }

  const handleRefresh = () => {
    loadClientCredits();
  }

  return (
    <StateContext.Provider value={{
      loading,
      client,
      creditState,
      creditSelected,
      creditSelectedInfo,
      clientCredits,
      clientCreditsResume,
      onClientSelect,
      onSelectCreditState,
      handleRefinance,
      handleClean,
      handleRefresh,
      handleSuccessRefinance,
      updateLoading,
    }}>
      <PageHeader title={`${showRefinance ? "Refinanciación" : "Estado de Cuenta"}`} className="mb-3" >
        {showDetails && !loading && (
          <ButtonIcon className="mt-3" onClick={handleBack} icon={faArrowLeft} transform="shrink-3 down-2" color="falcon-default" size="sm">
            Atrás
          </ButtonIcon>
        )}
      </PageHeader>
      {showRefinance && <RefinancePage />}
      {!showRefinance && showDetails && <CreditDetails />}
      {!showRefinance && !showDetails && (
        <>
          <ClientInfo />
          <MainTable
            title={tableTitle}
            data={clientCredits}
            columns={columns}
            keyField="id"
            className="px-2"
            loading={loading}
            noSearch
            noAdd
          />
        </>
      )}
    </StateContext.Provider>
  )
}

export default StatePage