import React, { useState, useEffect, Fragment } from 'react';
import {
  Card,
  Input,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Table,
  CustomInput,
  Form,
  Col,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { urlApi } from '../../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import ContactListComponent from '../../auth/TablaFormPropiedades';
import WizardError from '../../auth/wizard/WizardError';
import ButtonIcon from '../../common/ButtonIcon';
import WizardInput from '../../auth/wizard/WizardInput';
import { nitOsviel } from '../../../configuracion/serverConfig';

const options = ['49 - No responsable de Iva', '22 - Obligado a cumplir deberes formales'];

const tiposContribuyentes = [{
  value: "jur",
  label: "Persona jurídica o asimilada"
}, {
  value: "nat",
  label: "Persona natural o asimilada"
}, {
  value: "gran",
  label: "Gran contribuyente"
}];

const FormClasification = (props) => {

  const [initialData, setInitialData] = useState({
    Codigo: '',
    Procedimiento: ''
  });
  const [ListData, setListData] = useState([]);
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialData);
  const [EstadoUsuario, setEstadoUsuario] = useState(false);
  const [identificacion, setIdentificacion] = useState('');
  const [ListaActividades, setListaActividades] = useState([]);
  const [ListaResponsabilidades, setListaResponsabilidades] = useState([]);
  const [DataGrilla, setDataGrilla] = useState([]);

  const [ActividadPrincipal, setActividadPrincipal] = useState();
  const [ActividadSecundaria, setActividadSecundaria] = useState();
  const [Responsabilidades, setResponsabilidades] = useState([]);
  const [TipoContribuyente, setTipoContribuyente] = useState();

  useEffect(() => {
    ConsultarActvidadesEconomicas();
    ConsultarResponsabilidades();
    if (props.selected && props.editing) {
      cambiarselectActividadPrincipal({ value: props.selected.ActividadPrincipal, label: props.selected.ActividadPrincipalLabel })
      setTimeout(() => {
        cambiarselectActividadSecundaria({ value: props.selected.ActividadSecundaria, label: props.selected.ActividadSecundariaLabel })
      }, 700)
      setDataGrilla(props.selected.Responsabilidades);
      props.agregarcampo2(props.selected.Responsabilidades);
      if (props.selected.tipoContribuyente) {
        setTipoContribuyente(tiposContribuyentes.find(i => i.value == props.selected.tipoContribuyente));
      }
    } else if (props.ArrayClasificacion) {
      setDataGrilla(props.ArrayClasificacion);
    }
  }, []);

  const cambiarselectTipoContribuyente = data2 => {
    props.cambiarTipoContribuyente(data2.value);
    setTipoContribuyente(data2);
  };

  const cambiarselectActividadPrincipal = data2 => {
    if (!data2) {
      props.cmabiarselectact1(data2);
      return;
    }

    const value = data2.value || data2;
    const label = data2.label || value;
    props.cmabiarselectact1(value);
    setActividadPrincipal({ value, label });
  };

  const cambiarselectActividadSecundaria = data3 => {
    if (!data3) {
      props.cmabiarselectact2(data3);
      return;
    }
    const value = data3.value || data3;
    const label = data3.label || value;
    props.cmabiarselectact2(value);
    setActividadSecundaria({ value, label });
  };

  const cambiarselectResponsabilidades = data4 => {
    let dataresponsabilidades = { value: data4.value, label: data4.label };
    setResponsabilidades(dataresponsabilidades);
  };

  const ConsultarResponsabilidades = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    fetch(urlApi + '/responsabilidad/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;

          items.push(element);
        }
        if (data.status === 0) {
          setListaResponsabilidades(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  const ConsultarActvidadesEconomicas = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    fetch(urlApi + '/actividad/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;

          items.push(element);
        }
        if (data.status === 0) {
          setListaActividades(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  const quitarCampo = data => {
    const newData = DataGrilla.filter(d => d.IdResponsabilidad !== data.IdResponsabilidad)
    props.agregarcampo2(newData)
    setDataGrilla(newData);
  };

  const agregarCampo = () => {
    if (Responsabilidades && Responsabilidades.value != undefined) {
      let ObjRegistro = {};
      let EstadoID = false;
      ObjRegistro.Responsabilidad = Responsabilidades.label;
      ObjRegistro.IdResponsabilidad = Responsabilidades.value;
      if (DataGrilla.length > 0) {
        for (let i = 0; i < DataGrilla.length; i++) {
          if (DataGrilla[i].IdResponsabilidad == Responsabilidades.value) {
            DataGrilla[i].Responsabilidad = Responsabilidades.label;
            EstadoID = true;
            console.log('ya se encuentra el registro');
          }
        }
      } else {
        setDataGrilla([ObjRegistro]);
        props.agregarcampo2([ObjRegistro])
      }
      if (EstadoID == false) {
        let ObjRegistro2 = {};
        ObjRegistro2.Responsabilidad = Responsabilidades.label;
        ObjRegistro2.IdResponsabilidad = Responsabilidades.value;

        const newData = [...DataGrilla]
        newData.push(ObjRegistro2)
        setDataGrilla(newData);
        props.agregarcampo2(newData)
      }
    }
  };

  return (
    <>
      <Fragment>
        <Row form>
          <Col className="mb-4">
            <label>Actividad Principal*</label>
            <Select
              placeholder="Seleccionar"
              style="fullWidth-select"
              name="ActividadPrincipal"
              onChange={cambiarselectActividadPrincipal}
              value={{ value: ActividadPrincipal ? ActividadPrincipal.value : "", label: ActividadPrincipal ? ActividadPrincipal.label : "" }}
              options={ListaActividades}
              innerRef={props.register({
                required: "requerido"
              })}
            />
          </Col>

          <Col className="mb-4">
            <label>Actividad Secundaria*</label>
            <Select
              placeholder="Seleccionar"
              style="fullWidth-select"
              name="ActividadSecundaria"
              onChange={cambiarselectActividadSecundaria}
              value={{ value: ActividadSecundaria ? ActividadSecundaria.value : "", label: ActividadSecundaria ? ActividadSecundaria.label : "" }}
              options={ListaActividades}
              innerRef={props.register({
                required: "requerido"
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Responsabilidades*</label>
            <Select
              placeholder="Seleccionar"
              style="fullWidth-select"
              name="Responsabilidades"
              onChange={cambiarselectResponsabilidades}
              value={Responsabilidades}
              options={ListaResponsabilidades}
            />
            <ButtonIcon className="mt-2" onClick={agregarCampo} icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
              Agregar
            </ButtonIcon>
          </Col>

          <Col>
            <Label>Clasificacion</Label>
            <div style={{ border: "1px solid hsl(0,0%,80%)", borderRadius: '5px' }}>
              <div className="contact-table">
                <ContactListComponent
                  panel={props.panel}
                  searchInput={props.searchInput}
                  contactlists={DataGrilla}
                  headerLista={['', 'Responsabilidad', '']}
                  bodyLista={['Responsabilidad', '']}
                  quitarCampo={data => quitarCampo(data)}
                  Acciones={true}
                  Consultar={data => console.log(data)}
                  selectValue={data => console.log(data)}
                  handleSearch={data => console.log(data)}
                  actiononContact={(action, data) => console.log(action, data)}
                />
              </div>
            </div>
            {props.errors['Responsabilidades'] && (
              <span class="text-danger">
                Por favor seleccione al menos una responsabilidad.
              </span>
            )}
          </Col>
        </Row>
        {props.selected?.SelectTipoDocumento === "NIT" && props.selected?.NumeroIdentiicacion == nitOsviel && (<Row form>
          <Col className="mb-4">
            <label>Tipo contribuyente*</label>
            <Select
              placeholder="Seleccionar"
              style="fullWidth-select"
              name="TipoContribuyente"
              onChange={cambiarselectTipoContribuyente}
              value={TipoContribuyente}
              options={tiposContribuyentes}
              innerRef={props.register({
                required: "requerido"
              })}
            />
          </Col>
        </Row>)}
        <Row>
          <Col />
        </Row>
      </Fragment>
    </>
  );
}

export default FormClasification;
