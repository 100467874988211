import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { NavLink as BootstrapNavLink, Collapse, Nav, NavItem } from 'reactstrap';
import AppContext from '../../context/Context';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';

const NavbarVerticalMenu = ({ routes, location }) => {
  const { setShowBurgerMenu } = useContext(AppContext);
  const [rutas, setRutas] = useState(routes);
  const [openedIndex, setOpenedIndex] = useState(null);

  /*useEffect(() => {
    let openedDropdown = null;
    // if(rutas){
    //   rutas.forEach((route, index) => {  
    //     if (location.pathname.indexOf(route.formulario.ruta) === 0) openedDropdown = index;
    //   });
    //     setOpenedIndex(openedDropdown);
    // }

    // eslint-disable-next-line
  }, []);*/

  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  const getHr = name => {
    if (name === 'Widgets' || name === 'Documentation') {
      return (
        <div className="navbar-vertical-divider">
          <hr className="navbar-vertical-hr my-2" />
        </div>
      );
    }
  };

  const addTab = route => {
    setShowBurgerMenu(false);
    window.addTab(route);
  };

  useEffect(() => {
    setRutas(routes);
  }, [routes])

  if (rutas === null) return null;

  return rutas.map((route, index) => {
    if (!route.tareas) {
      return (
        <Fragment key={index}>
          {getHr(route.nombre)}
          <NavItem>
            <NavLink className="nav-link" to="/" onClick={() => addTab(route)}>
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </NavItem>
        </Fragment>
      );
    }
    return (
      <Fragment key={index}>
        {getHr(route.nombre)}
        <NavItem>
          <BootstrapNavLink
            onClick={e => toggleOpened(e, index)}
            className="dropdown-indicator cursor-pointer"
            aria-expanded={openedIndex === index}
          >
            <NavbarVerticalMenuItem route={route} />
          </BootstrapNavLink>
          <Collapse isOpen={openedIndex === index}>
            <Nav>
              <NavbarVerticalMenu routes={route.tareas} location={location} />
            </Nav>
          </Collapse>
        </NavItem>
      </Fragment>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(NavbarVerticalMenu);
