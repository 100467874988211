import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import MainTable from '../../components/tables/MainTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currencyFormat } from '../../helpers/tableFormatters';
import { Document, PDFDownloadLink, Page, Text, View, Image } from '@react-pdf/renderer';
import ButtonIcon from '../../components/common/ButtonIcon';
import styles from '../init-credit/invoice/styles';
import logo from '../../assets/img/illustrations/paynet.png';

const pagina1 = 25;
const pagina = 29;

function format(val) {
  if (typeof val == 'number') {
    //return val.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
    return currencyFormat(val);
  }
  return val;
}

function SimulatorPlanModal({ plan, open, collapseOneOpen }) {

  const columns = [
    {
      dataField: 'periodo',
      text: 'Cuota',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'saldoInicial',
      headerClasses: 'border-0 text-right',
      text: 'Saldo Inicial',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'interes',
      headerClasses: 'border-0 text-right',
      text: 'Interés',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'capital',
      headerClasses: 'border-0 text-right',
      text: 'Capital',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'cuota',
      headerClasses: 'border-0 text-right',
      text: 'Valor Cuota',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'saldo',
      headerClasses: 'border-0 text-right',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle font-weight-bold',
    },
  ]

  return (
    <Modal size="xl" isOpen={open} toggle={() => collapseOneOpen(!open)}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => collapseOneOpen(!open)}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >Plan de Pagos</ModalHeader>
      <ModalBody className="p-0 px-3">
        <MainTable
          data={plan}
          columns={columns}
          keyField="periodo"
          pageSize={12}
          className='mb-0 shadow-none'
          noSearch
          noAdd
        />
        <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
          <PDFDownloadLink document={
            <Document>
              <Page style={styles.body} orientation="landscape">
                <View style={styles.header}>
                  <View
                    style={{
                      ...styles.headerCol,
                      ...styles.logoContainer
                    }}
                  >
                    <Image
                      style={styles.logo}
                      src={logo}
                    />
                  </View>
                  <View
                    style={{
                      ...styles.headerCol,
                      ...styles.companyInfo
                    }}
                  >
                    <Text style={styles.strong}>SU OPERADORA DE LIBRANZA</Text>
                    <Text style={styles.strong}>NIT. 900.344.946-3</Text>
                    <Text>Calle 13 No. 8-94 Barrio Centro 2do. Piso</Text>
                    <Text>Telefono: (605)726 0049</Text>
                    <Text>Cel. 301 662 1260</Text>
                    <Text>Maicao, La Guajira - Colombia</Text>
                  </View>
                  <View
                    style={{
                      ...styles.headerCol,
                      ...styles.serialContainer
                    }}
                  >
                    <View style={styles.serialWrap}>
                      <View style={styles.serialHeader}>
                        <Text className="text-uppercase">Plan de Pagos</Text>
                      </View>
                      <View style={styles.serialBody}>
                        <Text>No.   </Text>
                        <Text style={styles.serial}>000</Text>
                      </View>
                    </View>

                  </View>
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '5pt'
                }}>
                  {columns?.map(i =>
                    <View style={{
                      height: '20pt',
                      width: (100 / columns.length) + '%',
                      borderWidth: '1pt',
                      borderStyle: 'solid',
                      borderColor: 'black'
                    }}>
                      <Text style={{
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold'
                      }}>{i.text}</Text>
                    </View>)}
                </View>
                {plan?.slice(0, pagina1).map(p =>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                    {columns?.map((i, n) =>
                      <View style={{
                        height: '18pt',
                        width: (100 / columns.length) + '%',
                        borderBottomWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: 'black',
                        paddingRight: '8pt'
                      }}>
                        <Text style={{
                          textAlign: 'right',
                          fontSize: '11px'
                        }}>{n == 0 ? p[i.dataField] : format(p[i.dataField])}</Text>
                      </View>)}
                  </View>)}
              </Page>
              {plan.length > pagina1 && [...Array(Math.ceil((plan.length - pagina1) / pagina)).keys()].map(c =>
                <Page style={styles.body} orientation="landscape">
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '5pt'
                  }}>
                    {columns?.map(i =>
                      <View style={{
                        height: '20pt',
                        width: (100 / columns.length) + '%',
                        borderWidth: '1pt',
                        borderStyle: 'solid',
                        borderColor: 'black'
                      }}>
                        <Text style={{
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold'
                        }}>{i.text}</Text>
                      </View>)}
                  </View>
                  {plan?.slice(pagina1 + c * pagina, pagina1 + c * pagina + pagina).map(p =>
                    <View style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}>
                      {columns?.map((i, n) =>
                        <View style={{
                          height: '18pt',
                          width: (100 / columns.length) + '%',
                          borderBottomWidth: '1px',
                          borderStyle: 'solid',
                          borderColor: 'black',
                          paddingRight: '8pt'
                        }}>
                          <Text style={{
                            textAlign: 'right',
                            fontSize: '11px'
                          }}>{n == 0 ? p[i.dataField] : format(p[i.dataField])}</Text>
                        </View>)}
                    </View>)}
                </Page>)}
            </Document>
          } fileName="plan de pagos.pdf" >
            {({ blob, url, loading, error }) => error ? error.message : loading ? "Generando PDF..." : "Descargar"
            }
          </PDFDownloadLink>
        </ButtonIcon>
      </ModalBody>
    </Modal>
  )
}

export default SimulatorPlanModal
