import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const getCajaMoves = ({ idTreasure, startDate, endDate, page, size } ) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/recaudo/mov/caja/${sesionToken}/${idTreasure}/${startDate}/${endDate}/${page || "1"}/${size || "0"}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getBankMoves = ({ idBank, startDate, endDate, page, size } ) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/recaudo/mov/banco/${sesionToken}/${idBank}/${startDate}/${endDate}/${page || "1"}/${size || "0"}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getInitMoves = ({ idPagaduria, startDate, endDate, page, size } ) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/recaudo/mov/init/${sesionToken}/${idPagaduria}/${startDate}/${endDate}/${page || "1"}/${size || "0"}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}


export const informesServices = {
    getCajaMoves,
    getBankMoves,
    getInitMoves
}