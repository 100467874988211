import { faArrowLeft, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import cogoToast from 'cogo-toast';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import MainTable from '../../components/tables/MainTable';
import { EnvironmentContext } from '../../context/Context';
import { getTodayStr } from '../../helpers/dateFormatters';
import { currencyFormat, idFormat, refFormat } from '../../helpers/tableFormatters';
import { desembolsoServices } from '../../services';
import { DesembolsoContext } from './DesembolsoContext';
import PrestamoDetails from './PrestamoDetails';
import SuccessModal from './SuccessModal';

const DesembolsoPage = (props) => {
  const environment = useContext(EnvironmentContext);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedInfo, setSelectedInfo] = useState();

  const [searchKey, setSearchKey] = useState();
  const [refresh, setRefresh] = useState(false);

  const [solicitudList, setSolicitudList] = useState([]);
  const [showDetails, setShowDetails] = useState();

  const [conceptosList, setConceptosList] = useState([]);
  const [totalDeductions, setTotalDeductions] = useState(0);
  const [deductionsData, setDeductionsData] = useState();
  const [cashData, setCashData] = useState();
  const [wireTransferData, setWireTransferData] = useState();
  const [chequeData, setChequeData] = useState();
  const [observationData, setObservationData] = useState();
  const [neto, setNeto] = useState();
  const [diff, setDiff] = useState(-1);
  const [selectedConcept, setSelectedConcept] = useState();
  const [desembolsoDate, setDesembolsoDate] = useState(getTodayStr());

  const [showSuccessModal, setShowSuccessModal] = useState();

  const [payload, setPayload] = useState();

  const [isInit, setIsInit] = useState(false);

  const [tercero, setTercero] = useState({});

  const columns = [
    {
      dataField: 'solicitud',
      text: 'Nro. Solicitud',
      headerClasses: 'border-0',
      align: 'left',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField) => refFormat(dataField),
    },
    {
      dataField: 'fecha',
      text: 'Fecha',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'credito',
      text: 'Nro. Crédito',
      headerClasses: 'border-0',
      align: 'center',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'nomCliente',
      text: 'Cliente',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => clientFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'nomPagaduria',
      text: 'Pagaduría',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'monto',
      text: 'Monto',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'plazo',
      text: 'Plazo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: 'Acción',
      classes: 'border-0 py-2',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  useEffect(() => {
    if (!selected) return;
    loadSelectedInfo();
    loadConceptos();
  }, [selected])

  useEffect(() => {
    loadSolicitudes();
  }, [searchKey, refresh])

  useEffect(() => {
    if (!deductionsData) return;
    const tempTotalDeductions = isInit ? 0 : Object.values(deductionsData).reduce((a, b) => a + b);
    setTotalDeductions(tempTotalDeductions);
    const tempNeto = selected.monto - tempTotalDeductions;
    setNeto(tempNeto);
  }, [deductionsData, isInit])

  useEffect(() => {
    if (!neto && !cashData && !wireTransferData && !chequeData) return;
    const tempCashValue = cashData ? cashData.cashValue : 0
    const tempWireTransferValue = wireTransferData ? wireTransferData.wireTransferValue : 0
    const tempChequeValue = chequeData ? chequeData.chequeValue : 0
    const tempDiff = neto - (tempCashValue + tempWireTransferValue + tempChequeValue);
    const fixedDiff = Number(tempDiff).toFixed(2);
    setDiff(fixedDiff);
  }, [neto, cashData, wireTransferData, chequeData])

  useEffect(() => {
    if (!neto) return
    if (!isInit) {
      setDiff(neto)
      return;
    }
    setDiff(0);
  }, [isInit])

  const actionsFormatter = (dataField, fieldSelected) => {
    if (!fieldSelected) return <></>
    return (
      <div className='pl-4'>
        <IconButton
          onClick={() => handleClickDetails(fieldSelected)}
        >
          <FontAwesomeIcon
            style={{ width: '16px' }}
            icon={faCalculator}
            color="SteelBlue"
          />
        </IconButton>
      </div>
    )
  }

  const clientFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className='text-primary'>{fieldSelected.nomCliente}</div>
        <div>{`${fieldSelected.tipoIdCliente}`} {idFormat(fieldSelected.idCliente)}</div>
      </div>
    )
  }

  useEffect(() => {
    handleBack();
  }, [solicitudList])

  const handleBack = () => {
    setShowDetails(false);
    setSelected(null);
    setSelectedInfo(null);
    setPayload(null);
  }

  const handleClickDetails = (prestamo) => {
    setSelected(prestamo);
    setShowDetails(true);
  }

  const loadSolicitudes = async () => {
    setLoading(true);
    const response = await desembolsoServices.getPrestamos(searchKey);

    if (!response || response.error) {
      setLoading(false);
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar la información' });
      return;
    }
    setSolicitudList(response.objResponse);
    setLoading(false);
  }

  const loadConceptos = async () => {
    if (!environment.desembolsoConcepts) return;
    const tempConceptos = environment.desembolsoConcepts.map(concepto => ({
      value: concepto.conceptotesoreria,
      label: concepto.nombre
    }))
    setConceptosList(tempConceptos);
  }

  const loadSelectedInfo = async () => {
    setLoading(true);
    const response = await desembolsoServices.getPrestamo(selected.prestamo);

    if (!response || response.error || !response.objResponse[0]) {
      setLoading(false);
      cogoToast.error(response.msg || "No se logro cargar los datos del prestamo",
        { position: 'bottom-left', heading: 'Error' });
      return;
    }
    const data = response.objResponse[0];
    setSelectedInfo(data);
    if (data.idModalidad == 5) {
      setIsInit(true);
    }
    setDesembolsoDate(data.fecha)
    setLoading(false);
  }

  const handleSearch = value => {
    setRefresh(!refresh);
    setSearchKey(value);
  }

  const handleDeductions = data => {
    setDeductionsData(data);
  }

  const handleCashData = data => {
    setCashData(data);
  }

  const handleWireTransferData = data => {
    setWireTransferData(data);
  }

  const handleChequeData = data => {
    setChequeData(data);
  }

  const handleObservationData = data => {
    setObservationData(data);
  }

  const handleSelectedConcept = data => {
    setSelectedConcept(data);
  }

  const handleDesembolsoDate = data => {
    setDesembolsoDate(data);
  }

  const onSubmit = async () => {

    if ((diff > 0 || diff < 0) && !isInit) {
      cogoToast.warn("La diferencia debe ser cero. Por favor completa la información relacionada con el desembolso (Efectivo, Transferencia o Cheque)",
        { position: 'bottom-left', heading: 'Diferencia' });
      return;
    }

    if (!selectedConcept && !isInit) {
      cogoToast.warn("Por favor selecciona el Concepto de egreso)",
        { position: 'bottom-left', heading: 'Campo requerido' });
      return;
    }

    if (tercero.pagoTercero && (!tercero.tipoDocumento || !tercero.numeroIdentificacion || !tercero.nombres || !tercero.apellidos)) {
      cogoToast.warn("Por favor completa la información relacionada con el pago a tercero autorizado",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
    }

    const isCash = cashData && cashData.cashValue && cashData.cashSource;
    const isWireTransfer = wireTransferData && wireTransferData.wireTransferValue && wireTransferData.wireTransferValue && wireTransferData.destAccount;
    const isCheque = chequeData && chequeData.chequeValue && chequeData.originAccount && chequeData.numCheque;


    confirmAlert({
      title: 'Confirmación requerida',
      message: '¿Confirma que desea ejecutar el desembolso?',
      buttons: [
        {
          label: 'Sí',
          onClick: async () => {

            if (!selectedInfo ||
              !deductionsData ||
              !neto) return;

            setLoading(true);
            const reqPayload = {
              desembolso: selectedInfo.monto,
              idPrestamo: selectedInfo.prestamo,
              fecha: desembolsoDate || selectedInfo.fecha,
              compraCartera: deductionsData.portfolioPurchase,
              anticipoInteres: deductionsData.advanceInterest,
              comision: deductionsData.commission,
              gmf: deductionsData.gmf,
              seguro: deductionsData.assured,
              estudioCredito: deductionsData.creditStudyValue,
              costoCheque: deductionsData.cheque,
              otrosPagos: deductionsData.otherPayments,
              txEfectivo: isCash && cashData ? cashData.cashValue : 0,
              efectivoTesoreria: isCash && cashData ? Number(cashData.cashSource) : 0,
              txBancaria: isWireTransfer && wireTransferData ? wireTransferData.wireTransferValue : 0,
              bancariaIdCtaOrig: isWireTransfer && wireTransferData ? Number(wireTransferData.originAccount) : 0,
              bancariaIdCtaDest: isWireTransfer && wireTransferData ? Number(wireTransferData.destAccount) : 0,
              txCheque: isCheque && chequeData ? chequeData.chequeValue : 0,
              chequeIdCtaOrig: isCheque && chequeData ? Number(chequeData.originAccount) : 0,
              nroCheque: isCheque && chequeData ? chequeData.numCheque : '',
              netoDesembolso: neto,
              observaciones: observationData ? observationData.observations : '',
              conceptoTesoreria: selectedConcept ? Number(selectedConcept) : 0,
              inicializar: isInit ? 1 : 0,
              terceroAutorizado: tercero.pagoTercero ? tercero : null
            }

            const response = await desembolsoServices.setDesembolso(reqPayload);


            if (!response || response.error || !response.objResponse) {
              cogoToast.error(response.msg || "No se logro completar la operación de desembolso",
                { position: 'bottom-left', heading: 'Error' });
              setLoading(false);
              setPayload(null);
              return;
            }

            setPayload({
              ...reqPayload,
              cashData: cashData,
              isCash,
              isWireTransfer,
              isCheque,
              ciudad: response.objResponse.ciudad,
              consecutivo: response.objResponse.consecutivo
            });

            handleSuccess();
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const handleSuccess = () => {
    setShowSuccessModal(true);
    setIsInit(false);
  }

  const handleOk = () => {
    setShowSuccessModal(false);
    loadSolicitudes();
  }

  const handleToggleInit = (value) => {
    setIsInit(value)
  }

  return (
    <DesembolsoContext.Provider value={{
      selected: selectedInfo,
      isInit,
      payload,
      loading,
      neto,
      diff,
      desembolsoDate,
      totalDeductions,
      handleBack,
      handleDeductions,
      handleCashData,
      handleWireTransferData,
      handleChequeData,
      handleObservationData,
      handleSelectedConcept,
      handleDesembolsoDate,
      conceptosList,
      onSubmit,
      handleToggleInit,
      handleOk
    }}>
      <Fragment>
        <PageHeader title={!showDetails ? "Pendientes por Desembolsar" : "Desembolso"} className="mb-3" >
          {showDetails && <ButtonIcon className="mt-3" onClick={handleBack} icon={faArrowLeft} transform="shrink-3 down-2" color="falcon-default" size="sm">
            Atras
          </ButtonIcon>}
        </PageHeader>
        {showSuccessModal && <SuccessModal open={showSuccessModal} onOk={handleOk} />}
        {showDetails && selected ? (
          <PrestamoDetails tercero={tercero} />
        ) : (
          <MainTable
            data={solicitudList}
            currentSearch={searchKey}
            columns={columns}
            keyField="id"
            search={handleSearch}
            className="px-2"
            loading={loading}
            noAdd
          />
        )}
      </Fragment>
    </DesembolsoContext.Provider>
  );
};

export default DesembolsoPage
