import React from 'react'
import * as FileSaver from 'file-saver'
import { pdf } from "@react-pdf/renderer";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from '../../../common/ButtonIcon'
import PDFTable from '../PDFTable';

const PdfExport = ({ data, headers, fileName, async = false, getData, disabled, className }) => {

  const generatePdfDocument = async () => {
    let dataSet = data;
    if(async) {
      const asyncData = await getData();
      dataSet = asyncData || [];
    }

    const blob = await pdf(
        <PDFTable data={dataSet} headers={headers}/>
    ).toBlob();
    FileSaver.saveAs(blob, fileName);
  };

  return (
    <ButtonIcon 
      onClick={generatePdfDocument} 
      style={{color: "#cc3f0c"}} 
      className={`position-relative ${className}`} 
      iconClassName="ml-1 fs-2" 
      icon={faFilePdf} 
      transform="shrink-3 down-2"
      color="falcon-default" 
      disabled={disabled}
    >
    </ButtonIcon>
  )
}

export default PdfExport