import React from 'react';
import CurrencyFormat from 'react-currency-format';

export const currencyFormat = (value, prefix = '$', suffix = '') => {
  return (
    <CurrencyFormat 
      value={value} 
      displayType={'text'} 
      thousandSeparator="." 
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={prefix}
      suffix={suffix}
    />
  )
}

export const idFormat = value => {
  return (
    <CurrencyFormat 
      value={value} 
      displayType={'text'} 
      thousandSeparator="." 
      decimalSeparator=","
    />
  )
}

export const percentageFormat = value => {
  return (
    <CurrencyFormat 
      value={value} 
      displayType={'text'} 
      thousandSeparator="." 
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale={true}
      suffix={'%'} 
    />
  )
}

export const refFormat = value => {
  let numStr = String(value);
  let rest = 6 - numStr.length;
  if(rest < 1) rest = 1
  const prefix = Array.from(Array(rest).keys()).map(i => '0').join('');
  return `${prefix}${numStr}`
}

export const clientFormatter = (dataField, fieldSelected) => {
  return (
    <div>
      <div className='text-primary'>{fieldSelected.nomCliente}</div>
      <div>{`${fieldSelected.tipoIdCliente}`} {idFormat(fieldSelected.idCliente)}</div>
    </div>
  )
}