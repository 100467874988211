import React from 'react'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from '../common/ButtonIcon'

const ExcelExport = ({ data, fileName, headers = [],  async = false, getData, disabled, className }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xls";

  const exportToExcel = async () => {
    let dataSet = dataSet;
    if(async) {
      const asyncData = await getData();
      dataSet = asyncData || [];
    }

    const keys = headers.map(item => item.key)
    const labels = headers.map(item => item.label)

    dataSet = dataSet.map(item => {
      return Object.assign(...Array.from(new Set(labels), (v, index) => ({[v]:item[`${keys[index]}`]})));
    })

    const ws = XLSX.utils.json_to_sheet(dataSet);
    const wb = { Sheets: {'data': ws}, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'array' });
    const dataResult = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataResult, fileName + fileExtension);
  }

  return (
    <ButtonIcon 
      onClick={exportToExcel} 
      style={{color: "#107c41"}} 
      className={`position-relative ${className}`} 
      iconClassName="ml-1 fs-2" 
      icon={faFileExcel} 
      transform="shrink-3 down-2"
      color="falcon-default" 
      disabled={disabled}
    >
    </ButtonIcon>
  )
}

export default ExcelExport