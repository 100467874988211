import React, { useState, useEffect, Fragment } from 'react';
import WizardInput from '../../auth/wizard/WizardInput';
import { Col, CustomInput, Row } from 'reactstrap';
import Select from 'react-select';
const options = [
  {
    label: 'Cédula de Ciudadania',
    value: 'CC'},
    {label: 'Cédula de Extranjería',
    value: 'CE'},
    {label: 'Pasaporte',
    value: 'PS'},
];
const FormRepresentation = (props) => {

  const [initialData, setInitialData] = useState({
    Codigo: '',
    Procedimiento: ''
  });
  const [ListData, setListData] = useState([]);
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialData);
  const [EstadoUsuario, setEstadoUsuario] = useState(false);
  const [identificacion, setIdentificacion] = useState('');
  const [SelectTipoDocumentoRep, setSelectTipoDocumentoRep] = useState([{ label: '', value: '' }]);

  useEffect(() => {
    if(props.IdentificacionRep){
      setSelectTipoDocumentoRep(props.IdentificacionRep);
    }
  }, []);

  const cambiarselect = data => {
    let datac = data;
    setSelectTipoDocumentoRep(datac);
    props.cambiarselect2(datac);
  };

  return (
    <>
      <Fragment>
        <Row form>
        <Col>
        <label>Tipo de Identificacion*</label>
            <WizardInput
                  type="select"
                  tag={CustomInput}
                  placeholder="Seleccionar"
                  id="SelectTipoDocumentoRep"
                  name="SelectTipoDocumentoRep"
                  value={props.IdentificacionRep}
                  errors={props.errors}
                  options={options}
                  onChange={(input) => cambiarselect(input.target.value)}
                  innerRef={props.register({
                    required: 'requerido',
                  })}
                />
              </Col>
          
          <Col>
            <WizardInput
              type="text"
              label="Numero Identificación*"
              name="NumeroidentificacionRep"
              innerRef={props.register({
                required: 'requerido',
                minLength: {
                  value: 2,
                  message: 'Min length 2'
                }
              })}
              errors={props.errors}
            />
          </Col>
        </Row>
        <Row form>
          <Col>
            <WizardInput
              type="text"
              label="Primer Nombre*"
              id="PrimerNombreRep"
              name="PrimerNombreRep"
              innerRef={props.register({
                required: 'requerido',
              })}
              errors={props.errors}
            />
          </Col>
          <Col>
            <WizardInput
              type="text"
              label="Segundo Nombre"
              id="SegundoNombreRep"
              name="SegundoNombreRep"
              innerRef={props.register()}
              errors={props.errors}
            />
          </Col>
        </Row>
        <Row form>
          <Col>
            <WizardInput
              type="text"
              label="Primer Apellido*"
              id="PrimerApellidoRep"
              name="PrimerApellidoRep"
              innerRef={props.register({
                required: 'requerido',
              })}
              errors={props.errors}
            />
          </Col>
          <Col>
            <WizardInput
              type="text"
              label="Segundo Apellido*"
              id="SegundoApellidoRep"
              name="SegundoApellidoRep"
              innerRef={props.register({
                required: 'requerido'
              })}
              errors={props.errors}
            />
          </Col>
        </Row>
        <Row form>
          <Col>
            <WizardInput
              type="Date"
              label="Fecha de Inicio Representación"
              id="FechaInicioRepresentacionRep"
              name="FechaInicioRepresentacionRep"
              innerRef={props.register({
                required: 'requerido',
                validate: value => {
                  const selectedDate = new Date(value)
                  const maxDate = new Date()
                  const minDate = new Date(new Date().setMonth(new Date().getMonth() - 1800))
                  return selectedDate <= maxDate && selectedDate >= minDate
                }
              })}
              max={new Date().toISOString().split('T')[0]}
              min={new Date(new Date().setMonth(new Date().getMonth() - 1800)).toISOString().split('T')[0]}
              errors={props.errors}
            />
          </Col>
          <Col>
            <WizardInput
              type="email"
              label="Correo Electrónico"
              id="CorreoElectronicoRep"
              name="CorreoElectronicoRep"
              innerRef={props.register({
                required: false,
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
              })}
              errors={props.errors}
              customError={
                props.errors['CorreoElectronicoRep'] && 
                props.errors['CorreoElectronicoRep'].type === "pattern" 
                  ? "Por favor escriba un correo electrónico válido."
                  : false 
              }
            />
          </Col>
        </Row>
        <Row form>
          <Col>
            <WizardInput
              type="text"
              label="Teléfono Celular*"
              id="TelefonoCelularRep"
              name="TelefonoCelularRep"
              innerRef={props.register({
                required: 'requerido',
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                validate: value => value.length === 10 || value.length === 0
              })}
              errors={props.errors}
              customError={
                props.errors['TelefonoCelularRep'] && 
                props.errors['TelefonoCelularRep'].type !== "required" 
                  ? "Por favor escriba un telefono válido."
                  : false 
              }
            />
          </Col>
          <Col>
            <WizardInput
              type="text"
              label="Teléfono Alterno"
              id="TelefonoAlternoRep"
              name="TelefonoAlternoRep"
              innerRef={props.register({
                required: false,
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                validate: value => value.length === 10 || value.length === 0
              })}
              errors={props.errors}
              customError={
                props.errors['TelefonoAlternoRep'] && 
                props.errors['TelefonoAlternoRep'].type !== "required" 
                  ? "Por favor escriba un telefono válido."
                  : false 
              }
            />
          </Col>
        </Row>
      </Fragment>
    </>
  );
}

export default FormRepresentation;
