import React from 'react';
import AuthWizardProvider from './AuthWizardProvider';

const WizardLayout = (props) => {
  return (
    <AuthWizardProvider>
      {props.children}
    </AuthWizardProvider>
  );
};

export default WizardLayout;
