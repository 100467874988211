import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const getPrestamos = (searchKey) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/prestamo/pend/${sesionToken}/1/${searchKey || '*'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getPrestamo = (id) => {
    if(!id) return;
    const sesionToken = authSesion();
    return fetch(`${urlApi}/prestamo/id/${sesionToken}/${id}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getTreasuries = () => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/tesoreria/nom/${sesionToken}/*`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getConceptos = (type) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/concepto/${sesionToken}/O/${type || 'C'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getBankAccount = () => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/cuentabanco/${sesionToken}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const setDesembolso = data => {
    if(!data) return;
    const sesionToken = authSesion();
    data.sesion = sesionToken;
    return fetch(`${urlApi}/desembolso`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const anular = sel => {
    return fetch(`${urlApi}/prestamo/anularDesembolso/${sel.idSolicitud}`, {
        method: 'POST',
        headers,
        body: null
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

export const desembolsoServices = {
  getPrestamos,
  getPrestamo,
  getConceptos,
  getTreasuries,
  getBankAccount,
  setDesembolso,
  anular
}