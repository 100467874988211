import React, { useState, useContext, useEffect } from 'react';
import {
  FormGroup,
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { DesembolsoContext } from '../DesembolsoContext';
import { currencyFormat } from '../../../helpers/tableFormatters';

const Deductions = () => {
  const { register, setValue, getValues } = useForm({
    defaultValues: {
      advanceInterest: 0,
      commission: 0,
      portfolioPurchase: 0,
      cheque: 0,
      otherPayments: 0,
    }
  });
  const context = useContext(DesembolsoContext);
  const { loading, selected, handleDeductions } = context;
  const [creditStudyValue, setCreditStudyValue] = useState(0);
  const [gmf, setGmf] = useState(0);

  useEffect(() => {
    if (!selected || !selected.paramPrestamo || !selected.paramPrestamo.porcEstudioCredito) return;
    const tempCreditStudyValue = selected.monto * (selected.paramPrestamo.porcEstudioCredito / 100);
    const tempGmf = (selected.monto / 1000) * 4;
    setCreditStudyValue(tempCreditStudyValue);
    setGmf(tempGmf);
    onSetData({gmf: tempGmf, creditStudyValue: tempCreditStudyValue})
    setValue("gmf", tempGmf)
    setValue("creditStudyValue", tempCreditStudyValue)
    setValue("assured", selected.valorPrima)
  }, [selected])

  const onChange = async e => {
    let { name, value } = e;
    if(!value){
      value = '0';
    }
    value = Number(value.replaceAll(",", "."))
    setValue(name, value);
    if(name == 'gmf') {
      setGmf(value)
    }

    if(name == 'creditStudyValue') {
      setCreditStudyValue(value)
    }
    onSetData();
  }

  const onSetData = (temp) => {
    const formData = getValues();
    if(temp) {
      formData.gmf = temp ? temp.gmf : gmf;
      formData.creditStudyValue = temp ? temp.creditStudyValue : creditStudyValue;
      formData.assured = selected.valorPrima
    }
    formData.updateValue = selected.valorActualizacion;
    formData.unificationValue = selected.valorUnificacion;
    console.log('FORM DATA', formData);
    handleDeductions(formData);
  }

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Deducciones Generales"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {selected ? (
          <form>
            <Row>

              <Col lg={4}>
                <DetailRow title="Valor Actualización">{currencyFormat(selected.valorActualizacion) || <span className='p-2'></span>}</DetailRow>
                <DetailRow title="Anticipo a Intereses">
                  <FormGroup className="pr-4">
                    <CurrencyInput
                      className="form-control pl-2"
                      allowNegativeValue={false}
                      name="advanceInterest"
                      id="advanceInterest"
                      prefix='$'
                      intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                      {...register('advanceInterest')}
                      onValueChange={(value) => onChange({name: 'advanceInterest', value })}
                      decimalScale={2}
                      maxLength={20}
                      placeholder="$ 0.00"
                      tabIndex="2" />
                  </FormGroup>
                </DetailRow>
                <DetailRow title="Valor Seguro Deudor">
                  <FormGroup className="pr-4">
                    <CurrencyInput
                    className="form-control pl-2 pr-2"
                    allowNegativeValue={false}
                    name="assured"
                    id="assured"
                    defaultValue={selected.valorPrima}
                    prefix='$'
                    intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                    {...register('assured')}
                    onValueChange={(value) => onChange({name: 'assured', value })}
                    decimalScale={2}
                    maxLength={20}
                    placeholder="$ 0.00"
                    tabIndex="1" />
                  </FormGroup>
                </DetailRow>
                <DetailRow title="Otros Pagos">
                  <FormGroup className="pr-4">
                    <CurrencyInput
                    className="form-control pl-2 pr-2"
                    allowNegativeValue={false}
                    name="otherPayments"
                    id="otherPayments"
                    prefix='$'
                    intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                    {...register('otherPayments')}
                    onValueChange={(value) => onChange({name: 'otherPayments', value })}
                    decimalScale={2}
                    maxLength={20}
                    placeholder="$ 0.00"
                    tabIndex="1" />
                  </FormGroup>
                </DetailRow>
              </Col>

              <Col lg={4}>
                <DetailRow title="Valor Unificación">{currencyFormat(selected.valorUnificacion) || <span className='p-2'></span>} </DetailRow>
                <DetailRow title="Valor Comisión">
                <FormGroup className="pr-4">
                    <CurrencyInput
                      className="form-control pl-2"
                      allowNegativeValue={false}
                      name="commission"
                      id="commission"
                      prefix='$'
                      intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                      {...register('commission')}
                      onValueChange={(value) => onChange({name: 'commission', value })}
                      decimalScale={2}
                      maxLength={20}
                      placeholder="$ 0.00"
                      tabIndex="3" />
                  </FormGroup>
                </DetailRow>
                <DetailRow title="Valor Estudio Crédito">
                  <FormGroup className="pr-4">
                    <CurrencyInput
                    className="form-control pl-2 pr-2"
                    allowNegativeValue={false}
                    name="creditStudyValue"
                    id="creditStudyValue"
                    value={Number(creditStudyValue)}
                    prefix='$'
                    intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                    {...register('creditStudyValue')}
                    onValueChange={(value) => onChange({name: 'creditStudyValue', value })}
                    decimalScale={2}
                    maxLength={20}
                    placeholder="$ 0.00"
                    tabIndex="1" />
                  </FormGroup>
                </DetailRow>
              </Col>

              <Col lg={4} className="text-right">
                <DetailRow title="Valor Compra de Cartera">
                <FormGroup className="pl-4">
                    <CurrencyInput
                      className="form-control pr-2 text-right"
                      allowNegativeValue={false}
                      name="portfolioPurchase"
                      id="portfolioPurchase"
                      prefix='$'
                      intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                      {...register('portfolioPurchase')}
                      onValueChange={(value) => onChange({name: 'portfolioPurchase', value })}
                      decimalScale={2}
                      maxLength={20}
                      placeholder="$ 0.00"
                      tabIndex="1" />
                  </FormGroup>
                </DetailRow>
                <DetailRow title="Valor GMF (4x1000)">
                  <FormGroup className="pl-4">
                    <CurrencyInput
                    className="form-control pr-2 text-right"
                    allowNegativeValue={false}
                    name="gmf"
                    id="gmf"
                    value={Number(gmf)}
                    prefix='$'
                    intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                    {...register('gmf')}
                    onValueChange={(value) => onChange({name: 'gmf', value })}
                    decimalScale={2}
                    maxLength={20}
                    placeholder="$ 0.00"
                    tabIndex="1" />
                  </FormGroup>
                </DetailRow>
                <DetailRow title="Valor del Cheque">
                <FormGroup className="pl-4">
                    <CurrencyInput
                      className="form-control pr-2 text-right"
                      allowNegativeValue={false}
                      name="cheque"
                      id="cheque"
                      prefix='$'
                      intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                      {...register('cheque')}
                      onValueChange={(value) => onChange({name: 'cheque', value })}
                      decimalScale={2}
                      maxLength={20}
                      placeholder="$ 0.00"
                      tabIndex="4" />
                  </FormGroup>
                </DetailRow>
              </Col>

            </Row>
          </form>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default Deductions;
