import React from 'react';
import { Page, View, Text, Image, Document } from '@react-pdf/renderer';
import { TableHeader, TableBody, TableCell, Table, DataTableCell } from  "@david.kucsai/react-pdf-table"
import styles from './styles';
import { currencyFormat } from '../../../helpers/formatters';
import logo from '../../../assets/img/illustrations/paynet.png'

const PDFTable = ({ headers, data, header }) => {
  
  return (
    <Document>
      <Page style={styles.container} orientation="landscape">
        {header && (
          <View style={styles.header}>
            <View
              style={{
                ...styles.headerCol,
                ...styles.logoContainer
              }}
            >
              <Image
                style={styles.logo}
                src={logo}
              />
            </View>
            <View
              style={{
                ...styles.headerCol,
                ...styles.companyInfo
              }}
            >
              <Text style={styles.strong}>SU OPERADORA DE LIBRANZA</Text>
              <Text style={styles.strong}>NIT. 900.344.946-3</Text>
              <Text>Calle 13 No. 8-94 Barrio Centro 2do. Piso</Text>
              <Text>Telefono: (605)726 0049</Text>
              <Text>Cel. 301 662 1260</Text>
              <Text>Maicao, La Guajira - Colombia</Text>
            </View>
          </View>
        )}
        
        <Table data={data} >
            <TableHeader textAlign={"center"}>
              {headers && headers.map(head => (
                <TableCell style={styles.headerLabel}>
                  {head.label}
                </TableCell>
              ))}
            </TableHeader>
            <TableBody>
              {data && headers && headers.map(item => (
                <DataTableCell style={styles.bodyLabel} getContent={(r) => {
                  return item.type == "money" ? currencyFormat(r[item.key]) : r[item.key]
                }}/>
              ))}
            </TableBody>
          </Table>
      </Page>
    </Document>
  )
}

export default PDFTable