import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import CountUp from 'react-countup';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr  from 'react-flatpickr';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import CardSummary from '../../../components/dashboard/CardSummary';
import { solicitudServices, simulatorServices } from '../../../services';
import RequiredMarked from '../../../components/common/RequiredMarked';
import { getLocaleCalendarConfig } from '../../../helpers/utils';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';
import SimulatorPlanModal from '../../simulator/SimulatorPlanModal';

const GeneralConditionsModal = ({ toggle, handleUpdate, open, title, data, currentUser }) => {
  const { register, triggerValidation, errors, setValue, getValues, replace } = useForm();
  const [creditLinesOptions, setCreditLinesOptions] = useState([]);
  const [creditSublinesOptions, setCreditSublinesOptions] = useState([]);
  const [formData, setFormData] = useState();
  const [ showPlan, setShowPlan ] = useState();

  const [disabledDate, setDisabledDate] = useState();

  const [ fee, setFee ] = useState();
  const [ bonus, setBonus ] = useState();
  const [ paymentsPlan, setPaymentsPlan ] = useState();

  const today = new Date();

  useEffect(() => {
    if(!data) return

    if(["1", 1].includes(data.idLinea)){
      setDisabledDate(true);
    } else {
      setDisabledDate(false)
    }

    const {
      monto, 
      tasa, 
      plazo, 
      tipoFormula,
      tasaPrima,
      valorPrima,
      fechaCorte,
      valorCuota} = data; 

    const tempFormData = {...formData};
    setFormData({
      ...tempFormData, 
      monto, 
      tasa, 
      plazo, 
      tipoFormula,
      tasaPrima,
      valorPrima,
      fechaCorte,
      valorCuota
    })
    setValue("monto", monto);
    setValue("tasa", tasa);
    setValue("plazo", plazo);
    setValue("tipoFormula", tipoFormula);
    setValue("tasaPrima", tasaPrima);
    setValue("valorPrima", valorPrima);
    setValue("fechaCorte", fechaCorte);
    setValue("valorCuota", valorCuota);
    setFee(valorCuota);
    setBonus(valorPrima);
  }, [data])

  const handleCalculate = async  data => {
    const payload = {
      type: data.tipoFormula,
      amount: data.monto,
      rate: data.tasa,
      term: data.plazo,
      bonus: data.tasaPrima
    }
    const resp = await simulatorServices.calculateFee(payload);
    if(resp.error) return;
    setFee(resp.objResponse.valorCuota);
    setBonus(resp.objResponse.valorPrima);
  }

  const onChange = async e => {
    const { name, value } = e.target;
    if(['monto', 'tasa', 'plazo', 'tasaPrima'].includes(name)) {
      let tempValue = value ? value.replaceAll('%', '').replaceAll('$', '').replaceAll('.', '').replaceAll(',', '.') : '0'
      tempValue = Number(tempValue);
      setValue(name, tempValue)
    }else{
      setValue(name, value);
    }
    const formCreditInfo = getValues();
    await triggerValidation();
    const isValid = Object.keys(errors).length < 1;
    handleCreditInfoForm(formCreditInfo);
  }

  const handleCreditInfoForm = (values) => {
    setFormData(values);
    handleCalculate(values)
  }

  // const handleCalculatePlan = (date, inFee) => {
  //   calculatePlan(date, inFee)
  // }

  // const calculatePlan = async (date, inFee) => {
  //   if(!date) return;
  //   const payload = {
  //     type: formData.tipoFormula,
  //     amount: formData.monto,
  //     rate: formData.tasa,
  //     term: formData.plazo,
  //     bonus: formData.tasaPrima
  //   }
  //   const resp = await simulatorServices.getPaymentsPlan(payload, inFee, date);
  //   if(resp.error) return;
  //   setPaymentsPlan(resp.objResponse)
  // }

  const onSubmit = async () => {

    const { monto, plazo, tasa, tipoFormula, tasaPrima, fechaCorte } = formData;

    if(!monto || 
      !plazo || 
      !tasa || 
      !tipoFormula || 
      !tasaPrima ||
      !fechaCorte) {
        cogoToast.error("Por favor completar los campos requeridos",
      { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
      }

    const payload = {
      solicitud: data.solicitud,
      credito: data.idCredito,
      fecha: data.fecha,
      idPagaduria: data.idPagaduria,
      idLinea: data.idLinea,
      idSubLinea: data.idSubLinea,
      idCliente: data.clienteId,
      idAsesor: currentUser.idAsesor,
      comision: 0,
      monto: formData.monto,
      plazo: formData.plazo,
      tasa: formData.tasa,
      tipoFormula: formData.tipoFormula,
      tasaPrima: formData.tasaPrima,
      fechaCorte: formData.fechaCorte,
      valorCuota: fee,
      estado: 1,
      idModalidad: data.idModalidad,
      refSolicitud: {}
    }

    const updates = {
      monto: payload.monto,
      plazo: payload.plazo,
      tasa: payload.tasa,
      tipoFormula: payload.tipoFormula,
      tasaPrima: payload.tasaPrima,
      fechaCorte: payload.fechaCorte,
      valorCuota: payload.valorCuota,
    }

    const response = await solicitudServices.updateSolicitud(payload);
    if(!response || response.error){
      return;
    }

    const { objResponse } = response;
    const newData = objResponse ? Array.isArray(objResponse) ? objResponse[0] : objResponse : null;

    handleUpdate(newData);
    toggle();
  }

  const handleModal = (value) => {
    setShowPlan(value)
  }

  return (
    <>
    {paymentsPlan && (
      <SimulatorPlanModal plan={paymentsPlan} open={showPlan} collapseOneOpen={handleModal} />
    )}
    <Modal size="lg" isOpen={open} toggle={() => toggle(!open)}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => toggle(!open)}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
        >
        {title}
      </ModalHeader>
      <ModalBody>
      <form onChange={onChange}>
        <Row>
        <Col lg={12} xs={12} >
          <FormGroup>
            <Label for="monto">
              <RequiredMarked />
              Monto Solicitado
            </Label>
            <CurrencyInput
              className="form-control"
              style={{
                fontSize: '1.6em',
                textAlign: 'right',
              }}
              allowNegativeValue={false}
              value={formData && formData.monto || 0}
              name="monto"
              id="monto"
              prefix='$'
              intlConfig={{ locale: 'es-CO', currency: 'COP' }}
              {...register("monto", {
                required: true,
              })}
              decimalScale={2}
              maxLength={20}
              placeholder="$ 0.00" />
          </FormGroup>
        </Col>
        <Col lg={12} xs={12} >
          <FormGroup>
            <Label for="tasa">
              Tasa de Interés
            </Label>
            <CurrencyInput
              className="form-control"
              allowNegativeValue={false}
              defaultValue={formData && formData.tasa || 0}
              name="tasa"
              id="tasa"
              suffix='%'
              {...register("tasa", {
                required: true,
              })}
              decimalScale={2}
              intlConfig={{ locale: 'es-CO', currency: 'COP' }}
              maxLength={5}
              placeholder="0.00%" />
          </FormGroup>
        </Col>
        <Col lg={12} xs={12} >
            <FormGroup>
              <Label for="plazo">
                <RequiredMarked />
                Plazo en Meses
              </Label>
              <CurrencyInput
                className="form-control"
                allowNegativeValue={false}
                allowDecimals={false}
                value={formData && formData.plazo || 0}
                name="plazo"
                id="plazo"
                {...register("plazo", {
                  required: true,
                })}
                decimalsLimit={0}
                maxLength={3}
                placeholder="0" />
            </FormGroup>
          </Col>
          <Col lg={12} xs={12} >
            <FormGroup>
              <Label for="tipoFormula">
                <RequiredMarked />
                Tipo de Fórmula
              </Label>
              <CustomInput
                type="select"
                id="tipoFormula"
                name="tipoFormula"
                value={formData && formData.tipoFormula || 1}
                className="text-uppercase"
                {...register("tipoFormula", {
                  required: true,
                })}
              >
                <option value="1" key={uuidv4()}>
                  Amortizable
                </option>
                <option value="2" key={uuidv4()}>
                  Fija
                </option>
              </CustomInput>
            </FormGroup>
          </Col>
          <Col lg={12} xs={12} >
            <FormGroup>
              <Label for="tasaPrima">
                <RequiredMarked />
                Porcentaje de Prima
              </Label>
              <CurrencyInput
                className="form-control"
                allowNegativeValue={false}
                name="tasaPrima"
                id="tasaPrima"
                defaultValue={formData && formData.tasaPrima || 1}
                suffix='%'
                {...register("tasaPrima", {
                  required: true,
                })}
                decimalScale={2}
                intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                maxLength={5}
                placeholder="0.00%" />
            </FormGroup>
          </Col>
          <Col lg={12} xs={12} >
            <FormGroup>
              <Label>Valor Prima</Label>
              <div className="display-4 fs-2">
                <CountUp end={bonus ? bonus : data ? data.valorPrima : 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
              </div>
            </FormGroup>
          </Col>
          <Col lg={12} xs={12} >
          <div className="card-deck">
            <div className="card mb-3 w-100 p-4">
              <form>
                <FormGroup>
                  <Label for="fechaCorte">
                    <RequiredMarked />
                    Fecha Primer Corte
                  </Label>
                  <Flatpickr
                    disabled={disabledDate}
                    className="form-control"
                    placeholder='aaaa/mm/dd'
                    name="fechaCorte"
                    id="fechaCorte"
                    value={formData && formData.fechaCorte || ""}
                    {...register('fechaCorte')}
                    options={{
                      dateFormat: "Y/m/d",
                      minDate: data ? data.fecha : today,
                      locale: getLocaleCalendarConfig(),
                    }}
                    onChange={(date, dateStr) => {
                      if(disabledDate) return;
                      onChange({target: {name: "fechaCorte", value: dateStr.replaceAll("/", "-")}})
                    }}
                  />
                </FormGroup>
              </form>
            </div>
            <CardSummary useBtn btnAction={() => console.log(true)} content={fee || data ? data.valorCuota : "0"} title="Cuota Mensual" color="info" linkText="Ver Plan de Pagos" rate="">
              {<CountUp end={fee ? fee : data ? data.valorCuota : 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />}
            </CardSummary>
          </div>
          </Col>
        </Row>
      </form>
      </ModalBody>
      <ModalFooter className="no-border">
        <Col className="text-right" >
          <Button onClick={() => toggle(!open)} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
            Cancelar
          </Button>
          <Button onClick={onSubmit} transform="shrink-3 down-2" color="primary" >
            Aceptar
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
    </>
  )
}

export default GeneralConditionsModal