import React, { useState, useContext, forwardRef, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import ButtonIcon from '../../../components/common/ButtonIcon';
import { RecaudoContext } from '../RecaudoContext';
import RequiredMarked from '../../../components/common/RequiredMarked';
import ModalSearchClient from '../../solicitud/ModalSearchClient';
import { formatterId } from '../../../helpers/formatters';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

const ClientInfo = ({ context: sourceContext, modalTitle, handleDateRange, showDatePicker, terceros, title, btnText, noCancel }) => {
  const context = useContext(sourceContext);
  const { client, onClientSelect, handleCleanClient } = context;
  const [showSearchClient, setShowSearchClient] = useState(false);

  const [dateRange, setDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 8)), new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if(!dateRange || !handleDateRange) return;
    handleDateRange(dateRange);
  }, dateRange)

  const handleSearchClient = () => {
    setShowSearchClient(!showSearchClient);
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      onClick={onClick}
      ref={ref}
      placeholder="Rango de fechas"
      value={value}
    />
  ));

  
  return (
    <>
    <ModalSearchClient title={modalTitle} terceros={terceros} show={showSearchClient} toggle={handleSearchClient} onSelect={(data) => {
      handleSearchClient();
      onClientSelect(data);
    }} />  
    <Card className={`my-3`} >
      <FalconCardHeader title={title || "Datos Generales del Cliente"}>
        <div className='d-flex'>
          {showDatePicker && (
            <div className='mx-2' style={{minWidth: '250px'}}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                locale="es"
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                customInput={<ExampleCustomInput />}
              />
            </div>
          )}
          {!noCancel && (
            <ButtonIcon onClick={handleCleanClient} icon={faTimes} transform="shrink-3 down-2" color="falcon-default" size="sm" className="mr-2">
              Cancelar
            </ButtonIcon>
          )}
          <ButtonIcon onClick={handleSearchClient} icon="user" transform="shrink-3 down-2" color="falcon-default" size="sm">
            {btnText || "Buscar Cliente"}
          </ButtonIcon>
        </div>
      </FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        <>
        {client ? (
        <Row>
          <Col lg className="col-xxl-5">
            <DetailRow title="Identificación">{client.terceroInformacion.identificacion 
              ? `${client.terceroInformacion.tipoIdentificacion ? client.terceroInformacion.tipoIdentificacion : ""} ${formatterId(client.terceroInformacion.identificacion)}`  
              : <span className='p-2'></span>}</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Telefono">{client.terceroUbicacion.telefono1 || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
            <DetailRow childrenClasses="text-uppercase" title="Razon Social o Nombre del Cliente">
              {!client.terceroInformacion.razonSocial ? (
                client.terceroInformacion.nombreCompleto ? 
                `${client.terceroInformacion.nombreCompleto}` :
                `${client.terceroInformacion.nombre1} ${client.terceroInformacion.nombre2}
                ${client.terceroInformacion.apellido1} ${client.terceroInformacion.apellido2}`
              ) : (
                `${client.terceroInformacion.razonSocial}`
              )}
            </DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Correo">{client.terceroUbicacion.correo || <span className='p-2'></span>}</DetailRow>
          </Col>
        </Row>
        ) : <Label for="pagaduria" className="py-4">
              <RequiredMarked />
              Por favor <a className='fw-bold pointer' onClick={handleSearchClient}>seleccione</a> un {terceros ? "tercero" : "cliente"}.
            </Label>  }
        </>
      </CardBody>
    </Card>
    </>
  );
};

export default ClientInfo;
