import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr  from 'react-flatpickr';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import { solicitudServices, advisersServices } from '../../../services';
import { getLocaleCalendarConfig } from '../../../helpers/utils';
import { getItemFromStore } from '../../../helpers/utils';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { EnvironmentContext } from '../../../context/Context';
import cogoToast from 'cogo-toast';
import RequiredMarked from '../../../components/common/RequiredMarked';

const ClientInfoModal = ({ toggle, handleUpdate, open, title, data }) => {
  const { register, triggerValidation, errors, setValue, getValues, replace } = useForm();
  const [creditLinesOptions, setCreditLinesOptions] = useState([]);
  const [creditSublinesOptions, setCreditSublinesOptions] = useState([]);
  const [formData, setFormData] = useState();
  const [currentUser, setCurrentUser] = useState();

  const environment = useContext(EnvironmentContext);
  const [pagadurias, setPagadurias] = useState([]);

  const today = new Date();

  useEffect(() => {
    loadPagadurias();
  }, [environment])

  useEffect(() => {
    if(!data) return

    const { idPagaduria } = data; 
    const tempFormData = {...formData};
    setFormData({
      ...tempFormData, 
      pagaduria: idPagaduria,
    })
    setValue("pagaduria", idPagaduria);
  }, [data])

  const loadPagadurias = async () => {
    if(!environment.pagadurias) return;
    const tempPagadurias = environment.pagadurias.map(pagaduria => ({
      value: pagaduria.id,
      label: pagaduria.nombreComercial || pagaduria.razonSocial 
    }))
    setPagadurias(tempPagadurias);
  }

  const onChange = async e => {
    const { name, value } = e.target;
    setValue(name, value);
    const formCreditInfo = getValues();
    await triggerValidation();
    const isValid = Object.keys(errors).length < 1;
    handleCreditInfoForm(formCreditInfo);
  }

  const handleCreditInfoForm = (values) => {
    setFormData(values);
  }

  const onSubmit = async () => {

    const { pagaduria } = formData;

    if(!pagaduria) {
        cogoToast.error("Por favor completar los campos requeridos",
      { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
      }

    const payload = {
      solicitud: data.solicitud,
      credito: data.credito,
      fecha: data.fecha,
      idPagaduria: formData.pagaduria,
      idLinea: data.idLinea,
      idSubLinea: data.idSubLinea,
      idCliente: data.clienteId,
      idAsesor: data.idAsesor,
      comision: 0,
      monto: data.monto,
      plazo: data.plazo,
      tasa: data.tasa,
      tipoFormula: data.tipoFormula,
      tasaPrima: data.tasaPrima,
      fechaCorte: data.fechaCorte,
      valorCuota: data.valorCuota,
      estado: 1,
      idModalidad: data.idModalidad,
      refSolicitud: {},
    }

    const response = await solicitudServices.updateSolicitud(payload);
    if(!response || response.error){
      cogoToast.error(response.msg || "Error al actualizar los datos de la solicitud", 
      { position: 'bottom-left', heading: 'Error' });
      return;
    }

    const { objResponse } = response;
    const newData = objResponse ? Array.isArray(objResponse) ? objResponse[0] : objResponse : null;
    handleUpdate(newData);
    toggle();
  }

  return (
    <Modal size="lg" isOpen={open} toggle={() => toggle(!open)}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => toggle(!open)}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
        >
        {title}
      </ModalHeader>
      <ModalBody>
      <form onChange={onChange}>
        <Row>
          <Col xl={12}>
            <FormGroup>
              <Label for="creditLine">
                <RequiredMarked />
                Pagaduria
              </Label>
              <WizardInput
                type="select"
                tag={CustomInput}
                placeholder="Seleccionar"
                id="pagaduria"
                value={formData && formData.pagaduria || ""}
                name="pagaduria"
                options={pagadurias}
                errors={errors}
                innerRef={register("pagaduria", {
                  required: true,
                })}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
      </ModalBody>
      <ModalFooter className="no-border">
        <Col className="text-right" >
          <Button onClick={() => toggle(!open)} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
            Cancelar
          </Button>
          <Button onClick={onSubmit} transform="shrink-3 down-2" color="primary" >
            Aceptar
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default ClientInfoModal