import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import React, { useContext, useEffect, useState } from 'react';
import MainTable from '../../../components/tables/MainTable';
import { currencyFormat, refFormat } from '../../../helpers/tableFormatters';
import RecaudoClientModal from '../modals/RecaudoClientModal';
import { RecaudoContext } from '../RecaudoContext';
import ClientInfo from '../sections/ClientInfo';

const RecaudoClient = () => {
  const context = useContext(RecaudoContext);
  const { loading, client, clientCredits, loadClientCredits } = context;
  const [showRecaudoModal, setShowRecaudoModal] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [creditSelected, setCreditSelected] = useState();

  useEffect(() => {
    if (!creditSelected) return;
    setModalTitle("Recaudo Crédito")
  })

  const columns = [
    {
      dataField: 'prestamo',
      text: 'Nro.',
      headerClasses: 'border-0',
      align: 'left',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField) => refFormat(dataField),
    },
    {
      dataField: 'credito',
      text: 'Nro. Crédito',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'fecha',
      text: 'Fecha',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'nombrePagaduria',
      text: 'Pagaduría',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'fechaCorte',
      text: 'Fecha Corte',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'valorCuota',
      text: 'Valor Cuota',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'recaudado',
      text: 'Recaudado',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'saldopro',
      text: 'Saldo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: 'Acciones',
      classes: 'border-0 py-2',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  const actionsFormatter = (dataField, fieldSelected) => {
    if (!fieldSelected) return <></>
    return (
      <div className='pl-4'>
        <IconButton
          onClick={() => handleCLickAction(fieldSelected)}
        >
          <FontAwesomeIcon
            style={{ width: '16px' }}
            icon={faCalculator}
            color="SteelBlue"
          />
        </IconButton>
      </div>
    )
  }

  const handleCLickAction = data => {
    setCreditSelected(data);
    setShowRecaudoModal(true);
  }

  const onCloseModal = value => {
    if (value) loadClientCredits();
    setShowRecaudoModal(!showRecaudoModal);
    setCreditSelected(null);
  }

  return (
    <>
      <RecaudoClientModal
        open={showRecaudoModal}
        data={creditSelected}
        toggle={onCloseModal}
      />
      <ClientInfo context={RecaudoContext} />
      <MainTable
        title="Créditos Activos"
        data={clientCredits}
        columns={columns}
        keyField="id"
        className="px-2"
        loading={loading}
        noSearch
        noAdd
      />
    </>
  )
}

export default RecaudoClient