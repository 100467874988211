import { StyleSheet, Font } from '@react-pdf/renderer';
import regular from '../../../assets/fonts/OpenSans-Regular.ttf'
import bold from '../../../assets/fonts/OpenSans-Bold.ttf'
Font.register({ family: 'Poppins', fonts: [
  { src: regular },
  { src: bold }
]});

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Poppins',
    padding: 20,
  },
  strong: {
    fontWeight: 'bold'
  },
  header: {
    display: 'flex',
    flexDirection: "row",
    padding: '1pt 0',
    height: '65pt',
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerCol: {
    width: "auto",
    height: '100%',
    flex: 1,
  },
  logoContainer: {
    display: 'flex',
    height: '60pt',
    justifyContent: 'center',
    width: "50%",
  },
  logo: {
    width: '180px'
  },
  companyInfo: {
    height: '100%',
    fontSize: '10pt',
    textAlign: 'right',
    width: "50%",
  },
  headerLabel: {
    fontSize: 10,
    fontWeight: 'bold'
  },
  bodyLabel: {
    padding: 5,
    fontSize: 10,
  },
})

export default styles;