import React, { useContext, useState, useEffect } from 'react'
import Card from 'reactstrap/lib/Card';
import { Font, PDFViewer, Page, View, Text, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { RecaudoContext } from '../RecaudoContext';
import styles from './styles'
import logo from '../../../assets/img/illustrations/paynet.png'
import check from '../../../assets/img/icons/check.png'
import { refFormat } from '../../../helpers/tableFormatters';
import { formatterId } from '../../../helpers/formatters';

export const RecaudoInvoiceViewer = () => {
  const context = useContext(RecaudoContext);
  const { client } = context;

  const [ownProps, setOwnProps] = useState();

  useEffect(() => {
    if (!client) return
    createProps()
  }, client)

  const createProps = () => {
    const tempOwnProps = {
      client: ""
    }

    setOwnProps(tempOwnProps)
  }

  return (
    <Card className="my-3 h-50 pdf_viewer">
      <PDFViewer>
        {ownProps && (
          <RecaudoInvoice data={ownProps} />
        )}
      </PDFViewer>
    </Card>
  )
}

const RecaudoInvoice = ({ data, title, egreso = false }) => {

  const [user, setUser] = useState({});

  useEffect(async () => {
    const tempUser = window.sessionStorage.getItem('Usuario');
    const parsedUser = await JSON.parse(tempUser);
    setUser(parsedUser)
  }, [])


  return (
    <Document>
      <Page style={styles.body} orientation="landscape">
        <View style={styles.header}>
          <View
            style={{
              ...styles.headerCol,
              ...styles.logoContainer
            }}
          >
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <View
            style={{
              ...styles.headerCol,
              ...styles.companyInfo
            }}
          >
            <Text style={styles.strong}>SU OPERADORA DE LIBRANZA</Text>
            <Text style={styles.strong}>NIT. 900.344.946-3</Text>
            <Text>Calle 13 No. 8-94 Barrio Centro 2do. Piso</Text>
            <Text>Telefono: (605)726 0049</Text>
            <Text>Cel. 301 662 1260</Text>
            <Text>Maicao, La Guajira - Colombia</Text>
          </View>
          <View
            style={{
              ...styles.headerCol,
              ...styles.serialContainer
            }}
          >
            <View style={styles.serialWrap}>
              <View style={styles.serialHeader}>
                <Text className="text-uppercase">{title || "COMPROBANTE DE INGRESO"}</Text>
              </View>
              <View style={styles.serialBody}>
                <Text>No.   </Text>
                <Text style={styles.serial}>{data.serial || "000"}</Text>
              </View>
            </View>

          </View>
        </View>
        <View style={styles.infoTable}>
          <View style={styles.infoRow}>
            <View style={styles.infoRowCol}>
              <Text style={styles.infoLabel}>Ciudad:</Text>
              <Text style={styles.textContent}>{data.ciudad}</Text>
            </View>
            <View style={styles.infoRowColStrech}>
              <Text style={styles.infoLabelStrech}>Fecha:</Text>
              <View style={styles.dateContent}>
              	<View style={styles.dateColumn}>
                  	<Text style={styles.dateMark}>D</Text>
                	<Text style={styles.textContent}>{data.date[2]}</Text>
                </View>
                <View style={styles.dateColumn}>
                  	<Text style={styles.dateMark}>M</Text>
                	<Text style={styles.textContent}>{data.date[1]}</Text>
                </View>
                <View style={styles.dateColumn}>
                  	<Text style={styles.dateMark}>A</Text>
                	<Text style={styles.textContent}>{data.date[0]}</Text>
                </View>
              </View>
            </View>
            <View style={styles.infoRowCol}>
              <Text style={styles.infoLabel}>Valor:</Text>
              <Text style={{ ...styles.textContent, ...styles.monto }}>{data.amount}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>{egreso ? 'Pagado a:' :'Recibido de:'}</Text>
            <Text style={styles.textContent}>{`${data.client}   ${data.clientTypeId}. ${data.clientId}`}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>Por Concepto de:</Text>
            <Text style={styles.textContent}>{data.concept}</Text>
          </View>
          <View
            style={{
              ...styles.infoRowSum,
              ...styles.noBorder,
            }}
          >
            <Text style={styles.infoLabelSum}>La Suma de: (en letras)</Text>
            <Text style={styles.textContent}>{data.sumWord} PESOS</Text>
          </View>
        </View>

        <View style={styles.detailTable}>
          <View style={styles.detailRow}>

            <View
              style={{
                ...styles.detailCol,
                ...styles.detailColLeft
              }}
            >
              <View style={styles.detailRowCount}>
                <View style={styles.countTable}>
                  <Text style={styles.countHeader}>FORMA DE PAGO</Text>
                  <View style={styles.checkRow}>
                    <View style={styles.check}>
                      {data.checkCash && (
                        <Image src={check}></Image>
                      )}
                    </View>
                    <Text style={styles.checkLabel}>EFECTIVO</Text>
                  </View>
                  {/* <View style={styles.countBody}>
                    <View style={styles.countRow}>
                      <Text style={styles.countCell}>CUENTA</Text>
                      <Text style={styles.countCell}>DEBITO</Text>
                      <Text style={styles.countCell}>CREDITO</Text>
                    </View>
                    <View style={styles.countRow}>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                    </View>
                    <View style={styles.countRow}>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                    </View>
                    <View style={styles.countRow}>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                    </View>
                    <View style={styles.countRow}>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                    </View>
                    <View style={styles.countRow}>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                    </View>
                    <View style={styles.countRow}>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                      <Text style={styles.countCell}></Text>
                    </View>
                  </View> */}
                </View>


                <View style={styles.checks}>
                  <Text style={styles.countHeader}>HUELLA</Text>
                  {/* <View style={styles.checkRow}>
                    <View style={styles.check}>
                      {data.checkCash && (
                        <Image src={check}></Image>
                      )}
                    </View>
                    <Text style={styles.checkLabel}>EFECTIVO</Text>
                  </View> */}
                  {/* <View style={styles.checkRow}>
                    <View style={styles.check}>
                      {data.checkWireTransfer && (
                        <Image src={check}></Image>
                      )}
                    </View>
                    <Text style={styles.checkLabel}>TRANSFERENCIA</Text>
                  </View>
                  <View style={styles.checkRow}>
                    <View style={styles.check}>
                      {data.checkPSE && (
                        <Image src={check}></Image>
                      )}
                    </View>
                    <Text style={styles.checkLabel}>PSE</Text>
                  </View>
                  <View style={styles.checkRow}>
                    <View style={styles.check}>
                      {data.checkCheque && (
                        <Image src={check}></Image>
                      )}
                    </View>
                    <Text style={styles.checkLabel}>CHEQUE</Text>
                  </View> */}
                  <Text>Indice derecho</Text>
                </View>

              </View>

              <View style={styles.detailRowCountBottom}>
                <View style={styles.detailBox}>
                  <Text style={styles.strong}>ELABORADO</Text>
                  <View>
                    <Text style={styles.boxValue}>{data.user || user.nombre}</Text>
                    <Text style={styles.boxValue}>{data.userIdParsed || `CC. ${formatterId(user.identificacion || 0)}`}</Text>
                  </View>
                </View>
                <View style={styles.detailBox}>
                  <Text style={styles.strong}>APROBADO</Text>
                  <Text style={styles.boxValue}></Text>
                </View>
                <View style={styles.detailBox}>
                  <Text style={styles.strong}>CONTABILIZADO</Text>
                </View>
              </View>

            </View>

            <View
              style={{
                ...styles.detailCol,
                ...styles.detailColRight
              }}
            >
              <View style={styles.signContainer}>
                <View style={styles.sign}>
                  <Text
                    style={{ ...styles.textContent, ...styles.strong }}
                  >FIRMA Y SELLO DEL BENEFICIARIO</Text>
                </View>
                <View style={styles.signIdContainer}>
                  <View style={styles.signBox}>
                    <Text style={styles.signTypeId}>C.C.</Text>
                    <View style={styles.signCheck}></View>
                  </View>
                  <View style={styles.signBox}>
                    <Text style={styles.signTypeId}>NIT.</Text>
                    <View style={styles.signCheck}></View>
                  </View>
                  <View style={styles.signBox}>
                    <Text style={styles.idLabel}>No.</Text>
                    <Text style={styles.textContent}></Text>
                  </View>
                </View>
                <View style={styles.receiverInfo}>
                  <View style={styles.receiverLabel}>
                    <Text>{egreso ? 'Fecha de Pagado:' :'Fecha de Recibido'}</Text>
                  </View>
                  <Text style={styles.textContent}></Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.fila}>
        {data.terceroAutorizado && <View style={styles.columna}>
          <Text style={styles.strong}>TERCERO AUTORIZADO:</Text>
          <Text style={styles.textContent} >{data.terceroAutorizado.tipoDocumento} {data.terceroAutorizado.numeroIdentificacion}</Text>
          <Text style={styles.textContent} >{data.terceroAutorizado.nombres} {data.terceroAutorizado.apellidos}</Text>
        </View>}
        <View style={styles.columna}>
          <Text style={styles.strong}>OBSERVACIONES:</Text>
          <Text style={styles.textContent} >{data.observations}</Text>
        </View>
        </View>
      </Page>
    </Document>
  )
}

export default RecaudoInvoice



