import React from 'react';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import AuthProvider from './context/AuthProvider';
import Layout from './layouts/Layout';

if (!window.myTabs) {
  window.myTabs = [];
  window.tabsId = 1;
  window.addTab = route => {
    var actual = window.myTabs.find(t => t.formulario.ruta == route.formulario.ruta);
    if (actual) {
      window.setActiveTab(actual.id);
    } else {
      window.setActiveTab(window.tabsId);
      window.myTabs.push({ ...route, id: window.tabsId++ });
    }
  };
}

const App = () => {
  return (
    <AuthProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </AuthProvider>
  );
};

export default App;
