import React from 'react';
import { Col } from 'reactstrap';
import DetailRow from './DetailRow';
import ListItem from './LIstItem';
import { nitOsviel } from '../../../configuracion/serverConfig';

const tiposContribuyentes = {
  "jur": "Persona jurídica o asimilada",
  "nat": "Persona natural o asimilada",
  "gran": "Gran contribuyente"
};

const ClasificationInfo = ({
    data,
    selected
}) => {

    return (
      <>
        <Col lg className="col-xxl-5">
          <DetailRow title="Actividad Principal">{data.infoActividad1.split('|').join(' - ')}</DetailRow>
        </Col>  
        <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
          <DetailRow title="Actividad Secundaria">{data.infoActividad2.split('|').join(' - ')}</DetailRow>
        </Col>
        <div className="p-4" style={{width: "100%"}}>
        <DetailRow title="Responsabilidades">
          {data && data.terceroResponsabilidad && (
              data.terceroResponsabilidad.map(p => (
                  <ListItem title={p.infoResponsabilidad.split('|').join(' - ')} />
              ))
          )}
        </DetailRow>
        </div>
        {selected.terceroInformacion.tipoIdentificacion === "NIT" && selected.terceroInformacion.identificacion == nitOsviel
         && (<Col lg className="col-xxl-5">
          <DetailRow title="Tipo contribuyente">{tiposContribuyentes[data.tipoContribuyente]}</DetailRow>
        </Col>)}
      </>
    )
}

export default ClasificationInfo;
