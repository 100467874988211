import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import 'react-confirm-alert/src/react-confirm-alert.css';
import cogoToast from 'cogo-toast';
import PageHeader from '../../components/common/PageHeader';
import MainTable from '../../components/tables/MainTable';
import { sessionsServices } from '../../services';
import { authSesion } from '../../helpers/authSesion';

const SessionsPage = () => {
  const [loading, setLoading] = useState(false);
  const [ListData, setListData] = useState([]);
  const [currentSearch, setCurrentSearch] = useState("");

  const columns = [
    {
        dataField: 'id',
        text: 'Id',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
        sort: true
    },
    {
        dataField: 'nombre',
        text: 'Nombre',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
        sort: true
    },
    {
      dataField: 'ip',
      text: 'Dirección IP',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'hora',
      text: 'Fecha y Hora',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
        dataField: '',
        headerClasses: 'border-0',
        text: 'Acciones',
        classes: 'border-0 py-2 align-middle',
        sort: false,
        formatter: (dataField, selected) => actionsFormatter(dataField, selected),
        align: 'center',
        headerAlign: 'center'
    },
  ];

  useEffect(() => {
    load();
  }, [])

  const load = async () => {
    setLoading(true);
    const response = await sessionsServices.getSessions(currentSearch)

    if(response.error){
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    setListData(response.objResponse);
    setLoading(false);
  }

  const handleSearch = async data => {
    let filter = data;
    setCurrentSearch(filter);

    const response = await sessionsServices.getSessions(filter)

    if(response.error){
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    setListData(response.objResponse);
  }

  const removeSession = async data => {
    const sessionToRemove = {
      sesion: data.sesion
    };

    const response = await sessionsServices.removeSession(sessionToRemove)

    if(response.error){
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    load();
  };

  const handleRemove = selectedSession => {
    confirmAlert({
      title: 'Eliminar sesión',
      message: '¿Desea eliminar la sesión seleccionada?',
      buttons:  [
        {
          label: 'Sí',
          onClick: () => removeSession(selectedSession)
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const actionsFormatter = (dataField, selected) => {
    if(!selected) return <></>
    const currentSession = authSesion();
    return (
      <div>
        {selected.sesion !== currentSession && (
          <Tooltip title="Eliminar">
            <IconButton
              onClick={() => handleRemove(selected)}
            >
              <FontAwesomeIcon
                style={{ width: '20px' }}
                icon={faTrash}
                color="red"
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
    )
  }

  return (
    <Fragment>
     <PageHeader
        title="Sesiones"
        className="mb-3"
      />    
      <MainTable 
        data={ListData} 
        columns={columns} 
        keyField="usuario"
        search={handleSearch}
        className="px-2"
        loading={loading}
        noAdd
      />
    </Fragment>
  );
}

export default SessionsPage;
