import React, { Fragment, useEffect, useState } from 'react';
// import Spinner from 'reactstrap/lib/Spinner';
import cogoToast from 'cogo-toast';
// import { Tooltip } from 'reactstrap';
import { faArrowLeft, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import MainTable from '../../components/tables/MainTable';
import { CREDIT_MODE, CREDIT_MODE_ID } from '../../data/constans';
import { currencyFormat, idFormat, refFormat } from '../../helpers/tableFormatters';
import { getItemFromStore } from '../../helpers/utils';
import { advisersServices, simulatorServices, solicitudServices } from '../../services';
import SimulatorPlanModal from '../simulator/SimulatorPlanModal';
import { PreliquidacionContext } from './PreliquidacionContext';
import SolicitudDetails from './SolicitudDetails';

const PreliquidacionPage = (props) => {
  const [loading, setLoading] = useState(false);

  const [searchKey, setSearchKey] = useState();
  const [refresh, setRefresh] = useState(false);

  const [solicitudList, setSolicitudList] = useState([]);
  const [showDetails, setShowDetails] = useState();
  const [selected, setSelected] = useState();
  const [selectedInfo, setSelectedInfo] = useState();

  const [anotationFormData, setAnotationFormData] = useState();
  const [creditInfoFormData, setCreditInfoFormData] = useState();
  const [creditModality, setCreditModality] = useState(1);

  const [paymentsPlan, setPaymentsPlan] = useState();
  const [showPlan, setShowPlan] = useState();

  const [selectedCredits, setSelectedCredits] = useState();
  const [updateBalance, setUpdateBalance] = useState(0);
  const [unificationBalance, setUnificationBalance] = useState(0);

  const [liqDate, setliqDate] = useState();

  const [currentUser, setCurrentUser] = useState();

  const columns = [
    {
      dataField: 'solicitud',
      text: 'Nro.',
      headerClasses: 'border-0',
      align: 'left',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField) => refFormat(dataField),
    },
    {
      dataField: 'fecha',
      text: 'Fecha',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'credito',
      text: 'Nro. Crédito',
      headerClasses: 'border-0',
      align: 'center',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'nomCliente',
      text: 'Cliente',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => clientFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'nomPagaduria',
      text: 'Pagaduría',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'monto',
      text: 'Monto',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'plazo',
      text: 'Plazo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: 'Acción',
      classes: 'border-0 py-2',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  useEffect(() => {
    if (!selected) return;
    loadSolicitudInfo();
    getCurrentUser();
  }, [selected])

  useEffect(() => {
    if (!selectedInfo) return;
    calculatePlan(selectedInfo.fechaCorte)
    if (props.liquidacion) {
      setUpdateBalance(selectedInfo.valorActualizacion)
      setUnificationBalance(selectedInfo.valorUnificacion)
    }
  }, [selectedInfo])

  useEffect(() => {
    loadSolicitudes();
  }, [searchKey, refresh])

  const actionsFormatter = (dataField, fieldSelected) => {
    if (!fieldSelected) return <></>
    return (
      <div className='pl-4'>
        <IconButton
          onClick={() => handleClickDetails(fieldSelected)}
        >
          <FontAwesomeIcon
            style={{ width: '16px' }}
            icon={faCalculator}
            color="SteelBlue"
          />
        </IconButton>
      </div>
    )
  }

  const getCurrentUser = async () => {
    const sesion = await getItemFromStore('Usuario', '', sessionStorage);
    const { identificacion } = sesion;

    const response = await advisersServices.getAdviserById(identificacion);
    if (!response || response.error) {
      return;
    }
    setCurrentUser(response.objResponse[0]);
  }

  const clientFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className='text-primary'>{fieldSelected.nomCliente}</div>
        <div>{`${fieldSelected.tipoIdCliente}`} {idFormat(fieldSelected.idCliente)}</div>
      </div>
    )
  }

  const handleBack = () => {
    setShowDetails(false);
    setSelected(null);
    setTimeout(() => {
      loadSolicitudes();
    }, 200)
  }

  const handleClickDetails = (solicitud) => {
    setSelected(solicitud);
    setShowDetails(true);
  }

  const calculatePlan = async date => {
    if (!date) return;
    const { tipoFormula: type, monto: amount, tasa: rate, plazo: term, idLinea: line } = selectedInfo;
    const resp = await simulatorServices.getPaymentsPlan({ type, amount, rate, term, line }, selectedInfo.valorCuota, date);
    if (resp.error) return;
    setPaymentsPlan(resp.objResponse)
  }

  const loadSolicitudes = async () => {
    setLoading(true);
    const response = props.liquidacion
      ? await solicitudServices.getLiqSolicitudes(searchKey)
      : await solicitudServices.getSolicitudes(searchKey);
    if (!response || response.error) {
      setLoading(false);
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar la información', hideAfter: 15 });
      return;
    }
    setSolicitudList(response.objResponse);
    setLoading(false);
  }

  const loadSolicitudInfo = async () => {
    setLoading(true);
    const response = await solicitudServices.getSolicitud(selected.solicitud);

    if (!response || response.error || !response.objResponse[0]) {
      setLoading(false);
      cogoToast.error(response.msg || "No se logro cargar los datos de la solicitud",
        { position: 'bottom-left', heading: 'Error' });
      handleBack();
      return;
    }
    setSelectedInfo(response.objResponse[0]);
    setLoading(false);
  }

  const handleSearch = value => {
    setRefresh(!refresh);
    setSearchKey(value);
  }

  const handlePlanModal = (value) => {
    if (!paymentsPlan) return
    setShowPlan(value)
  }

  const handleAnotationForm = (formData) => {
    setAnotationFormData(formData);
  }

  const handleCreditInfoForm = (formData) => {
    setCreditInfoFormData(formData);
  }

  const onChangeCreditMode = () => {
    setUpdateBalance(0);
    setUnificationBalance(0);
    setSelectedCredits(null);
  }

  const onSelecCreditModal = ({ total, data, mode }) => {
    setSelectedCredits(data);

    if ([CREDIT_MODE.UPDATE].includes(mode)) {
      setUpdateBalance(total);
    }

    if ([CREDIT_MODE.UNIFICATION].includes(mode)) {
      setUnificationBalance(total);
    }
  }

  const hdlPreliquidar = async (estado) => {

    if (!props.liquidacion && !creditInfoFormData) {
      cogoToast.error("Por favor complete la información del crédito.",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
    }

    if (!props.liquidacion && [CREDIT_MODE_ID.UNIFICATION, CREDIT_MODE_ID.UPDATE].includes(creditInfoFormData.creditMode)
      && !selectedCredits) {
      cogoToast.error("Por favor complete la información del crédito.",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
    }

    const observations = anotationFormData?.observations || selectedInfo.anotacion;

    let payload;

    setLoading(true);

    if (props.liquidacion) {
      payload = {
        idSolicitud: selected.solicitud,
        estado: estado || 3,
        fecha: liqDate || selected.fecha,
        anotacion: observations || "",
      }
    } else {
      payload = {
        solicitud: selected.solicitud,
        idModalidad: Number(creditInfoFormData.creditMode),
        estado: 2,
        valorActualizacion: updateBalance,
        valorUnificacion: unificationBalance,
        anotacion: observations || "",
        prestamos: selectedCredits
          ? selectedCredits.map(credit => ({
            prestamo: credit.prestamo,
            tipo: credit.tipo
          }))
          : []
      }
    }

    const response = props.liquidacion
      ? await solicitudServices.liquidarSolicitude(payload)
      : await solicitudServices.preliquidarSolicitude(payload);

    if (!response || response.error) {
      setLoading(false);
      cogoToast.error(response.msg || "No se logro completar la operación",
        { position: 'bottom-left', heading: 'Error' });
      return;
    }

    const resp = props.liquidacion
      ? await solicitudServices.getLiqSolicitudes(searchKey)
      : await solicitudServices.getSolicitudes(searchKey);

    if (!resp || resp.error) {
      setLoading(false);
      cogoToast.error(resp.msg,
        { position: 'bottom-left', heading: 'Error al cargar la información' });
      return;
    }

    setSolicitudList(resp.objResponse);

    cogoToast.success("Operación exitosa",
      { position: 'bottom-left', heading: 'Completa' });

    setLoading(false);
    handleBack();
  }

  const handleLiqDate = (value) => {
    setliqDate(value)
  }

  const handleUpdate = (data) => {
    setSelectedInfo({ ...data })
  }

  return (
    <PreliquidacionContext.Provider value={{
      liquidacion: props.liquidacion,
      currentUser,
      loading,
      liqDate,
      selected: selectedInfo,
      updateBalance,
      unificationBalance,
      selectedCredits,
      handleAnotationForm,
      handleCreditInfoForm,
      hdlPreliquidar,
      handlePlanModal,
      onChangeCreditMode,
      onSelecCreditModal,
      handleLiqDate,
      handleUpdate
    }}>
      <Fragment>
        {paymentsPlan && (
          <SimulatorPlanModal plan={paymentsPlan} open={showPlan} collapseOneOpen={handlePlanModal} />
        )}
        <PageHeader title={showDetails ? props.liquidacion ? "Liquidación" : "Pre-Liquidación" : props.liquidacion ? "Pendientes por Liquidar" : "Pendientes por Preliquidar"} className="mb-3" >
          {showDetails && <ButtonIcon className="mt-3" onClick={handleBack} icon={faArrowLeft} transform="shrink-3 down-2" color="falcon-default" size="sm">
            Atrás
          </ButtonIcon>}
        </PageHeader>
        {showDetails && selected ? (
          <SolicitudDetails />
        ) : (
          <MainTable
            data={solicitudList}
            currentSearch={searchKey}
            columns={columns}
            keyField="id"
            search={handleSearch}
            className="px-2"
            loading={loading}
            noAdd
          />
        )}
      </Fragment>
    </PreliquidacionContext.Provider>
  );
};

export default PreliquidacionPage
