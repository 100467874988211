import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Row, Spinner } from 'reactstrap';
import ButtonIcon from '../../components/common/ButtonIcon';
import MainTable from '../../components/tables/MainTable';
import { formatterId } from '../../helpers/formatters';
import numToWord from '../../helpers/numToWord';
import { currencyFormat } from '../../helpers/tableFormatters';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import { creditServices } from '../../services';
import RecaudoInvoice from '../recaudar/invoice/RecaudoInvoice';
import CreditGeneralData from './sections/CreditGeneralData';
import CreditInfo from './sections/CreditInfo';
import { StateContext } from './StateContext';

const CreditDetails = () => {
  const context = useContext(StateContext);
  const { loading, creditSelected: selected } = context;
  const [paymentsPlan, setPaymentsPlan] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [invoiceProps, setInvoiceProps] = useState();

  useEffect(() => {
    if (!selected) return;
    getCreditPlan();
  }, [selected])

  const generaRecibo = (pago) => {
    setInvoiceProps({
      client: selected.nomCliente,
      clientTypeId: selected.tipoIdCliente,
      clientId: formatterId(Number(selected.idCliente)),
      serial: "RC-" + pago.movimiento,
      ciudad: 'MAICAO - LA GUAJIRA',
      idCredit: selected.idCredito,
      amount: currencyFormat(pago.valorRecaudo || 0),
      concept: pago.concepto,
      sumWord: numToWord(pago.valorRecaudo),
      checkCash: pago.modalidadPago == 1,
      checkWireTransfer: pago.modalidadPago == 2,
      checkPSE: false,
      checkCheque: pago.modalidadPago == 3,
      date: pago.fecha.split('-'),
      observations: pago.observacion
    });
    setShowModal(true);
  };

  const detalle = (index) => {
    var ele;
    while (ele = document.getElementById("row" + index++)) {
      ele = ele.parentElement.parentElement;
      ele.style.display = ele.style.display == "none" ? "" : "none";
    }
  };

  const columns = [
    {
      dataField: 'periodo',
      text: 'Cuota',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (value, pago, index) => {
        return <div id={"row" + (value ? -index : index)}>
          {value ? ([
            value,
            paymentsPlan[index + 1] && !paymentsPlan[index + 1].periodo && <IconButton onClick={() => detalle(index + 1)}>
              <FontAwesomeIcon style={{ width: '16px' }} icon={faEye} color="SteelBlue" />
            </IconButton>]) : <></>}
        </div>;
      },
    },
    {
      dataField: 'fechaCorte',
      headerClasses: 'border-0 text-left',
      text: 'Fecha Corte',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha Pago',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'concepto',
      headerClasses: 'border-0 text-left',
      text: 'Concepto',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'movimiento',
      headerClasses: 'border-0 text-left',
      text: 'No. comprobante',
      formatter: (value, pago) => {
        return <div>
          {value ? ([
            "RC-" + value,
            <IconButton onClick={() => generaRecibo(pago)}>
              <FontAwesomeIcon style={{ width: '16px' }} icon={faEye} color="SteelBlue" />
            </IconButton>]) : <></>}
        </div>;
      },
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'valorRecaudo',
      headerClasses: 'border-0 text-right',
      text: 'Valor Pagado',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'abonoInteres',
      headerClasses: 'border-0 text-right',
      text: 'Interés',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'abonoCapital',
      headerClasses: 'border-0 text-right',
      text: 'Capital',
      formatter: (value) => /*value < 0 ? currencyFormat(-value, "($", ")") : */currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'descuento',
      headerClasses: 'border-0 text-right',
      text: 'Descuento',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'saldoProyectado',
      headerClasses: 'border-0 text-right pr-3',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle pr-3',
    },
  ]

  const getCreditPlan = async () => {
    const resp = await creditServices.getCreditPayments(selected.prestamo);
    if (resp.error) return;
    setPaymentsPlan(resp.objResponse)
  }

  const toggleModal = () => {
    setShowModal(false);
  }

  if (loading) {
    return (
      <Row className="py-4 d-flex justify-content-center">
        <Spinner color="primary" />
      </Row>
    )
  }

  return (
    <>
      <Modal isOpen={showModal} size="md" toggle={() => toggleModal()}>
        {invoiceProps && (
          <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
            <PDFDownloadLink document={<RecaudoInvoice title="Recibo de caja" data={invoiceProps} />} fileName={`recibo_caja-${invoiceProps.serial}.pdf`} >
              {({ loading }) =>
                loading ? 'Cargando recibo...' : 'Descargar recibo de caja'
              }
            </PDFDownloadLink>
          </ButtonIcon>)}
      </Modal>
      <ContentWithAsideLayout aside={<CreditGeneralData />} isStickyAside={false}>
        <CreditInfo />
      </ContentWithAsideLayout>
      <MainTable
        loading={!paymentsPlan}
        data={paymentsPlan}
        columns={columns}
        keyField="periodo"
        headerClassName="d-none"
        pageSize={12}
        className='pr-4'
        noSearch
        noAdd
        rowStyle={(row) => {
          if (!row.periodo) {
            return {
              fontStyle: "italic",
              color: "#777",
              display: "none"
            };
          }
        }}
      />
    </>
  )
}

export default CreditDetails;