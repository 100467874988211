import React, { useState, useEffect, Fragment } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import cogoToast from 'cogo-toast';
import { RecaudoContext } from './RecaudoContext';
import PageHeader from '../../components/common/PageHeader';
import RecaudoClient from './childrens/RecaudoClient';
import RecaudoLote from './childrens/RecaudoLote';
import types from '../../data/recaudo/types';
import { creditServices } from '../../services';

const RecaudarPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [client, setClient] = useState();
  const [pagSelected, setPagSelected] = useState();
  const [clientCredits, setClientCredits] = useState([]);
  const [creditsPag, setCreditsPag] = useState([]);

  const [recaudoDate, setRecaudoDate] = useState();

  const [showModalLote, setShowModalLote] = useState(false);

  useEffect(() => {
    if (!client) return;
    loadClientCredits();
  }, [client])

  const toggleTab = id => {
    setActiveTab(id);
  }

  const loadClientCredits = async () => {
    const response = await creditServices.getCreditsByClient(client.id);
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar los créditos del cliente' });
      return;
    }

    setClientCredits(response.objResponse);
  }

  const loadCreditsByPag = async (pag) => {
    if(!pag){
      setPagSelected(null);
      setCreditsPag([]);
      return;
    }
    setPagSelected(pag)
    const response = await creditServices.getCreditsByPagaduria(pag.id);
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar los créditos por lote' });
      return;
    }

    setCreditsPag(response.objResponse);
  }

  const onClientSelect = data => {
    setClient(data)
  }

  const toggleModalLote = (value) => {
    if((value || !showModalLote) && !pagSelected) return;
    setShowModalLote(value || !showModalLote);
  }

  const handleSaveLote = () => {
    toggleModalLote(false);
    setPagSelected(null);
    setCreditsPag([]);
  }

  const onCancelLote = () => {
    setPagSelected(null);
    setCreditsPag([]);
  }

  const handleCleanClient = () => {
    setClient(null);
    setClientCredits([]);
  }

  const handleRecaudoDate = (value) => {
    setRecaudoDate(value);
  }

  return (
    <RecaudoContext.Provider value={{
      loading,
      recaudoDate,
      client,
      pagSelected,
      clientCredits,
      creditsPag,
      onClientSelect,
      showModalLote,
      loadClientCredits,
      loadCreditsByPag,
      toggleModalLote,
      handleSaveLote,
      onCancelLote,
      handleCleanClient,
      handleRecaudoDate,
    }}>
      <Fragment>
        <PageHeader title="Recaudo" className="mb-3" >
        </PageHeader>
        <div>
          <Nav tabs className="custom-nav">
            {types && types.map((type, idx) => (
              <NavItem className="flex-grow-1 text-center cursor-pointer">
                <NavLink onClick={() => toggleTab(type.id)} className={`p-3  ${activeTab == (idx+1) ? 'active' : '' }`}>
                  {type.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <RecaudoClient />
            </TabPane>
            <TabPane tabId="2">
              <RecaudoLote />
            </TabPane>
            <TabPane tabId="3">
            </TabPane>
          </TabContent>
        </div>
      </Fragment>
    </RecaudoContext.Provider>
  );
};

export default RecaudarPage
