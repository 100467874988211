import React, { useContext } from 'react';
import CountUp from 'react-countup';
import {
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { currencyFormat, percentageFormat } from '../../../helpers/tableFormatters';
import { StateContext } from '../StateContext';

const GeneralConditionsInfo = () => {
  const context = useContext(StateContext);
  const { loading, creditSelected: selected } = context;

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Condiciones Generales"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {!loading && selected ? (
          <Row className="text-right fs-3">
            <Col lg={12}>
              <DetailRow className="pb-3" textNormal title="Monto del Crédito">{selected.monto ? currencyFormat(selected.monto) : <span className='p-2'></span>}</DetailRow>
              <Row className="ml-3 justify-content-between pb-0">
                <Col lg={5} className='d-flex flex-column p-0'>
                  <div className='fs--2 text-left'>
                    Tipo fórmula
                  </div>
                  <div className='text-left' style={{ fontSize: '1.2rem' }}>
                    {selected.nomTipoFormula || <span className='p-2'></span>}
                  </div>
                </Col>
                <Col lg={7} className='d-flex flex-column p-0'>
                  <div className='fs--2'>
                    Tasa de Interés
                  </div>
                  <div>
                    {selected.tasa ? percentageFormat(selected.tasa) : <span className='p-2'></span>}
                  </div>
                </Col>
              </Row>
              <Row className="ml-3 justify-content-between pb-0">
                <Col lg={5} className='d-flex flex-column p-0'>
                  <div className='fs--2 text-left'>
                    Plazo
                  </div>
                  <div className='text-left'>
                    {selected.plazo || <span className='p-2'></span>}
                  </div>
                </Col>
                <Col lg={7} className='d-flex flex-column p-0'>
                  <div className='fs--2'>
                    Valor Cuota
                  </div>
                  <div>
                    <CountUp end={selected.valorCuota || 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default GeneralConditionsInfo;
