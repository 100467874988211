import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useContext, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Modal,
  Row,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import ButtonIcon from '../../../components/common/ButtonIcon';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import ThemeBackground from '../../../components/common/ThemeBackground';
import { CREDIT_MODE_ID } from '../../../data/constans';
import { formatterId } from '../../../helpers/formatters';
import numToWord from '../../../helpers/numToWord';
import { currencyFormat, idFormat, refFormat } from '../../../helpers/tableFormatters';
import RecaudoInvoice from '../../recaudar/invoice/RecaudoInvoice';
import { StateContext } from '../StateContext';
import ModalCredits from '../children/CreditSelectModal';

const CreditInfo = () => {
  const context = useContext(StateContext);
  const { creditSelected: selected, handleRefinance } = context;

  const [showThirdModal, setShowThirdModal] = useState();
  const [thirdCredits, setThirdCredits] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ownProps, setOwnProps] = useState();

  const handleSelectCreditModal = () => {
    setThirdCredits(selected.prestamos)
    setShowThirdModal(true);
  }

  const closeModal = () => {
    setShowThirdModal(false)
    setThirdCredits(selected.prestamos)
  }

  const toggleModal = () => {
    setShowModal(false);
  }

  const generaRecibo = () => {
    setOwnProps({
      client: selected.nomCliente,
      clientTypeId: selected.tipoIdCliente,
      clientId: formatterId(Number(selected.idCliente)),
      serial: "CE-" + selected.serial,
      ciudad: 'MAICAO - LA GUAJIRA',
      idCredit: selected.idCredito,
      amount: currencyFormat(selected.monto),
      concept: selected.tesoreria,
      sumWord: numToWord(selected.monto),
      checkCash: selected.modalidad == 1,
      checkWireTransfer: selected.modalidad == 2,
      checkPSE: false,
      checkCheque: selected.modalidad == 3,
      date: selected.fechaDesembolso.split('-'),
      observations: selected.observacion,
      terceroAutorizado: selected.autorizado
    });
    setShowModal(true);
  };

  return (
    <>
      <Modal isOpen={showModal} size="md" toggle={() => toggleModal()}>
        {ownProps && (
          <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
            <PDFDownloadLink document={<RecaudoInvoice title="Comprobante Egreso" data={ownProps} egreso />} fileName={`comprobante_egreso_${selected.idCredito}.pdf`} >
              {({ loading }) =>
                loading ? 'Cargando comprobante...' : 'Descargar comprobante de egreso'
              }
            </PDFDownloadLink>
          </ButtonIcon>)}
      </Modal>
      <ModalCredits open={showThirdModal} title="Refinanciación" data={thirdCredits} toggle={closeModal} />
      <Card className="my-3" >
        <ThemeBackground color='info' />
        <FalconCardHeader title="Información del Crédito">
          {selected && selected.estado == 2 && (
            <ButtonIcon onClick={handleRefinance} color="falcon-primary" size="sm">
              Refinanciar
            </ButtonIcon>
          )}
        </FalconCardHeader>
        <CardBody className="border-top px-4">
          {selected && ([
            <Row>
              <Col lg={4} xs={4}>
                <DetailRow title="Nro. de Credito">{selected.idCredito || <span className='p-2'></span>}</DetailRow>
                <DetailRow title="Línea de Crédito">{selected.nomLinea || <span className='p-2'></span>}</DetailRow>
                <DetailRow title="Modalidad de Crédito">{selected.nomModalidad || <span className='p-2'></span>}</DetailRow>
                <DetailRow title="Identificación">
                  {selected.tipoIdCliente} {idFormat(selected.idCliente)}
                </DetailRow>
              </Col>

              <Col lg={4} xs={4}>
                <DetailRow title="Fecha Solicitud">{selected.fechaSolicitud || <span className='p-2'></span>}</DetailRow>
                <DetailRow title="Sublínea de Crédito">{selected.nomSubLinea || <span className='p-2'></span>}</DetailRow>

                {[CREDIT_MODE_ID.UPDATE].includes(`${selected.idModalidad}`) && (
                  <DetailRow title="Monto Actualización">{selected.valorActualizacion ? currencyFormat(selected.valorActualizacion) : <span className='p-2'></span>}</DetailRow>
                )}
                {[CREDIT_MODE_ID.UNIFICATION].includes(`${selected.idModalidad}`) && (
                  <DetailRow title="Monto Unificación">{selected.valorUnificacion ? currencyFormat(selected.valorUnificacion) : <span className='p-2'></span>}</DetailRow>
                )}

                <DetailRow title="Razón Social o Nombre del Cliente" childrenClasses="text-uppercase">{selected.nomCliente || <span className='p-2'></span>}</DetailRow>

                {[CREDIT_MODE_ID.REFINANCE].includes(`${selected.idModalidad}`) ? (
                  <DetailRow title="-">
                    <a className="font-weight-semi-bold fs--1 text-nowrap cursor-pointer" onClick={() => handleSelectCreditModal(true)}>
                      {/* {liquidacion
                    && [CREDIT_MODE_ID.UPDATE].includes(`${selected.idModalidad }`)
                      && "Ver Créditos Actualizados"}
                  {liquidacion
                    && [CREDIT_MODE_ID.UNIFICATION].includes(`${selected.idModalidad }`)
                      && "Ver Créditos Unificados"} */}
                      {[CREDIT_MODE_ID.REFINANCE].includes(`${selected.idModalidad}`)
                        && "Ver crédito Refinanciado"}
                      <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
                    </a>
                  </DetailRow>
                ) : <></>}

              </Col>

              <Col lg={4} xs={4} className="text-right">
                <DetailRow title="Fecha de Desembolso">
                  {selected.fechaDesembolso || <span className='p-2'></span>}
                  {selected.serial && <IconButton onClick={() => generaRecibo()}>
                    <FontAwesomeIcon style={{ width: '16px' }} icon={faEye} color="SteelBlue" />
                  </IconButton>}
                </DetailRow>
                <DetailRow title="Pagaduría">{selected.nomPagaduria || <span className='p-2'></span>}</DetailRow>
                <DetailRow title="Saldo Actual">{selected.saldoProyectado ? currencyFormat(selected.saldoProyectado) : <span className='p-2'></span>}</DetailRow>
                <DetailRow title="Fecha Vencimiento">{selected.fechaVencimiento || <span className='p-2'></span>}</DetailRow>
              </Col>

            </Row>,
            <Row>
              <Col lg={4} xs={4}>
                <DetailRow title="Nro. Solicitud">
                  {selected.idSolicitud && refFormat(selected.idSolicitud) || <span className='p-2'></span>}
                </DetailRow>
              </Col>
              <Col lg={8} xs={8}>
                <DetailRow title="Anotaciones">
                  {selected.anotacion || <span className='p-2'></span>}
                </DetailRow>
              </Col>
            </Row>
          ])}
        </CardBody>
      </Card>
    </>
  );
};

export default CreditInfo;
