import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import cogoToast from 'cogo-toast';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import CardSummary from '../../components/dashboard/CardSummary';
import MainTable from '../../components/tables/MainTable';
import { MODAL_MODE, MODAL_VIEW } from '../../data/constans';
import { currencyFormat } from '../../helpers/tableFormatters';
import { creditServices } from '../../services';
import ThirdCreditSelectModal from './ThirdCreditSelectModal';


function CreditSelectModal({ viewMode, mode, title, inSelectedCredits, inThirdCredits, inTotal, open, idClient, collapseOneOpen, onSubmit }) {
  const [creditList, setCreditList] = useState([]);
  const [selectedCredits, setSelectedCredits] = useState([]);
  const [thirdCredits, setThirdCredits] = useState([]);
  const [total, setTotal] = useState(0);

  const [showSelectThridCreditModal, setShowSelectThirdCreditModal] = useState();

  const columns = [
    {
      dataField: 'prestamo',
      text: '#',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'credito',
      text: 'Nro. Crédito',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'saldopro',
      headerClasses: 'border-0 text-right pr-3',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 pr-3 text-right align-middle',
    }
  ]

  const columnsThirdCredits = [
    {
      dataField: 'prestamo',
      text: '#',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'credito',
      text: 'Nro. Crédito',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (dataField, selected) => clientFormatter(dataField, selected),
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'saldopro',
      headerClasses: 'border-0 text-right pr-3',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 pr-3 text-right align-middle',
    },
    {
      dataField: '',
      headerClasses: 'border-0',
      text: 'Acción',
      classes: 'border-0 py-2 align-middle',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(selected),
      align: 'center',
      headerAlign: 'center'
    },
  ]

  const clientFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div>{fieldSelected.credito}</div>
        <div className='text-primary' style={{ fontSize: '0.8em' }}>{fieldSelected.nombreTercero}</div>
      </div>
    )
  }

  const actionsFormatter = (credit) => {
    if (!credit || [MODAL_VIEW.READONLY].includes(viewMode)) return <></>
    return (
      <div>
        <IconButton
          onClick={() => {
            handleRemoveThirdCredit(credit)
          }}
        >
          <FontAwesomeIcon
            style={{ width: '12px' }}
            icon={faTrash}
            color="red"
          />
        </IconButton>
      </div>
    )
  }

  useEffect(() => {
    if (!idClient || [MODAL_VIEW.READONLY].includes(viewMode)) return;
    loadCredits(idClient);
  }, [])

  useEffect(() => {
    if (inSelectedCredits) {
      setSelectedCredits(inSelectedCredits);
    };

    if (inThirdCredits) {
      setThirdCredits(inThirdCredits);
    };

    if (inTotal) {
      setTotal(inTotal);
    }

  }, [inSelectedCredits, inThirdCredits, inTotal])

  useEffect(() => {
    let tempTotal = 0;
    if (!selectedCredits && !thirdCredits) return;

    if (selectedCredits) {
      selectedCredits.forEach(credit => {
        tempTotal += credit.saldopro;
      })
    }

    if (thirdCredits) {
      thirdCredits.forEach(credit => {
        tempTotal += credit.saldopro;
      })
    }

    setTotal(tempTotal);
  }, [selectedCredits, thirdCredits])

  const loadCredits = async (id) => {
    const response = await creditServices.getCreditsByClient(id);
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar la información' });
      return;
    }

    setCreditList(response.objResponse);
  }

  const handleAddThridCredit = () => {
    setShowSelectThirdCreditModal(true);
  }

  const handleSelect = (data, isSelect) => {
    data.tipo = 1;
    if ([MODAL_VIEW.READONLY].includes(viewMode)) return;
    let tempSelecetdCredits;
    if (isSelect) {
      tempSelecetdCredits = [...selectedCredits];
      tempSelecetdCredits.push(data);
    } else {
      tempSelecetdCredits = selectedCredits.filter(credit => credit.prestamo != data.prestamo);
    }

    if ([MODAL_MODE.SINGLE].includes(mode)) {
      setSelectedCredits([data])
      handleSubmit({ total: data.saldopro, data: [data] })
    } else {
      setSelectedCredits(tempSelecetdCredits)
    }
  }

  const handleSubmit = (value) => {
    onSubmit(value || { total, data: [...selectedCredits, ...thirdCredits] });
    collapseOneOpen(!open)
  }

  const handleSubmitThirdCredit = (credit) => {
    credit.data.tipo = 2;
    const isAdded = thirdCredits.findIndex(c => c.prestamo === credit.data.prestamo)
    if (isAdded >= 0) return;
    setThirdCredits([...thirdCredits, credit.data]);
  }

  const handleRemoveThirdCredit = (creditToRemove) => {
    if ([MODAL_VIEW.READONLY].includes(viewMode)) return;
    const tempThirdCredits = thirdCredits.filter(credit => credit.prestamo !== creditToRemove.prestamo);

    setThirdCredits(tempThirdCredits);
  }

  return (
    <Modal size="lg" isOpen={open} toggle={() => collapseOneOpen(!open)}>
      {showSelectThridCreditModal && (
        <ThirdCreditSelectModal
          viewMode={MODAL_VIEW.WRITE}
          mode={MODAL_MODE.SINGLE}
          title='Agregar Crédito de Tercero'
          open={showSelectThridCreditModal}
          collapseOneOpen={() => setShowSelectThirdCreditModal(!showSelectThridCreditModal)}
          onSubmit={handleSubmitThirdCredit}
        />
      )}
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => collapseOneOpen(!open)}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
        {title}
      </ModalHeader>
      <ModalBody className="p-0">
        <MainTable
          title="Créditos Propios"
          data={[MODAL_VIEW.READONLY].includes(viewMode) ? inSelectedCredits : creditList}
          columns={columns}
          keyField="prestamo"
          pageSize={12}
          handleSelect={{
            mode: [MODAL_MODE.SINGLE].includes(mode) ? 'radio' : 'checkbox',
            clickToSelect: true,
            selected: selectedCredits.map(credit => credit.prestamo),
            onSelect: handleSelect,
            hideSelectAll: true,
            selectColumnPosition: 'right',
            selectColumnStyle: { paddingLeft: '20px', verticalAlign: 'middle' }
          }}
          className='mb-0 shadow-none'
          rowClasses="main-table-custom-row"
          headerClassName='bg-light'
          noSearch
          noAdd
        />
        {[MODAL_MODE.MULTIPLE].includes(mode) && (
          <>
            <MainTable
              title="Créditos de Terceros"
              data={thirdCredits}
              columns={columnsThirdCredits}
              keyField="prestamo"
              pageSize={12}
              handleAddNew={handleAddThridCredit}
              className='mb-0 shadow-none'
              headerClassName='bg-light'
              noSearch
              noAdd={[MODAL_VIEW.READONLY].includes(viewMode)}
            />
            <CardSummary
              content={total}
              rate=""
              title="Total Seleccionado"
              color="success"
              linkText="Statistics"
              className='shadow-none text-right'
              noLink
              noBg
            >
              <CountUp end={total} duration={1} prefix="$" separator="." decimal="," decimals={2} />
            </CardSummary>
          </>
        )}
      </ModalBody>
      {[MODAL_MODE.MULTIPLE].includes(mode) && ![MODAL_VIEW.READONLY].includes(viewMode) && (
        <ModalFooter className="no-border">
          <Col className="text-right" >
            <Button onClick={() => collapseOneOpen(!open)} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
              Cancelar
            </Button>
            <Button onClick={() => handleSubmit()} transform="shrink-3 down-2" color="primary" >
              Aceptar
            </Button>
          </Col>
        </ModalFooter>
      )}
      {[MODAL_VIEW.READONLY].includes(viewMode) && (
        <ModalFooter className="no-border">
          <Col className="text-right" >
            <Button onClick={() => collapseOneOpen(!open)} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
              Aceptar
            </Button>
          </Col>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default CreditSelectModal
