import React, { useState, useEffect, Fragment } from 'react';
import PageHeader from '../../components/common/PageHeader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import * as Icons from '@fortawesome/free-solid-svg-icons';
import { urlApi } from '../../configuracion/serverConfig';
import { headers } from "../../helpers/authSesion";
import cogoToast from 'cogo-toast';
import ButtonIcon from '../../components/common/ButtonIcon';
import XLSX from 'sheetjs-style'

const fecha = new Date().toLocaleDateString();

const CarteraPage = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [mora, setMora] = useState(0);
  const [s1y30, setS1y30] = useState(0);
  const [s31y60, setS31y60] = useState(0);
  const [s61y120, setS61y120] = useState(0);
  const [s121y360, setS121y360] = useState(0);
  const [s361, setS361] = useState(0);

  const exportar = () => {
    const table = document.getElementById("tablaExport");
    const wb = XLSX.utils.table_to_book(table);
    /* Export to file (start a download) */
    XLSX.writeFile(wb, "cartera-" + fecha + ".xlsx");
  };

  const getCartera = () => {
    return fetch(`${urlApi}/informe/cartera`, {
      method: 'GET',
      headers,
    }).then(res => res.json())
      .then(data => data)
      .then(data => {
        return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
      }).catch(err => {
        console.log("Error", err);
        return { error: true, msg: "ERROR" }
      });
  };

  const load = async () => {
    const response = await getCartera();
    if (response.error) {
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }
    const res = response.objResponse;
    setData(res);
    let total = 0;
    let mora = 0;
    let s1y30 = 0;
    let s31y60 = 0;
    let s61y120 = 0;
    let s121y360 = 0;
    let s361 = 0;
    for (let i = 0; i < res.length; i++) {
      total += res[i].saldo;
      mora += res[i].mora;
      if (res[i].mora <= 30) {
        s1y30 += res[i].saldo;
      } else if (res[i].mora <= 60) {
        s31y60 += res[i].saldo;
      } else if (res[i].mora <= 120) {
        s61y120 += res[i].saldo;
      } else if (res[i].mora <= 360) {
        s121y360 += res[i].saldo;
      } else {
        s361 += res[i].saldo;
      }
    }
    setTotal(total);
    setMora(mora);
    setS1y30(s1y30);
    setS31y60(s31y60);
    setS61y120(s61y120);
    setS121y360(s121y360);
    setS361(s361);
  };

  useEffect(() => {
    load();
  }, []);

  /*const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon]);*/

  const cols = 13;

  return (
    <Fragment>
      <PageHeader
        title="Informe de cartera por edades"
        className="mb-3"
      />

      {/*iconList.map(i=><div><FontAwesomeIcon icon={i} /> {i.iconName}</div>)*/}

      <ButtonIcon onClick={exportar} icon="file-excel" transform="shrink-3 down-2" color="falcon-default" size="sm">
        Exportar
      </ButtonIcon>

      <table id="tablaExport"
        className="table table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }} colSpan={cols}>INFORME DE CARTERA POR EDADES</th>
          </tr>
          <tr>
            <th style={{ textAlign: "center" }} colSpan={cols}>INVERSIONES OSVIEL S.A.S.</th>
          </tr>
          <tr>
            <th style={{ textAlign: "center" }} colSpan={cols}>Edades de Cartera con cierre al {fecha}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style={{ textAlign: "center" }}>Cliente/Beneficiario</th>
            <th style={{ textAlign: "center" }}>Pagaduria</th>
            <th style={{ textAlign: "center" }}>Línea Cred</th>
            <th style={{ textAlign: "center" }}>Sublinea Cred</th>
            <th style={{ textAlign: "center" }}>Num. Cred.</th>
            <th style={{ textAlign: "center" }}>Asesor Credito</th>
            <th style={{ textAlign: "center" }}>Valor Adeudado</th>
            <th style={{ textAlign: "center" }}>Días Mora</th>
            <th style={{ textAlign: "center" }}>Entre 1 y 30</th>
            <th style={{ textAlign: "center" }}>Entre 31 y 60</th>
            <th style={{ textAlign: "center" }}>Entre 61 y 120</th>
            <th style={{ textAlign: "center" }}>Entre 121 y 360</th>
            <th style={{ textAlign: "center" }}>Más de 360</th>
          </tr>
          {data.map(i => <tr key={i.prestamo}>
            <td>{i.nomcliente}</td>
            <td>{i.nompagaduria}</td>
            <td>{i.nomlinea}</td>
            <td>{i.nomsublinea}</td>
            <td style={{ textAlign: "right" }}>{i.credito}</td>
            <td>{i.nomasesor}</td>
            <td style={{ textAlign: "right" }}>{i.saldo.toLocaleString()}</td>
            <td style={{ textAlign: "right" }}>{i.mora.toLocaleString()}</td>
            <td style={{ textAlign: "right" }}>{i.mora <= 30 ? i.saldo.toLocaleString() : 0}</td>
            <td style={{ textAlign: "right" }}>{i.mora > 30 && i.mora <= 60 ? i.saldo.toLocaleString() : 0}</td>
            <td style={{ textAlign: "right" }}>{i.mora > 60 && i.mora <= 120 ? i.saldo.toLocaleString() : 0}</td>
            <td style={{ textAlign: "right" }}>{i.mora > 120 && i.mora <= 360 ? i.saldo.toLocaleString() : 0}</td>
            <td style={{ textAlign: "right" }}>{i.mora > 360 ? i.saldo.toLocaleString() : 0}</td>
          </tr>)}
          <tr>
            <td colSpan={6} style={{ fontWeight: "bold" }}>TOTAL GENERAL</td>
            <td style={{ textAlign: "right" }}>{total.toLocaleString()}</td>
            <td style={{ textAlign: "right" }}>{mora.toLocaleString()}</td>
            <td style={{ textAlign: "right" }}>{s1y30.toLocaleString()}</td>
            <td style={{ textAlign: "right" }}>{s31y60.toLocaleString()}</td>
            <td style={{ textAlign: "right" }}>{s61y120.toLocaleString()}</td>
            <td style={{ textAlign: "right" }}>{s121y360.toLocaleString()}</td>
            <td style={{ textAlign: "right" }}>{s361.toLocaleString()}</td>
          </tr>
        </tbody>
      </table>

    </Fragment>
  );
}

export default CarteraPage;
