import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';
import Flex from '../common/Flex';

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix")
  .map(icon => Icons[icon]);
library.add(...iconList)

const NavbarVerticalMenuItem = ({ route }) => (
  <Flex align="center">
    {route.icono && (
      <span className="nav-link-icon">
        <FontAwesomeIcon icon={route.icono} />
      </span>
    )}
    <span className="nav-link-text" title={route.nombre}>{route.nombre}</span>
    {!!route.badge && (
      <Badge color={route.badge.color || 'soft-success'} pill className="ml-2">
        {route.badge.text}
      </Badge>
    )}
  </Flex>
);

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icono: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    nombre: PropTypes.string.isRequired
  }).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
