import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const setRecaudo = (data) => {
    if (!data) return;
    const sesionToken = authSesion();
    data.forEach(element => element.sesion = sesionToken);
    return fetch(`${urlApi}/recaudo`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

const setRecaudoLote = (data) => {
    if (!data) return;
    const sesionToken = authSesion();
    data.sesion = sesionToken
    return fetch(`${urlApi}/recaudo/lote`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

const getRecaudosByClientRange = (idClinet, startDate, endDate) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/recaudo/cli/${sesionToken}/${idClinet}/${startDate}/${endDate}`, {
        method: 'GET',
        headers,
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

const cancelRecaudo = (data) => {
    if (!data) return;
    const sesionToken = authSesion();
    data.sesion = sesionToken
    return fetch(`${urlApi}/recaudo/cre`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

const getLotes = ({ idPag, startDate, endDate }) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/recaudo/lote/${sesionToken}/${idPag}/${startDate}/${endDate}`, {
        method: 'GET',
        headers,
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

const getRecaudosByLote = ({ idLote }) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/recaudo/lote/${sesionToken}/${idLote}`, {
        method: 'GET',
        headers,
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

const anularLote = (data) => {
    if (!data) return;
    const sesionToken = authSesion();
    data.sesion = sesionToken
    return fetch(`${urlApi}/recaudo/lote/cre`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

export const recaudoServices = {
    setRecaudo,
    setRecaudoLote,
    getRecaudosByClientRange,
    cancelRecaudo,
    getRecaudosByLote,
    getLotes,
    anularLote,
}