import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const getData = (table) => {
    //const sesionToken = authSesion();
    return fetch(`${urlApi}/params/data/${table}`, {
        method: 'GET',
        headers,
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

const delParam = (table, id) => {
    //const sesionToken = authSesion();
    return fetch(`${urlApi}/params/delete/${table}/${id}`, {
        method: 'DELETE',
        headers,
    })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
            return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
        })
        .catch(err => {
            console.log("Error", err);
            return { error: true, msg: "ERROR" }
        });
}

export const paramsServices = {
    getData, delParam
}