import cogoToast from 'cogo-toast';
import React, { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import { desembolsoServices } from '../../../services';
import { DesembolsoContext } from '../DesembolsoContext';

const ObservationsSection = () => {
  const { register, setValue, getValues, errors } = useForm({
    defaultValues: {
      chequeValue: 0,
    }
  });
  const context = useContext(DesembolsoContext);
  const { selected, loading, handleBack, onSubmit, handleObservationData, handleOk } = context;

  const onChange = async e => {
    let { name, value } = e.target;
    setValue(name, value);
    onSetData();
  }

  const onSetData = () => {
    const formData = getValues();
    handleObservationData(formData);
  }

  const anular = () => {
    confirmAlert({
      title: 'Confirmación requerida',
      message: '¿Confirma que desea cancelar el desembolso?',
      buttons: [
        {
          label: 'Sí',
          onClick: async () => {
            const response = await desembolsoServices.anular(selected);
            if (!response || response.error || !response.objResponse) {
              cogoToast.error(response.msg || "No se logro completar la operación de anular",
                { position: 'bottom-left', heading: 'Error' });
              return;
            }

            cogoToast.info("Se anuló el desembolso satisfactoriamente", { position: 'bottom-right' });
            handleOk();
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  return (
    <Card className={`my-3`} >
      <CardBody className="bg-light border-top px-4 card-no-header">
        {selected ? (
          <form onChange={onChange}>
            <Row>
              <Col lg={12}>
                <DetailRow title="Observaciones">
                  <FormGroup>
                    <Input
                      type="textarea"
                      id="observations"
                      invalid={errors['observations']}
                      maxLength={500}
                      name="observations"
                      tabIndex="13"
                      {...register("observations", {
                        required: false,
                      })}
                    />
                  </FormGroup>
                </DetailRow>
              </Col>
            </Row>
            <Row className="mt-4">
              {/*<Col className="px-1">
                <Button onClick={handleBack} className="w-100 px-1" transform="shrink-3 down-2" color="secondary">
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : "Cancelar"}
                </Button>
              </Col>*/}
              <Col className="px-1">
                <Button onClick={anular} className="ml-2" transform="shrink-3 down-2" color="danger" >
                  Cancelar
                </Button>
              </Col>
              <Col className="px-1">
                <Button onClick={onSubmit} className="w-100 px-1" transform="shrink-3 down-2" color="primary" >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : "Desembolsar"}
                </Button>
              </Col>
            </Row>
          </form>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default ObservationsSection;
