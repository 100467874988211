import React, { useState, useEffect, useContext } from 'react';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../common/PageHeader';
import { Modal, ModalHeader } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import SingleDetailsCard from './personDetails/SingleDetailsCard';
import PersonalInfo from './personDetails/PersonalInfo';
import OwnerInfo from './personDetails/OwnerInfo';
import UbicationInfo from './personDetails/UbicationInfo';
import RepresentativeInfo from './personDetails/RepresentativeInfo';
import PersonForm from './PersonForm';
import { authSesion } from '../../helpers/authSesion';
import { normalizeReqBody, parsePerson } from '../../helpers/normalizers';
import { personsServices } from '../../services';
import cogoToast from 'cogo-toast';
import WizardLayout from '../auth/wizard/WizardLayout';
import ClasificationInfo from './personDetails/ClasificationInfo';
import FinancialInfo from './personDetails/FinancialInfo';
import { EnvironmentContext } from '../../context/Context';
import { nitOsviel } from '../../configuracion/serverConfig';
import { Col } from 'reactstrap';
import DetailRow from './personDetails/DetailRow';


const PersonDetails = ({
  selected,
  handleAction
}) => {
  const environment = useContext(EnvironmentContext);

  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [stepToEdit, setStepToEdit] = useState(1);
  const [dataToEdit, setDataToEdit] = useState();
  const [parsedSelected, setParsedSelected] = useState();
  const [title, setTilte] = useState("Detalle")
  const [description, setDescription] = useState("")
  const [actual, setActual] = useState({})

  useEffect(() => {
    if (!selected) return;
    const parsed = parsePerson(selected);
    setParsedSelected(parsed);
    const isCompany = parsed?.SelectTipoDocumento === "NIT";
    const tempTitle = isCompany
      ? `${parsed.RazonSocial}`
      : `${parsed.PrimerNombre} ${parsed.PrimerApellido} ${parsed.SegundoApellido}`;
    const tempDescription = `${parsed.SelectTipoDocumento}. ${parsed.NumeroIdentiicacion}`
    setTilte(tempTitle);
    setDescription(tempDescription)
  }, [selected])

  const handleEditAction = (section, step, values) => {
    setStepToEdit(step);
    setModalTitle(section);
    setEditing(true);
    if (values) {
      values.editado = {};
      setActual(values);
    }
  }

  const submitEdit = async formData => {
    const sesionToken = authSesion();
    const reqBody = normalizeReqBody(formData, sesionToken);
    const roles = reqBody.terceroRol.map(item => item.idRol);
    reqBody.revisor = selected.revisor;
    reqBody.contador = selected.contador;
    const resp = await personsServices.edit(reqBody);

    if (resp.error) {
      cogoToast.error(resp.msg || resp.error.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
    } else {
      setEditing(false)
      handleAction("Cargar")
      if (roles.includes(2)) {
        environment.loadPagadurias();
      }
      cogoToast.success('Edición exitosa!', {
        position: 'bottom-right',
        heading: 'Actualizado'
      });
    }
  };

  return (
    <div>
      {editing && (
        <Modal isOpen={editing} size="xl" toggle={() => setEditing(false)}>
          <ModalHeader toggle={() => setEditing(false)} >
            <div className="row pt-3 ">
              <div className="flex col-md-12">
                <h3>Editar {modalTitle}</h3>
              </div>
            </div>
          </ModalHeader>
          <WizardLayout>
            <PersonForm
              editing={true}
              editingStep={stepToEdit}
              selected={parsedSelected}
              extraInfo={selected}
              handleSubmitForm={submitEdit}
              actual={actual}
            />
          </WizardLayout>
        </Modal>
      )}
      <PageHeader
        title={title}
        description={description}
        className="mb-3"
      >
        <ButtonIcon className="mt-3" onClick={() => handleAction("Cancelar")} icon={faArrowLeft} transform="shrink-3 down-2" color="falcon-default" size="sm">
          Atras
        </ButtonIcon>
      </PageHeader>
      {selected && (
        <>
          {/* PERSONAL INFO */}
          <SingleDetailsCard
            step={1}
            sectionKey="terceroInformacion"
            title="Identificación"
            data={selected.terceroInformacion}
            handleEditAction={handleEditAction}
          >
            <PersonalInfo data={selected.terceroInformacion} natural={selected.terceroInformacion.tipoIdentificacion !== "NIT"} />
          </SingleDetailsCard>

          {/* ROLES */}
          <SingleDetailsCard
            step={2}
            sectionKey="terceroRol"
            title="Roles"
            data={selected.terceroRol}
            handleEditAction={handleEditAction}
          >
            <OwnerInfo data={selected.terceroRol} />
          </SingleDetailsCard>

          {/* LOCATION */}
          <SingleDetailsCard
            step={3}
            sectionKey="terceroUbicacion"
            title="Ubicación"
            data={selected.terceroUbicacion}
            handleEditAction={handleEditAction}
          >
            <UbicationInfo data={selected.terceroUbicacion} />
          </SingleDetailsCard>

          {/* CLASIFICATION */}
          <SingleDetailsCard
            step={4}
            sectionKey="terceroClasificacion"
            title="Clasificación"
            data={selected.terceroClasificacion}
            handleEditAction={handleEditAction}
          >
            <ClasificationInfo data={selected.terceroClasificacion} selected={selected} />
          </SingleDetailsCard>

          {/* REPRESENTATION */}
          {selected.terceroInformacion.tipoIdentificacion === "NIT" && (
            <SingleDetailsCard
              step={5}
              sectionKey="terceroRepresentacion"
              title="Representación"
              data={selected.terceroRepresentacion}
              handleEditAction={handleEditAction}
            >
              <RepresentativeInfo data={selected.terceroRepresentacion} />
            </SingleDetailsCard>
          )}

          {/* FINANCIAL */}
          <SingleDetailsCard
            step={6}
            sectionKey="terceroFinanciera"
            title="Financiera"
            data={selected.terceroFinanciera}
            handleEditAction={handleEditAction}
          >
            <FinancialInfo data={selected.terceroFinanciera} />
          </SingleDetailsCard>

          {/* CONTADOR */}
          {selected.terceroInformacion.tipoIdentificacion === "NIT" && selected.terceroInformacion.identificacion == nitOsviel && (
            <SingleDetailsCard
              step={8}
              sectionKey="contador"
              title="Contador"
              data={selected.contador}
              handleEditAction={handleEditAction}
            >
              <Col lg className="col-xxl-5">
                <DetailRow title="Tipo de Identificación">{selected.contador.tipoIdentificacion}</DetailRow>
                <DetailRow title="Nombres">{selected.contador.nombres}</DetailRow>
                <DetailRow title="Tarjeta Profecional">{selected.contador.tarjetaProfesional}</DetailRow>
              </Col>
              <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <DetailRow title="Número de Identificación">{selected.contador.identificacion}</DetailRow>
                <DetailRow title="Apellidos">{selected.contador.apellidos}</DetailRow>
              </Col>
            </SingleDetailsCard>
          )}

          {/* REVISOR FISCAL */}
          {selected.terceroInformacion.tipoIdentificacion === "NIT" && selected.terceroInformacion.identificacion == nitOsviel && (
            <SingleDetailsCard
              step={8}
              sectionKey="revisor"
              title="Revisor Fiscal"
              data={selected.revisor}
              handleEditAction={handleEditAction}
            >
              <Col lg className="col-xxl-5">
                <DetailRow title="Tipo de Identificación">{selected.revisor.tipoIdentificacion}</DetailRow>
                <DetailRow title="Nombres">{selected.revisor.nombres}</DetailRow>
                <DetailRow title="Tarjeta Profecional">{selected.revisor.tarjetaProfesional}</DetailRow>
              </Col>
              <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <DetailRow title="Número de Identificación">{selected.revisor.identificacion}</DetailRow>
                <DetailRow title="Apellidos">{selected.revisor.apellidos}</DetailRow>
              </Col>
            </SingleDetailsCard>
          )}

        </>
      )}
    </div>
  )
}

export default PersonDetails;
