import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cogoToast from 'cogo-toast';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import MainTable from '../../components/tables/MainTable';
import { MODAL_MODE, MODAL_VIEW } from '../../data/constans';
import { currencyFormat } from '../../helpers/tableFormatters';
import { creditServices } from '../../services';
import ModalSearchClient from '../solicitud/ModalSearchClient';


function ThirdCreditSelectModal({ viewMode, mode, title, idsDefault, open, collapseOneOpen, onSubmit }) {
  const [creditList, setCreditList] = useState([]);
  const [selectedCredits, setSelectedCredits] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedClient, setSelectedClient] = useState();
  const [idClient, setIdClient] = useState();

  const [showSelectClientModal, setShowSelectClientModal] = useState();

  const columns = [
    {
      dataField: 'prestamo',
      text: '#',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'credito',
      text: 'Nro. Crédito',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'saldopro',
      headerClasses: 'border-0 text-right',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    }
  ]

  useEffect(() => {
    if (!idClient) return;
    loadCredits(idClient);
  }, [idClient])

  useEffect(() => {
    let tempTotal = 0;
    if (!selectedCredits) return;
    selectedCredits.forEach(credit => {
      tempTotal += credit.saldopro;
    })

    setTotal(tempTotal);
  }, [selectedCredits])

  const loadCredits = async (id) => {
    const response = await creditServices.getCreditsByClient(id);
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar la información' });
      return;
    }

    setCreditList(response.objResponse);
  }

  const handleSelect = (data, isSelect) => {
    if ([MODAL_VIEW.READONLY].includes(viewMode)) return;
    let tempSelecetdCredits;
    if (isSelect) {
      tempSelecetdCredits = [...selectedCredits];
      tempSelecetdCredits.push(data);
    } else {
      tempSelecetdCredits = selectedCredits.filter(credit => credit.prestamo != data.prestamo);
    }

    if ([MODAL_MODE.SINGLE].includes(mode)) {
      handleSubmit({ total: data.saldopro, data })
    } else {
      setSelectedCredits(tempSelecetdCredits)
    }
  }

  const handleSubmit = (value) => {
    onSubmit(value || { total, data: selectedCredits });
    collapseOneOpen(!open)
  }

  const handleSearchClient = () => {
    setShowSelectClientModal(true)
  }

  const handleClientSelected = (client) => {
    setIdClient(client.id);
    setSelectedClient(client);
    setShowSelectClientModal(false)
  }

  return (
    <Modal size="lg" isOpen={open} toggle={() => collapseOneOpen(!open)}>
      {showSelectClientModal && (
        <ModalSearchClient
          show={showSelectClientModal}
          toggle={() => setShowSelectClientModal(false)}
          onSelect={handleClientSelected}
        />
      )}
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => collapseOneOpen(!open)}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
        <h5>{title}</h5>
      </ModalHeader>
      <ModalBody className="p-0 px-3">
        <div className='mt-2'>
          <a className="font-weight-semi-bold fs--1 text-nowrap cursor-pointer" onClick={handleSearchClient}>
            Buscar Tercero
            <FontAwesomeIcon icon="user" transform="down-1.5" className="ml-1" />
          </a>
          <h5>{selectedClient ? `${selectedClient.terceroInformacion.nombre1} ${selectedClient.terceroInformacion.apellido1} ${selectedClient.terceroInformacion.apellido2}` : ''}</h5>
        </div>
        <MainTable
          data={creditList}
          columns={columns}
          keyField="prestamo"
          pageSize={12}
          handleSelect={{
            mode: [MODAL_MODE.SINGLE].includes(mode) ? 'radio' : 'checkbox',
            clickToSelect: true,
            selected: [MODAL_VIEW.READONLY].includes(viewMode) ? idsDefault : null,
            onSelect: handleSelect,
            hideSelectAll: true
          }}
          className='mb-0 shadow-none'
          rowClasses="main-table-custom-row"
          noSearch
          noAdd
        />
      </ModalBody>
      {[MODAL_MODE.MULTIPLE].includes(mode) && (
        <ModalFooter className="no-border">
          <Col className="text-right" >
            <Button onClick={() => collapseOneOpen(!open)} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
              Cancelar
            </Button>
            <Button onClick={() => handleSubmit()} transform="shrink-3 down-2" color="primary" >
              Guardar
            </Button>
          </Col>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ThirdCreditSelectModal
