import React, { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { PreliquidacionContext } from '../PreliquidacionContext';

const ObservationsForm = () => {
  const { liquidacion, hdlPreliquidar, handleAnotationForm, selected } = useContext(PreliquidacionContext);
  const { register, triggerValidation, errors, setValue, getValues } = useForm();

  const onChange = async e => {
    const { name, value } = e.target;
    setValue(name, value);
    const formData = getValues();
    await triggerValidation();
    const isValid = Object.keys(errors).length < 1;
    handleAnotationForm(isValid ? formData : null);
  }

  const anular = () => {
    confirmAlert({
      title: 'Confirmación requerida',
      message: '¿Confirma que desea anular?',
      buttons: [
        {
          label: 'Sí',
          onClick: () => hdlPreliquidar(5)
        },
        {
          label: 'No'
        }
      ]
    });
  }

  return (
    <Card className={`my-3`} >
      <CardBody className="bg-light border-top">
        <form onChange={onChange}>
          <Row>
            <Col lg={12} xs={12} >
              <FormGroup>
                <Label for="observations">
                  Anotaciones
                </Label>
                <Input
                  type="textarea"
                  id="observations"
                  invalid={errors['observations']}
                  maxLength={300}
                  name="observations"
                  defaultValue={selected?.anotacion}
                  {...register("observations", {
                    required: false,
                  })}
                />
              </FormGroup>
            </Col>
          </Row>
        </form>
        <Col lg={12} xs={12} className="text-right p-0" >
          {liquidacion && (
            <>
              <Button onClick={() => hdlPreliquidar(4)} className="ml-2" transform="shrink-3 down-2" color="secondary">
                Corregir
              </Button>
              <Button onClick={anular} className="ml-2" transform="shrink-3 down-2" color="danger" >
                Anular
              </Button>
            </>
          )}
          <Button onClick={() => hdlPreliquidar()} className="ml-2" transform="shrink-3 down-2" color="primary" >
            {liquidacion ? "Liquidar" : "Preliquidar"}
          </Button>
        </Col>
      </CardBody>
    </Card>
  )
}

export default ObservationsForm