import cogoToast from 'cogo-toast';
import React, { Fragment, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PageHeader from '../../components/common/PageHeader';
import { formatDate, getTodayStr } from '../../helpers/dateFormatters';
import { refFormat } from '../../helpers/tableFormatters';
import { getItemFromStore } from '../../helpers/utils';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import { solicitudServices } from '../../services';
import { advisersServices } from '../../services/advisers.services';
import ModalSearchClient from './ModalSearchClient';
import SolicitudAside from './SolicitudAside';
import SolicitudContent from './SolicitudContent';
import { SolicitudContext } from './SolicitudContext';
import DeniedCard from './contents/DeniedCard';

const SolicitudPage = () => {
  const [showModal, setShowModal] = useState();
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(true);

  const [currentUser, setCurrentUser] = useState();
  const [client, setClient] = useState();
  const [formCreditData, setFormCreditData] = useState();
  const [formCreditInfo, setFormCreditInfo] = useState();
  const [formRefData, setFormRefData] = useState();
  const [selectedPagaduria, setSelectedPagaduria] = useState();
  const [cutDate, setCutDate] = useState();
  const [date, setDate] = useState(getTodayStr());

  const [creditLinesOptions, setCreditLinesOptions] = useState([]);
  const [creditSublinesOptions, setCreditSublinesOptions] = useState([]);

  const [reload, setReload] = useState(false);

  const [selectedLine, setSelectedLine] = useState();
  const [disabledCutDate, setDisabledCutDate] = useState();
  const [lastDay, setLastDay] = useState();


  useEffect(() => {
    getCurrentUser();
    getCreditLines();
  }, [])

  useEffect(() => {
    if (!selectedLine) return;

    if (selectedLine == 1) {
      setDisabledCutDate(true);
      handleLibranzaCredits();
    } else {
      setLastDay(null);
      setDisabledCutDate(false)
    }
  }, [selectedLine, date])

  useEffect(() => {
    if (!reload) return;
    setReload(false);
  }, [reload])

  useEffect(() => {
    if (!window.simulacion) {
      return;
    }
    const { date } = window.simulacion;
    if (date) {
      setDate(date);
    }
  }, [window.simulacion])

  const handleLibranzaCredits = () => {
    if (!date) return;

    const tempDate = date.replaceAll("-", "/")

    const selectedDate = new Date(tempDate);
    const day = selectedDate.getDate();
    const month = selectedDate.getMonth();
    const year = selectedDate.getFullYear();

    const templastDay = day > 10
      ? new Date(year, month + 2, 0)
      : new Date(year, month + 1, 0)

    setLastDay(`${formatDate(templastDay)}#${uuidv4()}`);
  }

  const getCurrentUser = async () => {
    const sesion = await getItemFromStore('Usuario', '', sessionStorage);
    const { identificacion } = sesion;

    const response = await advisersServices.getAdviserById(identificacion);
    if (!response || response.error) {
      setLoading(false);
      setRequesting(false);
      return;
    }
    setCurrentUser(response.objResponse[0]);
    setLoading(false);
    setRequesting(false);
  }

  const getCreditLines = async (id) => {
    const response = await solicitudServices.getCreditLines(id);
    if (!response || response.error) {
      return;
    }
    setCreditLinesOptions(response.objResponse);
  }

  const loadCreditSublines = async (creditLineId) => {
    const response = await solicitudServices.getCreditSublines(creditLineId);
    if (!response || response.error) {
      return;
    }
    setCreditSublinesOptions(response.objResponse);
  }


  const onSelectClient = (value) => {
    setClient(value);
    setShowModal(false);
  }

  const onOpenModal = () => {
    setShowModal(true);
  }

  const handleCreditInfoForm = data => {
    setFormCreditInfo(data);
  }

  const handleCreditForm = (values) => {
    setFormCreditData(values);
  }

  const handleCutDate = (date) => {
    setCutDate(date);
  }

  const handleFormDate = (date) => {
    setDate(date);
  }

  const onSelectPagaduria = value => {
    setSelectedPagaduria(value);
  }

  const handleFormRef = data => {
    setFormRefData(data);
  }

  const onChangeLine = (value) => {
    setSelectedLine(value);
  }

  const onSave = async () => {
    if (
      !currentUser ||
      !client ||
      !formCreditInfo ||
      !formCreditData ||
      !selectedPagaduria ||
      !formRefData ||
      !cutDate
    ) {
      cogoToast.error("Por favor completa toda la información requerida",
        { position: 'bottom-left', heading: 'Campos requeridos faltantes.' });
      return;
    }
    setLoading(true);
    const today = getTodayStr();

    const payload = {
      solicitud: 0,
      credito: formCreditInfo.creditNumber,
      fecha: date || today,
      idPagaduria: selectedPagaduria,
      idLinea: formCreditInfo.creditLine,
      idSubLinea: formCreditInfo.creditSubline,
      idCliente: client.id,
      idAsesor: currentUser.idAsesor,
      comision: 0,
      monto: Number(formCreditData.amount || 0),
      plazo: formCreditData.term,
      tasa: formCreditData.rate,
      tipoFormula: formCreditData.type,
      tasaPrima: formCreditData.bonus,
      fechaCorte: cutDate,
      valorCuota: formCreditData.cuota,
      estado: 1,
      idModalidad: 0,
      refSolicitud: {
        identificacion: formRefData.identificacion,
        nombre: formRefData.fullName,
        domicilio: formRefData.address,
        telefono: formRefData.phone,
        correo: formRefData.email || "",
      }
    }

    const response = await solicitudServices.createSolicitud(payload);
    if (!response || response.error) {
      // resetData();
      setLoading(false);
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error en solicitud' });
      return;
    };
    resetData();
    setLoading(false);
    cogoToast.success("Su solicitud " + refFormat(response.objResponse.solicitud) + " ha sido creada con éxito",
      { position: 'bottom-left', heading: 'Solicitud Creada' });
  }

  const resetData = () => {
    setClient(null);
    setFormCreditData(null);
    setFormCreditInfo(null);
    setFormRefData(null);
    setSelectedPagaduria(null);
    setCutDate(null);

    setReload(true);
  }

  return (
    <SolicitudContext.Provider value={{
      loading,
      formCreditInfo,
      adviser: currentUser,
      creditLinesOptions,
      creditSublinesOptions,
      client,
      date,
      lastDay,
      disabledCutDate,
      onOpenModal,
      onSelectClient,
      handleCreditForm,
      handleCutDate,
      handleCreditInfoForm,
      handleFormRef,
      handleFormDate,
      onSelectPagaduria,
      onSave,
      loadCreditSublines,
      onChangeLine,
    }}>
      {reload ? (
        <></>
      ) : (
        <Fragment>
          <ModalSearchClient show={showModal} toggle={() => setShowModal(false)} />
          <PageHeader title="Solicitud de Crédito" />
          {
            currentUser ? (
              <ContentWithAsideLayout aside={<SolicitudAside />} isStickyAside={false}>
                <SolicitudContent />
              </ContentWithAsideLayout>
            ) : (
              <>
                {requesting ? <></> : <DeniedCard />}
              </>
            )
          }
        </Fragment>
      )}
    </SolicitudContext.Provider>
  );
};

export default SolicitudPage
