import React, { useState, useContext, useEffect } from 'react';
import {
  Input,
  Button,
  FormGroup,
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import cogoToast from 'cogo-toast';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import CustomInput from 'reactstrap/lib/CustomInput';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { DesembolsoContext } from '../DesembolsoContext';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import { desembolsoServices } from '../../../services';
import { formatterId } from '../../../helpers/formatters';

const InitSwitchSection = () => {
  const { register, setValue, getValues, errors } = useForm({
    defaultValues: {
      chequeValue: 0,
    }
  });
  const context = useContext(DesembolsoContext);
  const { selected, loading, handleToggleInit, isInit} = context;

  const [ toggleInit, setToggleInit ] = useState(false);

  const onChange = async e => {
    setToggleInit(!toggleInit);
    handleToggleInit(!toggleInit);
  }

  return (
    <Card className={`my-3`} >
        {false && selected && selected.idModalidad != 5 ? (
      <CardBody className="bg-light border-top px-4 card-no-header">
          <form >
            <Row>
              <Col lg={12} >
                <div className="p-0 pb-0" >
                  <FormGroup className="text-center m-0 d-flex align-items-center justify-content-center">
                  <input 
                    type='checkbox'
                    id="initialize"
                    name="initialize"
                    tabIndex="14"
                    checked={isInit}
                    onChange={onChange}
                  />
                  <label for="initialize" className='m-0 ml-3'>Pago por Inicialización</label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </form>
      </CardBody>
        ) : <></>}
        </Card>
  );
};

export default InitSwitchSection;
