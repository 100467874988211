import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { DesembolsoContext } from '../DesembolsoContext';
import { idFormat } from '../../../helpers/tableFormatters';

const ClientInfo = () => {
  const context = useContext(DesembolsoContext);
  const { loading, selected } = context;

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Datos Generales del Cliente"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {selected ? (
        <Row>
          <Col lg={12} xs={12}>
            <DetailRow title="Pagaduría">{selected.nomPagaduria || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg={6}>
            <DetailRow title="Identificación">
              {selected.tipoIdCliente} {idFormat(selected.idCliente)}
            </DetailRow>
            <DetailRow title="Teléfono">{selected.telefono || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg={6}>
            <DetailRow title="Razon Social o Nombre del Cliente" childrenClasses="text-uppercase">{selected.nomCliente || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Correo" childrenClasses="text-uppercase">{selected.correo || <span className='p-2'></span>}</DetailRow>
          </Col>
        </Row>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default ClientInfo;
