import "flatpickr/dist/themes/airbnb.css";
import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import Flatpickr from 'react-flatpickr';
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import {
  FormGroup,
  Label
} from 'reactstrap';
import ButtonIcon from '../../components/common/ButtonIcon';
import RequiredMarked from '../../components/common/RequiredMarked';
import CardSummary from '../../components/dashboard/CardSummary';
import { getLocaleCalendarConfig } from '../../helpers/utils';

function SimulatorDisplay({
  fee,
  handleCalculatePlan,
  handleClickPlan,
  vertical,
  requiredMark,
  planDate,
  minDate = false,
  disabledDate = false,
  lastDay = false,
  handleSolicitar
}) {
  const { register, handleSubmit, setValue } = useForm();
  const today = new Date().toISOString().split("T")[0].replaceAll("-", "/");

  useEffect(() => {
    if (!minDate) return;
    if (!disabledDate) onSubmitDate(minDate);
  }, [minDate, disabledDate])

  useEffect(() => {
    if (!lastDay || !disabledDate) return;
    const temp = lastDay.split("#")[0]
    onSubmitDate(temp);
  }, [lastDay])

  const onSubmitDate = date => {
    handleCalculatePlan(date)
  }

  return (
    <div className={`${!vertical ? 'card-deck' : ''}`}>
      <div className={`${!vertical ? 'card mb-3 w-100 p-4' : 'mb-3'}`}>
        <form>
          <FormGroup>
            <Label for="first_pay">
              {requiredMark && <RequiredMarked />}
              Fecha Primer Corte
            </Label>
            <Flatpickr
              disabled={disabledDate}
              className="form-control"
              placeholder='aaaa/mm/dd'
              value={planDate}
              name="first_pay"
              id="first_pay"
              {...register('first_pay')}
              options={{
                dateFormat: "Y/m/d",
                minDate: minDate || today,
                locale: getLocaleCalendarConfig(),
              }}
              onChange={(date, dateStr) => {
                if (disabledDate) return;
                onSubmitDate(dateStr.replaceAll("/", "-"))
              }}
            />
          </FormGroup>
        </form>
      </div>
      <CardSummary useBtn btnAction={(event) => handleClickPlan(true, event)} content={fee || "0"} title="Cuota Mensual" color="info" linkText="Ver Plan de Pagos" rate=""
        footer={vertical ? <></> : <span style={{ marginLeft: "5%" }}>
          <NavLink to="/" onClick={handleSolicitar}>
            <ButtonIcon
              color="primary"
              className="ml-auto px-5"
              type="button"
              iconAlign="right"
              transform="down-1 shrink-4"
            >Iniciar solicitud de crédito</ButtonIcon>
          </NavLink></span>}>
        {<CountUp end={fee ? fee : 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />}
      </CardSummary>
    </div>
  )
}

export default SimulatorDisplay
