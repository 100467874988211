import cogoToast from 'cogo-toast';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr from 'react-flatpickr';
import {
  Col,
  CustomInput,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import RequiredMarked from '../../components/common/RequiredMarked';
import { getLocaleCalendarConfig } from '../../helpers/utils';
import { solicitudServices } from '../../services';

function SimulatorFormDemo(
  {
    date,
    handleFormError,
    handleCalculate,
    bonus,
    vertical,
    requiredMark,
    defaultData,
    invisibleFields = {},
    handleFormDate,
    handleSelectedLine,
  }) {
  const [type, setType] = useState("1");
  const [amount, setAmount] = useState("");
  const [rate, setRate] = useState("");
  const [term, setTerm] = useState("");
  const [bonusPercent, setBonusPercent] = useState("");
  const [creditLinesOptions, setCreditLinesOptions] = useState([]);

  const today = new Date();

  useEffect(() => {
    getCreditLines();
  }, []);

  useEffect(() => {
    if (!defaultData) {
      if (window.simulacion) {
        defaultData = { ...window.simulacion };
        //window.simulacion = null;
      } else {
        return;
      }
    }
    const { amount, rate, term, bonusPercent, type, bonus, date } = defaultData;
    if (amount) setAmount(amount);
    if (rate) setRate(rate);
    if (term) setTerm(term);
    if (bonusPercent || bonus) setBonusPercent(bonusPercent || bonus);
    if (type) setType(type);
    if (date) handleFormDate(date.replaceAll("/", "-"));
  }, vertical ? [defaultData, window.simulacion] : [defaultData]);

  useEffect(() => {
    const formData = {
      amount,
      rate,
      term,
      type,
      bonus: bonusPercent,
    };

    if (!amount || !term || !bonusPercent || !type) {
      handleFormError();
      return;
    }

    if (!formData.rate) formData.rate = "0.00";

    formData.amount = formData.amount.replaceAll(",", ".")
    formData.rate = formData.rate.replaceAll(",", ".")
    formData.term = formData.term.replaceAll(",", ".")
    formData.bonus = formData.bonus.replaceAll(",", ".")

    handleCalculate(formData)

  }, [amount, rate, term, bonusPercent, type])

  const getCreditLines = async (id) => {
    const response = await solicitudServices.getCreditLines(id);
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Lineas de crédito' });
      return;
    }
    setCreditLinesOptions(response.objResponse);
  }

  return (
    <form>
      <div className={`${vertical ? '' : 'col'}`}>
        <Row>
          {!invisibleFields.date && (
            <Col lg={vertical ? 12 : 6} xs={12} >
              <FormGroup>
                <Label for="date">
                  {requiredMark && <RequiredMarked />}
                  Fecha Solicitud
                </Label>
                <Flatpickr
                  className="form-control"
                  placeholder='aaaa/mm/dd'
                  value={date}
                  options={{
                    dateFormat: "Y/m/d",
                    maxDate: today,
                    locale: getLocaleCalendarConfig(),
                  }}
                  onChange={(date, dateStr) => {
                    handleFormDate(dateStr.replaceAll("/", "-"))
                  }}
                />
              </FormGroup>
            </Col>
          )}
          {!invisibleFields.creditLine && (
            <Col lg={vertical ? 12 : 6} xs={12} >
              {/*<FormGroup>
              <Label for="creditLine">
                {requiredMark && <RequiredMarked />}
                Línea de Crédito
              </Label>
              <WizardInput
                type="select"
                tag={CustomInput}
                placeholder="Seleccionar"
                id="creditLine"
                name="creditLine"
                options={creditLinesOptions.map(item => (
                  {label: item.nombre, value: item.id}
                ))}
                onChange={(e) => handleSelectedLine(e.target.value)}
                errors={[]}
              />
            </FormGroup>*/}
            </Col>
          )}
          {!invisibleFields.amount && (
            <Col lg={vertical ? 12 : 6} xs={12} >
              <FormGroup>
                <Label for="amount">
                  {requiredMark && <RequiredMarked />}
                  Monto Solicitado
                </Label>
                <CurrencyInput
                  className="form-control"
                  style={vertical ? {
                    fontSize: '1.6em',
                    textAlign: 'right',
                  } : {}}
                  allowNegativeValue={false}
                  value={amount}
                  name="amount"
                  id="amount"
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => setAmount(v)}
                  decimalScale={2}
                  maxLength={20}
                  placeholder="$ 0.00" />
              </FormGroup>
            </Col>
          )}
          {!invisibleFields.rate && (
            <Col lg={vertical ? 12 : 6} xs={12}>
              <FormGroup>
                <Label for="rate">
                  Tasa de Interés
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  value={rate}
                  name="rate"
                  id="rate"
                  suffix='%'
                  onValueChange={(v) => setRate(v)}
                  decimalScale={2}
                  maxLength={5}
                  placeholder="0.00%" />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          {!invisibleFields.term && (
            <Col lg={vertical ? 12 : 6} xs={12}>
              <FormGroup>
                <Label for="term">
                  {requiredMark && <RequiredMarked />}
                  Plazo en Meses
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  allowDecimals={false}
                  value={term}
                  name="term"
                  id="term"
                  onValueChange={(v) => setTerm(v)}
                  decimalsLimit={0}
                  maxLength={3}
                  placeholder="0" />
              </FormGroup>
            </Col>
          )}
          {!invisibleFields.type && (
            <Col lg={vertical ? 12 : 6} xs={12}>
              <FormGroup>
                <Label for="type">
                  {requiredMark && <RequiredMarked />}
                  Tipo de Fórmula
                </Label>
                <CustomInput
                  type="select"
                  id="type"
                  name="type"
                  value={type}
                  className="text-uppercase"
                  onChange={e => setType(e.target.value)}
                >
                  <option value="1" key={uuidv4()}>
                    Amortizable
                  </option>
                  <option value="2" key={uuidv4()}>
                    Fija
                  </option>
                </CustomInput>
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row className="align-items-end">
          {!invisibleFields.bonusPercent && (
            <Col lg={vertical ? 12 : 6} xs={12}>
              <FormGroup>
                <Label for="bonus">
                  {requiredMark && <RequiredMarked />}
                  Porcentaje de Prima
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  value={bonusPercent}
                  name="bonus"
                  id="bonus"
                  suffix='%'
                  onValueChange={(v) => setBonusPercent(v)}
                  decimalScale={2}
                  maxLength={5}
                  placeholder="0.00%" />
              </FormGroup>
            </Col>
          )}
          <Col lg={vertical ? 12 : 6} xs={12}>
            <FormGroup>
              <Label>Valor Prima</Label>
              <div className={`display-4 ${vertical ? 'fs-2' : 'fs-4'} mb-2 pl-2 font-weight-normal text-sans-serif`}>
                <CountUp end={bonus ? bonus : 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </form>
  )
}

export default SimulatorFormDemo
