import React, { useState, useEffect, Fragment } from 'react';
import {
  Card,
  Input,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Table,
  CustomInput,
  Form,
  Col,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { urlApi } from '../../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import ButtonIcon from '../../common/ButtonIcon';
import ContactListComponent from '../../auth/TablaFormPropiedades';
import WizardInput from '../../auth/wizard/WizardInput';


const options = ['Cliente', 'Pagaduria', 'Vendedor', 'Proveedor'];

const FormPropiedades = (props) => {
  const [initialData, setInitialData] = useState({
    Codigo: '',
    Procedimiento: ''
  });
  const [ListData, setListData] = useState([]);
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialData);
  const [EstadoUsuario, setEstadoUsuario] = useState(false);
  const [identificacion, setIdentificacion] = useState('');
  const [ListaPropiedades, setListaPropiedades] = useState([]);
  const [DataGrilla, setDataGrilla] = useState([]);
  const [propiedades, setPropiedades] = useState([]);

  useEffect(() => {
    if(props.editing && props.selected){
      setDataGrilla(props.selected.propiedades)
    } else if(props.ArrayPropiedades) {
      setDataGrilla(props.ArrayPropiedades)
    }
  }, []);

  const cambiarselectpropiedades = data2 => {
    if(!data2) {
      setPropiedades(null)
      return;
    }
    const selectedRol = props.roles.filter(r => r.value === Number(data2))[0]
    let datapropiedades = { value: selectedRol.value, label: selectedRol.label };
    setPropiedades(datapropiedades);
  };
  
  const quitarCampo = data => {
    const newData = DataGrilla.filter(d => d.IdPropiedad !== data.IdPropiedad)
    props.agregarcampo(newData);
    setDataGrilla(newData);
  };

  const agregarCampo = () => {
    if (propiedades && propiedades.value) {
      let ObjRegistro = {};
      let EstadoID = false;
      ObjRegistro.Propiedad = propiedades.label;
      ObjRegistro.IdPropiedad = propiedades.value;
      if (DataGrilla.length > 0) {
        for (let i = 0; i < DataGrilla.length; i++) {
          if (DataGrilla[i].IdPropiedad == propiedades.value) {
            DataGrilla[i].Propiedad = propiedades.label;
            EstadoID = true;
            console.log('ya se encuentra el registro');
          }
        }
      } else {
        setDataGrilla([ObjRegistro]);
        props.agregarcampo([ObjRegistro]);
        // limpiar();
      }
      if (EstadoID == false) {
        let ObjRegistro2 = {};
        ObjRegistro2.Propiedad = propiedades.label;
        ObjRegistro2.IdPropiedad = propiedades.value;

        const newData = [...DataGrilla]
        newData.push(ObjRegistro2)
        setDataGrilla(newData);
        props.agregarcampo(newData);
      }
    }
  };

  return (
    <>
      <Fragment>
        <Row form>
          <Col>
            <label>Roles de la Persona*</label>
            <WizardInput
              type="select"
              tag={CustomInput}
              className="superponer2"
              id="propiedades"
              placeholder="Seleccionar"
              name="propiedades"
              onChange={(input) => cambiarselectpropiedades(input.target.value)}
              options={props.roles}
              errors={props.errors}
            />
            <ButtonIcon className="mt-2" onClick={agregarCampo} icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
                Agregar
            </ButtonIcon>
          </Col>

          <Col>
            <Label>Roles Asignados</Label>
            <div style={{ border: "1px solid hsl(0,0%,80%)", borderRadius: '5px' }}>
              <div className="contact-table">
                <ContactListComponent
                  panel={props.panel}
                  searchInput={props.searchInput}
                  contactlists={DataGrilla}
                  headerLista={['', 'Rol', '']}
                  bodyLista={['Propiedad', '']}
                  quitarCampo={data => quitarCampo(data)}
                  Acciones={true}
                  Consultar={data => console.log(data)}
                  selectValue={data => console.log(data)}
                  handleSearch={data => console.log(data)}
                  actiononContact={(action, data) => console.log(action, data)}
                />
              </div>
            </div>
            {props.errors['propiedades'] && (
              <span class="text-danger">
                Por favor seleccione por lo menos un rol.
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col />
        </Row>
      </Fragment>
    </>
  );
}

export default FormPropiedades;
