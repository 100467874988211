import React, { useState, useEffect, Fragment } from 'react';
import PageHeader from '../../components/common/PageHeader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import * as Icons from '@fortawesome/free-solid-svg-icons';
import { urlApi } from '../../configuracion/serverConfig';
import { headers } from "../../helpers/authSesion";
import cogoToast from 'cogo-toast';
import ButtonIcon from '../../components/common/ButtonIcon';
import XLSX from 'sheetjs-style'

const fecha = new Date().toLocaleDateString();

const MovimientoCajaPage = () => {
  const [data, setData] = useState([]);

  const exportar = () => {
    const table = document.getElementById("tablaExport");
    const wb = XLSX.utils.table_to_book(table);
    /* Export to file (start a download) */
    XLSX.writeFile(wb, "movimeinto-caja-" + fecha + ".xlsx");
  };

  const getMovimientos = () => {
    return fetch(`${urlApi}/informe/movimientoCaja`, {
      method: 'GET',
      headers,
    }).then(res => res.json())
      .then(data => data)
      .then(data => {
        return data.status === 0 ? data : { error: true, msg: data.objError.msgError }
      }).catch(err => {
        console.log("Error", err);
        return { error: true, msg: "ERROR" }
      });
  };

  const load = async () => {
    const response = await getMovimientos();
    if (response.error) {
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }
    const res = response.objResponse;
    let total = 0;
    for (let i = 0; i < res.length; i++) {
      const item = res[i];
      total += item.tipo == 1 ? item.valor : -item.valor;
      item.saldo = total;
    }
    setData(res);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Fragment>
      <PageHeader
        title="Informe movimiento diario de caja"
        className="mb-3"
      />

      <ButtonIcon onClick={exportar} icon="file-excel" transform="shrink-3 down-2" color="falcon-default" size="sm">
        Exportar
      </ButtonIcon>

      <table id="tablaExport"
        className="table table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }} colSpan={11}>INFORME MOVIMEINTO DIARIO DE CAJA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style={{ textAlign: "center" }}>TERCERO</th>
            <th style={{ textAlign: "center" }}>FECHA</th>
            <th style={{ textAlign: "center" }}>NOTA</th>
            <th style={{ textAlign: "center" }}>CHEQUE</th>
            <th style={{ textAlign: "center" }}>DOC. #</th>
            <th style={{ textAlign: "center" }}>DÉBITOS</th>
            <th style={{ textAlign: "center" }}>CRÉDITOS</th>
            <th style={{ textAlign: "center" }}>SALDO</th>
          </tr>
          {data.map(i => <tr key={i.prestamo}>
            <td>{i.razonsocial || (i.nombre1 + " " + i.nombre2 + " " + i.apellido1 + " " + i.apellido2)}</td>
            <td style={{ textAlign: "center" }}>{i.fechahora}</td>
            <td style={{ textAlign: "center" }}>{i.nota}</td>
            <td style={{ textAlign: "center" }}>{i.numero_cheque}</td>
            <td style={{ textAlign: "center" }}>{i.doc}</td>
            <td style={{ textAlign: "right" }}>{i.tipo == 1 ? i.valor.toLocaleString() : 0}</td>
            <td style={{ textAlign: "right" }}>{i.tipo == 2 ? i.valor.toLocaleString() : 0}</td>
            <td style={{ textAlign: "right" }}>{i.saldo.toLocaleString()}</td>
          </tr>)}
        </tbody>
      </table>

    </Fragment>
  );
}

export default MovimientoCajaPage;
