import React, { useState, useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label
} from 'reactstrap';
import { faSync} from '@fortawesome/free-solid-svg-icons';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import ButtonIcon from '../../../components/common/ButtonIcon';
import { StateContext } from '../StateContext';
import ModalSearchClient from '../../solicitud/ModalSearchClient';
import { formatterId } from '../../../helpers/formatters';
import Avatar from '../../../components/common/Avatar';
import defaultImg from '../../../assets/img/avatars/default.png'
import ClientMiniCard from './ClientMiniCard';
import { CREDIT_STATE_ID } from '../../../data/constans';


const ClientInfo = () => {
  const context = useContext(StateContext);
  const { client, clientCreditsResume, onClientSelect, handleClean, handleRefresh } = context;
  const [showSearchClient, setShowSearchClient] = useState(false);

  const handleSearchClient = () => {
    setShowSearchClient(!showSearchClient);
  }
  
  return (
    <div className='d-flex'>
    <ModalSearchClient show={showSearchClient} toggle={handleSearchClient} onSelect={(data) => {
      handleSearchClient();
      onClientSelect(data);
    }} />  
    <Card className="my-3 mr-3" style={{flexGrow: 2, maxWidth: '65%', minWidth: '60%'}} >
      <CardBody className="bg-light border-top px-4">
        <>
        <Row className="justify-content-between" style={{flexWrap: 'nowrap !important'}}>
          <Col lg={2} xs={12} className="d-flex flex-column align-items-center">
            {/* <RequiredMarked /> */}
            {client ? (
              <Avatar
                size="4xl" 
                name={`${client.terceroInformacion.nombre1} ${client.terceroInformacion.apellido1}`}
              />
            ) : (
              <Avatar
                src={defaultImg}
                rounded="circle" 
                size="4xl" 
              />
            )}
            <ButtonIcon onClick={handleSearchClient} className="mt-4" color="falcon-default" size="sm">
              Buscar
            </ButtonIcon>
            {/* Por favor <a className='fw-bold pointer' onClick={handleSearchClient}>seleccione</a> un cliente. */}
          </Col>
          <Col lg={4} xs={12} className="d-flex flex-column pt-3">
            <DetailRow title="Identificación">{client && client.terceroInformacion.identificacion 
              ? `${client.terceroInformacion.tipoIdentificacion ? client.terceroInformacion.tipoIdentificacion : ""} ${formatterId(client.terceroInformacion.identificacion)}`  
              : <span className='p-2'></span>}</DetailRow>
            <DetailRow className="flex-nowrap" childrenClasses="text-uppercase" title="Correo">{client && client.terceroUbicacion.correo || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg={5} xs={12} className="d-flex flex-column pt-3">
            <DetailRow childrenClasses="text-uppercase" title="Razon Social o Nombre del Cliente">{
              client 
              ? `${client.terceroInformacion.nombre1} ${client.terceroInformacion.nombre2}
              ${client.terceroInformacion.apellido1} ${client.terceroInformacion.apellido2}`
              : <span className='p-2'></span>
            }</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Telefono">{client && client.terceroUbicacion.telefono1 || <span className='p-2'></span>}</DetailRow>
          </Col>
        </Row>
        <Row className="d-flex w-100 position-relative">
          {client && (
            <div className="position-absolute r-0 b-0">
              <ButtonIcon onClick={handleRefresh} icon={faSync} className="mr-3"  color="falcon-default" size="sm">
                Refrescar
              </ButtonIcon>
              <ButtonIcon onClick={handleClean}  color="falcon-default" size="sm">
                Cancelar
              </ButtonIcon>
            </div>
          )}
        </Row>
        </>
      </CardBody>
    </Card>
    <Row className="mt-3">
      <Col md={6} lg={6} xl={6} className=" mb-3 pr-md-2">
        <ClientMiniCard
          stateId={CREDIT_STATE_ID.ALL}
          title="Todos"
          badge={clientCreditsResume ? clientCreditsResume.cantTodos : 0}
          total={clientCreditsResume ? clientCreditsResume.sumTodos : 0}
          emoji='💰'
        />
      </Col>
      <Col md={6} lg={6} xl={6} className=" mb-3 pl-md-2">
         <ClientMiniCard
          stateId={CREDIT_STATE_ID.ACTIVE}
          title="Activos"
          badge={clientCreditsResume ? clientCreditsResume.cantActivos : 0}
          total={clientCreditsResume ? clientCreditsResume.sumActivos : 0}
          emoji='🧾'
        />
      </Col>
      <Col md={6} lg={6} xl={6} className=" mb-3 pr-md-2">
         <ClientMiniCard
          stateId={CREDIT_STATE_ID.CLOSED}
          title="Cerrados"
          badge={clientCreditsResume ? clientCreditsResume.cantCerrados : 0}
          total={clientCreditsResume ? clientCreditsResume.sumCerrados : 0}
          emoji='📑'
        />
      </Col>
      <Col md={6} lg={6} xl={6} className=" mb-3 pl-md-2">
         <ClientMiniCard
          title="Saldo Créditos"
          badge={clientCreditsResume ? clientCreditsResume.cantSaldos : 0}
          total={clientCreditsResume ? clientCreditsResume.sumSaldos : 0}
          emoji='💳'
        />
      </Col>
    </Row>
    </div>
  );
};

export default ClientInfo;
