import React, { useState, useContext, useEffect } from 'react';
import { Input, Card, CardBody, Row, Col, FormGroup, Label, CustomInput } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import { EnvironmentContext } from '../../../context/Context';
import cogoToast from 'cogo-toast';
import ClientInfo from '../../recaudar/sections/ClientInfo';
import { MovesPageContext } from '../MovesPageContext';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import ButtonIcon from '../../../components/common/ButtonIcon';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { getTodayStr } from '../../../helpers/dateFormatters';
import { generalServices, treasureServices } from '../../../services';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import numToWords from '../../../helpers/numToWord';
import { currencyFormat as currencyNumberFormat, formatterId} from '../../../helpers/formatters';
import ModalSuccess from '../ModalSuccess'; 
import Spinner from 'reactstrap/lib/Spinner';
import ModalPayments from '../ModalPayments';

const MovesCaja = ({ title, egreso }) => {
  const context = useContext(MovesPageContext);
  const environment = useContext(EnvironmentContext)
  const { loading, client, setLoading, handleCleanClient } = context;

  const [conceptosOptions, setConceptosOptions] = useState([]);
  const [treasuriesOptions, setTreasuriesOptions] = useState([]);

  const [selectedTreasure, setSelectedTreasure] = useState();
  const [selectedConcept, setSelectedConcept] = useState();
  const [amount, setAmount] = useState();
  const [observations, setObservations] = useState("");

  const [showSuccess, setShowSuccess] = useState();

  const [invoiceProps, setInvoiceProps] = useState();

  const [showModal, setShowModal] = useState();

  useEffect(() => {
    loadTreasuries();
    loadConcepts();
  }, [])

  const createInvoiceProps  = (payload) => {
    const selectedConcept = conceptosOptions.filter(concept => concept.value == payload.idConcepto)
    const total = Number(amount)
    const montoWord = numToWords(Number(total))

    const tempOwnProps = {
      client: client.terceroInformacion.nombreComercial || client.terceroInformacion.razonSocial || 
      `${client.terceroInformacion.nombre1} ${client.terceroInformacion.nombre2} ${client.terceroInformacion.apellido1} ${client.terceroInformacion.apellido2}`,
      clientTypeId: client.terceroInformacion.tipoIdentificacion,
      clientId: client.terceroInformacion.identificacion ? formatterId(Number(client.terceroInformacion.identificacion)) : "",
      serial: payload.consecutivo,
      ciudad: payload.ciudad,
      amount: currencyNumberFormat(total || 0),
      concept: selectedConcept[0] ? selectedConcept[0].label : "",
      sumWord: montoWord || "",
      checkCash: payload.modalidadPago == 1,
      checkWireTransfer: payload.modalidadPago == 2,
      checkPSE: false,
      checkCheque: payload.modalidadPago == 3,
      date: getTodayStr().split('-'),
      observations: payload.observaciones
    }

    setInvoiceProps(tempOwnProps)
  }

  const loadTreasuries = async () => {
    if(environment.treasuries && environment.treasuries[0]){
      setTreasuriesOptions(environment.treasuries)
      return
    }

    const res = await generalServices.getTreasuries();
    if(res.error || !res.objResponse) return;
    
    const tmpTreasuries = res.objResponse.map(treasure => ({
      value: treasure.tesoreria,
      label: treasure.nombre,
    }))

    setTreasuriesOptions(tmpTreasuries);

  }

  const loadConcepts = async () => {
    const types = egreso ? "T/C" : "T/D"
    const res = await generalServices.getCustomConcepts(types);
    if(res.error || !res.objResponse) return;
    
    const tmpConcepts = res.objResponse.map(concept => ({
      value: concept.conceptotesoreria,
      label: concept.nombre,
    }))

    setConceptosOptions(tmpConcepts);
  }

  const onSelectConcepto = value => {
    setSelectedConcept(value)
  }

  const onSelectTreasure = value => {
    setSelectedTreasure(value)
  }

  const handleSubmit = () => {
    if(!client){
      cogoToast.warn("Por favor seleccione un tercero", { position: 'bottom-left', heading: 'Tercero' });
      return
    }

    if(!amount){
      cogoToast.warn("Por favor seleccione complete los campos", { position: 'bottom-left', heading: 'Campos Requeridos' });
      return
    }
    setShowModal(true);
  }

  const onSubmit = async (dataPayments) => {
    setShowModal(false);
    setLoading(true)

    if(!client){
      cogoToast.warn("Por favor seleccione un tercero", { position: 'bottom-left', heading: 'Tercero' });
      setLoading(false)
      return
    }

    if(!amount){
      cogoToast.warn("Por favor seleccione complete los campos", { position: 'bottom-left', heading: 'Campos Requeridos' });
      setLoading(false)
      return
    }

    const payload = {
      idMovimiento: 0,
      tipo: egreso ? 2 : 1,
      idTercero: client.id,
      fecha: getTodayStr(),
      valor: Number(amount),
      idTesoreria: dataPayments.idTesoreria,
      idConcepto: dataPayments.conceptoTesoreria,
      idCuentaDestino: dataPayments.idCuentaDestino,
      idBancoCheque: dataPayments.idBancoCheque,
      numeroCheque: dataPayments.numeroCheque,
      observaciones: dataPayments.observacion,
      modalidadPago: dataPayments.modalidadPago,
    }

    const res = await treasureServices.setMove(payload);
    if(res.error || !res.objResponse) return;
    
    createInvoiceProps({
      ...payload,
      consecutivo: res.objResponse.consecutivo,
      ciudad: res.objResponse.ciudad,
      modalidadPago: dataPayments.modalidadPago
    })
    
    setShowSuccess(true)
  }

  const onOk = () => {
    setLoading(false);
    setShowSuccess(false);
    setAmount();
    setSelectedConcept();
    setSelectedTreasure();
    setObservations("");
    handleCleanClient();
  }

  const handleCancel = () => {
    setAmount();
    setSelectedConcept();
    setSelectedTreasure();
    setObservations("");
    handleCleanClient();
    handleCleanClient();
  }
      
  return (
    <>
      <ModalPayments 
        open={showModal}
        title={title} 
        textBtn="Guardar"
        toggle={() => setShowModal(false)} 
        data={{total: amount}}
        onSubmit={onSubmit}
        egreso={egreso}
      />
      <ModalSuccess open={showSuccess} data={invoiceProps} onOk={onOk} egreso={egreso} />
      {loading ? (
        <Row className="py-4 d-flex justify-content-center h-100">
          <Spinner color="primary" />
        </Row>
      ) : (
        <>
          <ClientInfo context={MovesPageContext} title="Datos del Tercero" btnText="Buscar Tercero" terceros noCancel/>
          <Card className={`my-3`} >
            <FalconCardHeader title={title}>
              <div className='d-flex'>
                <ButtonIcon onClick={handleCancel} icon={faTimes} transform="shrink-3 down-2" color="falcon-default" size="sm" className="mr-2">
                  Cancelar
                </ButtonIcon>
                <ButtonIcon onClick={handleSubmit} transform="shrink-3 down-2" color="primary" size="sm">
                  Guardar
                </ButtonIcon>
              </div>
            </FalconCardHeader>
            <CardBody className="bg-light border-top px-4">
              <Row>
                <Col>
                  {/* <DetailRow title="Tesorería:" >
                      <FormGroup className="pr-4">
                        <WizardInput
                          type="select"
                          placeholder="Seleccionar"
                          tag={CustomInput}
                          name="treasure"
                          id="treasure"
                          value={selectedTreasure || 0}
                          onChange={({ target }) => {
                            onSelectTreasure(target.value);
                          }}
                          errors={[]}
                          options={treasuriesOptions}
                        />
                      </FormGroup>
                  </DetailRow> */}
                  {/* <DetailRow title="Por Concepto de:" >
                      <FormGroup className="pr-4">
                        <WizardInput
                          type="select"
                          placeholder="Seleccionar"
                          tag={CustomInput}
                          value={selectedConcept || 0}
                          name="concepto"
                          id="concepto"
                          onChange={({ target }) => {
                            onSelectConcepto(target.value);
                          }}
                          errors={[]}
                          options={conceptosOptions}
                        />
                      </FormGroup>
                  </DetailRow> */}
                  {/* <DetailRow title="Observaciones:" >
                      <FormGroup className="pr-4">
                        <Input
                          type="textarea"
                          id="observations"
                          value={observations}
                          maxLength={500}
                          name="observations"
                          onChange={(e) => setObservations(e.target.value)}
                        />
                      </FormGroup>
                  </DetailRow> */}
                  <DetailRow title="Valor del Movimiento:">
                    <FormGroup className="pr-4">
                      <CurrencyInput
                        className="form-control"
                        allowNegativeValue={false}
                        name="amount"
                        id="amount"
                        value={amount || 0}
                        prefix='$'
                        intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                        onValueChange={(v) => {
                          if(v){
                            setAmount(Number(v.replaceAll(',', '.')))
                          }else {
                            setAmount(v)
                          }
                        }}
                        decimalScale={2}
                        maxLength={20}
                        placeholder="$ 0.00" />
                    </FormGroup>
                  </DetailRow>
                </Col>
                <Col lg={4}>
                  <DetailRow title="Fecha Movimiento:" >
                    <FormGroup>
                      <div className="form-control">
                        {getTodayStr() || <span className='p-2'></span>}
                      </div>
                    </FormGroup>
                  </DetailRow>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default MovesCaja