import React, { useContext, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import CustomInput from 'reactstrap/lib/CustomInput';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { EnvironmentContext } from '../../../context/Context';
import { formatterId } from '../../../helpers/formatters';
import { DesembolsoContext } from '../DesembolsoContext';

const ChequeSection = () => {
  const environment = useContext(EnvironmentContext);
  const { register, setValue, getValues, errors } = useForm({
    defaultValues: {
      chequeValue: 0,
    }
  });
  const context = useContext(DesembolsoContext);
  const { loading, selected, handleChequeData } = context;

  const [originAccounts, setOriginAccounts] = useState([]);

  useEffect(() => {
    loadBankAccounts();
  }, [environment])

  useEffect(() => {
    if (!selected) return;
    onSetData()
  }, [selected])


  const loadBankAccounts = async () => {
    if (!environment.bankAccounts) return;
    const tempOriginAccounts = environment.bankAccounts.map(account => ({
      value: account.idCuenta,
      label: `${account.nroCuenta} - ${account.nombreBanco}`
    }))

    setOriginAccounts(tempOriginAccounts);
  }

  const onChange = async (e, noValid) => {
    let { name, value } = e;
    if (!noValid) {
      if (!value) {
        value = '0';
      }
      value = Number(value.replaceAll(",", "."))
    }
    setValue(name, value);
    onSetData();
  }

  const onSetData = () => {
    const formData = getValues();
    const { originAccount, numCheque } = formData;
    if (!originAccount || !numCheque) {
      handleChequeData(null)
      return;
    }
    handleChequeData(formData);
  }

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Cheque"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {selected ? (
          <form>
            <Row>
              <Col lg={4}>
                <DetailRow title="Valor en Cheque">
                  <FormGroup className="pr-4">
                    <CurrencyInput
                      className="form-control pl-2"
                      allowNegativeValue={false}
                      name="chequeValue"
                      id="chequeValue"
                      prefix='$'
                      intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                      {...register('chequeValue')}
                      onValueChange={(value) => onChange({ name: 'chequeValue', value })}
                      decimalScale={2}
                      maxLength={20}
                      placeholder="$ 0.00"
                      tabIndex="10" />
                  </FormGroup>
                </DetailRow>
                <DetailRow childrenClasses="pr-3" title={`${selected.tipoIdCliente} ${formatterId(selected.idCliente)}`}>{selected.nomCliente || <span className='p-2'></span>}</DetailRow>
              </Col>

              <Col lg={8}>
                <DetailRow title="Cuenta y Banco Origen">
                  <FormGroup className="pr-4">
                    <WizardInput
                      className="pl-2"
                      type="select"
                      tag={CustomInput}
                      placeholder="Seleccionar"
                      id="originAccount"
                      name="originAccount"
                      options={originAccounts}
                      onChange={(e) => onChange({ name: 'originAccount', value: e.target.value })}
                      errors={errors}
                      tabIndex="11"
                      innerRef={register({
                        required: true,
                      })}
                    />
                  </FormGroup>
                </DetailRow>
                <DetailRow title="Numero del Cheque">
                  <FormGroup className="pr-4">
                    <Input
                      id="numCheque"
                      name="numCheque"
                      onChange={(e) => onChange({ name: 'numCheque', value: e.target.value }, true)}
                      {...register("numCheque", {
                        required: true,
                      })}
                    />
                  </FormGroup>
                </DetailRow>
              </Col>
            </Row>
          </form>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default ChequeSection;
