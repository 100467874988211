import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
//import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import Footer from '../components/footer/Footer';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
//import loadable from '@loadable/component';
import ProductProvider from '../components/e-commerce/ProductProvider';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import AppContext from '../context/Context';
import { getPageName } from '../helpers/utils';

import useAuth from '../hooks/useAuth';

import EnvironmentProvider from '../EnvironmentProvider';

// Pages
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import HomePage from '../pages/home/HomePage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GestionPersonaEmpresa from '../components/GestionPersonaEmpresa';
import AdvisersPage from '../pages/advisers/AdvisersPage';
import DesembolsoPage from '../pages/desembolso/DesembolsoPage';
import InfoMovesPage from '../pages/info-moves/InfoMovesPage';
import AnuladosPage from '../pages/informes/Anulados';
import CarteraPage from '../pages/informes/Cartera';
import MovimientoCaja from '../pages/informes/MovimientoCaja';
import InitCreditPage from '../pages/init-credit/InitCreditPage';
import Prejuridico from '../pages/juridico/cobro/Prejuridico';
import PrejuridicoProcess from '../pages/juridico/guardados/PrejuridicoProcess';
import JuridicoProcess from '../pages/juridico/liquidados/JuridicoProcess';
import MovesPage from '../pages/moves/MovesPage';
import ParamsPage from '../pages/params/ParamsPage';
import PreliquidacionPage from '../pages/preliquidacion/PreliquidacionPage';
import ProfilesPage from '../pages/profiles/ProfilesPage';
import RecaudarPage from '../pages/recaudar/RecaudarPage';
import RecaudoPage from '../pages/recaudo/RecaudoPage';
import SessionsPage from '../pages/sessions/SessionsPage';
import SimulatorPage from '../pages/simulator/SimulatorPage';
import SolicitudPage from '../pages/solicitud/SolicitudPage';
import StatePage from '../pages/state/StatePage';
import UsersPage from '../pages/users/UsersPage';

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const isKanban = getPageName('kanban');
  const [auth] = useAuth();
  /*var arrayRutas = JSON.parse(sessionStorage.getItem('rutas'));
  let history = useHistory();*/
  const [activeTab, setActiveTab] = useState('_');

  window.setActiveTab = setActiveTab;

  const comps = {
    "/GestionUsuarios": UsersPage,
    '/GestionPerfiles': ProfilesPage,
    '/GestionSesiones': SessionsPage,
    //"/Parametros/": ParamsPage,
    '/GestionPersonaEmpresa': GestionPersonaEmpresa,
    '/GestionAsesores': AdvisersPage,
    "/Simulador": SimulatorPage,
    "/Solicitud": SolicitudPage,
    '/Preliquidacion': PreliquidacionPage,
    '/Desembolso': DesembolsoPage,
    '/Recaudo': RecaudarPage,
    '/EstadoCuenta': StatePage,
    "/ConsultaRecaudo": RecaudoPage,
    "/MovTesoreria": MovesPage,
    "/Prejuridico": Prejuridico,
    "/Proc-Prejuridico": PrejuridicoProcess,
    "/Proc-Juridico": JuridicoProcess,
    "/InicializarCredito": InitCreditPage,
    "/InfMovimientos": InfoMovesPage,
    "/InfCartera": CarteraPage,
    "/InfAnulados": AnuladosPage,
    "/InfMovimientoCaja": MovimientoCaja
  };

  /*useEffect(() => {
    window.scrollTo(0, 0);
    if (arrayRutas) {
      if (!arrayRutas.includes(location.pathname)) {
        history.push('/errors/NoAutorizado');
      }
    }

  }, [location.pathname]);*/

  // useEffect(() => {
  //   DashboardRoutes.preload();
  // }, []);

  if (!auth) return <Redirect to="/authentication/basic/login" />;

  const abrir = (r) => {
    if (r.id) {
      setActiveTab(r.id);
    }
  };

  const cerrar = (i, r) => {
    window.myTabs.splice(i, 1);
    r.id = null;
    setActiveTab(i > 0 ? window.myTabs[i - 1].id : "_");
  };

  return (
    <EnvironmentProvider>
      <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
        {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
        <ProductProvider>
          <div className="content">
            <NavbarTop />

            <Nav tabs className="custom-nav">
              <NavItem className="text-center cursor-pointer" >
                <NavLink onClick={() => setActiveTab("_")} className={`${activeTab === "_" ? 'active' : ''}`}>
                  Inicio
                </NavLink>
              </NavItem>
              {window.myTabs.map((r, i) =>
                <NavItem className="text-center cursor-pointer" key={r.id}>
                  <NavLink onClick={() => abrir(r)} className={`${activeTab === r.id ? 'active' : ''}`}>
                    {r.nombre}
                    {activeTab !== r.id ? <></> : <FontAwesomeIcon
                      onClick={() => cerrar(i, r)}
                      style={{ cursor: 'pointer', marginLeft: '8px' }}
                      size='sm'
                      icon="times"
                    />}
                  </NavLink>
                </NavItem>)}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="_">
                <Route component={HomePage} />
              </TabPane>
              {window.myTabs.map(r =>
                <TabPane tabId={r.id} key={r.id}>
                  {r.formulario.ruta.startsWith("/Parametros/") ?
                    <ParamsPage tabla={r.formulario.ruta} /> :
                    r.formulario.ruta === "/Liquidacion" ?
                      <PreliquidacionPage liquidacion /> :
                      <Route component={comps[r.formulario.ruta]} />}
                </TabPane>)}
            </TabContent>

            {!isKanban && <Footer />}
          </div>
          <SidePanelModal path={location.pathname} />
        </ProductProvider>
      </div>
    </EnvironmentProvider>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
