import React, { useEffect, useContext } from 'react';
import { Button } from 'reactstrap';   
import { useForm } from "react-hook-form";
import { EnvironmentContext } from '../../context/Context';

const AdvisersForm = ({adviser, handleCancel, handleSaveEdit}) => {
  const environment = useContext(EnvironmentContext);
  const { register, setValue, getValues, handleSubmit } = useForm();

  useEffect(() => {
    loadPagadurias();
  }, [])

  useEffect(() => {
    console.log("ENV", environment)
  }, [environment])

  const loadPagadurias = async () => {
    const advisersPagadurias = adviser.asesorPagaduria.map(pag => `${pag.idPagaduria}`);
    if(advisersPagadurias && advisersPagadurias.length){
      setValue("pagadurias", [...advisersPagadurias]);
    }
  }

  const onSubmit = (formData) => {
    if(Array.isArray(formData.pagadurias)){
      const idPagadurias = formData.pagadurias.length > 0 ? formData.pagadurias.map(pag => ({ idPagaduria: pag })) : [];
      
      handleSaveEdit(adviser.idAsesor, idPagadurias)
    } else {
      const idPagadurias = !formData.pagadurias ? [] : [{ idPagaduria: formData.pagadurias}]
      handleSaveEdit(adviser.idAsesor, idPagadurias)
    }
  }

  return (
    <div className='pb-3'>
      <div className="pt-3 pb-3 ">
        <div className="flex col-md-12">
          <label>Nombre del Asesor</label>
          <h5>{adviser.nombre}</h5>
        </div>
      </div>
      <form className='px-3' onSubmit={handleSubmit(onSubmit)}>
        <label>Seleccione las Pagadurías permitidas para el Asesor</label>
        <div>
          {environment && environment.pagadurias && environment.pagadurias.length ? (
            <div className='d-flex flex-column border rounded mb-2'>
            {environment.pagadurias.map((item) => {
              return (
                <label 
                  key={item.identificacion}
                  htmlFor={`pag_${item.identificacion}`}
                  className='d-flex justify-content-between align-items-center p-3 border-bottom m-0'
                >
                  <span>{item.nombreComercial || item.razonSocial}</span>
                  <input
                    id={`pag_${item.identificacion}`}
                    type='checkbox'
                    name="pagadurias"
                    value={item.id}
                    ref={register}
                  />
                </label>
              );
            })}
            </div>
          ) : (
            <div className="text-center no-found-message">Cargando lista...</div>
          )}
        </div>
        <div className='d-flex justify-content-end mt-2'>
          <Button
              color="falcon-default"
              className="px-5 py-2"
              transform="down-1 shrink-4"
              size="sm"
              onClick={handleCancel}
            >
              Cancelar
          </Button>
          <Button
              color="primary"
              className="ml-2 px-5 py-2"
              type="submit"
              transform="down-1 shrink-4"
              size="sm"
            >
              Guardar
          </Button>
        </div>
      </form>
    </div>
  )
}

export default AdvisersForm
