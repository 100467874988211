import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr  from 'react-flatpickr';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import { solicitudServices, advisersServices } from '../../../services';
import { getLocaleCalendarConfig, getItemFromStore, getLastMonthDay } from '../../../helpers/utils';
import { formatDate } from '../../../helpers/dateFormatters';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';
import RequiredMarked from '../../../components/common/RequiredMarked';

const CreditInfoModal = ({ toggle, handleUpdate, open, title, data }) => {
  const { register, triggerValidation, errors, setValue, getValues, replace } = useForm();
  const [creditLinesOptions, setCreditLinesOptions] = useState([]);
  const [creditSublinesOptions, setCreditSublinesOptions] = useState([]);
  const [formData, setFormData] = useState();
  const [currentUser, setCurrentUser] = useState();

  const today = new Date();

  useEffect(() => {
    getCreditLines();
    getCurrentUser();
  }, [])

  useEffect(() => {
    if(!data) return

    const {idLinea, idSubLinea, idCredito, fecha} = data; 
    loadCreditSublines(idLinea);
    const tempFormData = {...formData};
    setFormData({
      ...tempFormData, 
      creditLine: idLinea, 
      creditSubline: idSubLinea,
      creditNumber: idCredito,
      date: fecha
    })
    setValue("creditLine", idLinea);
    setValue("creditSubline", idSubLinea);
    setValue("creditNumber", idCredito);
    setValue("date", fecha);
  }, [data])

  const getCurrentUser = async () => {
    const sesion = await getItemFromStore('Usuario', '', sessionStorage);
    const { identificacion } = sesion;

    const response = await advisersServices.getAdviserById(identificacion);
    if(!response || response.error){
      return;
    }
    setCurrentUser(response.objResponse[0]);
  }

  const getCreditLines = async (id) => {
    const response = await solicitudServices.getCreditLines(id);
    if(!response || response.error){
      return;
    }
    setCreditLinesOptions(response.objResponse);
  }

  const loadCreditSublines = async (creditLineId) => {
    const response = await solicitudServices.getCreditSublines(creditLineId);
    if(!response || response.error){
      return;
    }
    setCreditSublinesOptions(response.objResponse);
  }

  const onChange = async e => {
    const { name, value } = e.target;
    if(value && name === 'creditNumber' && !/^[0-9]+$/.test(value)){
      return;
    }
    if(name === 'creditLine'){
      loadCreditSublines(value);
      setValue('creditSubline', null)
    }
    setValue(name, value);
    const formCreditInfo = getValues();
    await triggerValidation();
    const isValid = Object.keys(errors).length < 1;
    handleCreditInfoForm(formCreditInfo);
  }

  const handleCreditInfoForm = (values) => {
    setFormData(values);
  }

  const onSubmit = async () => {

    if (!currentUser) {
      cogoToast.error("Su usuario no es asesor",
      { position: 'bottom-left', heading: 'Error de configuración' });
      return;
    }

    const { creditNumber, creditLine, creditSubline, date } = formData;
    let newCutDate;

    if(creditNumber == "0") {
        cogoToast.error("El número del crédito no puede ser cero",
      { position: 'bottom-left', heading: 'Valor incorrecto' });
      return;
    }

    if(!creditNumber || 
      !creditLine || 
      !creditSubline || 
      !date) {
        cogoToast.error("Por favor completar los campos requeridos",
      { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
      }

    if(["1", 1].includes(creditLine)){
      newCutDate = formatDate(getLastMonthDay(date));
    } else {
      newCutDate = date;
    }

    const payload = {
      solicitud: data.solicitud,
      credito: formData.creditNumber,
      fecha: formData.date,
      idPagaduria: data.idPagaduria,
      idLinea: formData.creditLine,
      idSubLinea: formData.creditSubline,
      idCliente: data.clienteId,
      idAsesor: currentUser.idAsesor,
      comision: 0,
      monto: data.monto,
      plazo: data.plazo,
      tasa: data.tasa,
      tipoFormula: data.tipoFormula,
      tasaPrima: data.tasaPrima,
      fechaCorte: newCutDate || data.fechaCorte,
      valorCuota: data.valorCuota,
      estado: 1,
      idModalidad: data.idModalidad,
      refSolicitud: {},
    }

    const updates = {
      credito: formData.creditNumber,
      fecha: formData.date,
      idLinea: formData.creditLine,
      idSubLinea: formData.creditSubline,
    }

    const response = await solicitudServices.updateSolicitud(payload);
    if(!response || response.error){
      cogoToast.error(response.msg || "Error al actualizar los datos de la solicitud", 
      { position: 'bottom-left', heading: 'Error' });
      return;
    }

    const { objResponse } = response;
    const newData = objResponse ? Array.isArray(objResponse) ? objResponse[0] : objResponse : null;
    handleUpdate(newData);
    toggle();
  }

  return (
    <Modal size="lg" isOpen={open} toggle={() => toggle(!open)}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => toggle(!open)}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
        >
        {title}
      </ModalHeader>
      <ModalBody>
      <form onChange={onChange}>
        <Row>
        <Col xl={12}>
          <FormGroup>
          <Label for="creditNumber">
            <RequiredMarked />
            Fecha de Solicitud
          </Label>
          <Flatpickr
            className="form-control"
            placeholder='aaaa/mm/dd'
            value={formData && formData.date || ""}
            options={{
              dateFormat: "Y/m/d",
              maxDate: today,
              locale: getLocaleCalendarConfig(),
            }}
            onChange={(date, dateStr) => {
              onChange({target: {name: "date", value:dateStr.replaceAll("/", "-")}})
            }}
            {...register("date", {
              required: true,
            })}
          />
          </FormGroup>
        </Col>
        <Col xl={12}>
          <FormGroup>
          <Label for="creditNumber">
            <RequiredMarked />
            Nro. del Crédito
          </Label>
          <CurrencyInput
            className="form-control"
            allowNegativeValue={false}
            allowDecimals={false}
            disableGroupSeparators={true}
            name="creditNumber"
            value={formData && formData.creditNumber || ""}
            id="creditNumber"
            {...register("creditNumber", {
              required: true,
            })}
            decimalsLimit={0}
            maxLength={20}
            placeholder="0" />
          </FormGroup>
        </Col>
        <Col xl={12}>
          <FormGroup>
            <Label for="creditLine">
              <RequiredMarked />
              Línea de Crédito
            </Label>
            <WizardInput
              type="select"
              tag={CustomInput}
              placeholder="Seleccionar"
              id="creditLine"
              value={formData && formData.creditLine || ""}
              name="creditLine"
              options={creditLinesOptions.map(item => (
                {label: item.nombre, value: item.id}
              ))}
              errors={errors}
              innerRef={register("creditLine", {
                required: true,
              })}
            />
          </FormGroup>
        </Col>

        <Col xl={12}>
          <FormGroup>
          <Label for="creditSubline">
            <RequiredMarked />
            Sublínea de Crédito
          </Label>
          <WizardInput
            type="select"
            tag={CustomInput}
            placeholder="Seleccionar"
            value={formData && formData.creditSubline || ""}
            id="creditSubline"
            name="creditSubline"
            options={creditSublinesOptions.map(item => (
              {label: item.nombre, value: item.id}
            ))}
            errors={errors}
            innerRef={register({
              required: true,
            })}
          />
          </FormGroup>
        </Col>
        </Row>
      </form>
      </ModalBody>
      <ModalFooter className="no-border">
        <Col className="text-right" >
          <Button onClick={() => toggle(!open)} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
            Cancelar
          </Button>
          <Button onClick={onSubmit} transform="shrink-3 down-2" color="primary" >
            Aceptar
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default CreditInfoModal