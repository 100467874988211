import React from 'react';
import GeneralConditionsInfo from './GeneralConditionsInfo';
import WayToPay from './WayToPay';
import ObservationsSection from './ObservationsSection';
import InitSwitchSection from './InitSwitchSection';

const AsideLayout = () => {
  return (
      <>
        <GeneralConditionsInfo />
        <WayToPay />
        <InitSwitchSection />
        <ObservationsSection />
      </>
  )
}

export default AsideLayout