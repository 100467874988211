import React, { useState, useContext, useEffect } from 'react';
import {
  FormGroup,
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import cogoToast from 'cogo-toast';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import CustomInput from 'reactstrap/lib/CustomInput';
import { EnvironmentContext } from '../../../context/Context';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { DesembolsoContext } from '../DesembolsoContext';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import { desembolsoServices } from '../../../services';

const CashSection = () => {
  const environment = useContext(EnvironmentContext);
  const { register, setValue, getValues, errors } = useForm({
    defaultValues: {
      cashValue: 0
    }
  });
  const context = useContext(DesembolsoContext);
  const { loading, selected, handleCashData } = context;

  const [treasuries, setTreasuries] = useState([]);

  useEffect(() => {
    loadTreasuries();
  }, [environment])

  useEffect(() => {
    if (!selected) return;
    onSetData()
  }, [selected])

  const loadTreasuries = async () => {
    if(!environment.treasuries) return;
    const tempTreasuries = environment.treasuries.map(treasury => ({
      value: treasury.tesoreria,
      label: treasury.nombre
    }))
    setTreasuries(tempTreasuries);
  }

  const onChange = async e => {
    let { name, value } = e;
    if(!value){
      value = '0';
    }
    value = Number(value.replaceAll(",", "."))
    setValue(name, Number(value));
    onSetData();
  }

  const onSetData = () => {
    const formData = getValues();
    const { cashSource } = formData;
    if(!cashSource){
      handleCashData(null)
      return;
    }
    handleCashData(formData);
  }

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Transacción en Efectivo"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {selected ? (
          <form>
            <Row>
              <Col lg={4}>
                <DetailRow title="Valor en Efectivo">
                  <FormGroup className="pr-4">
                    <CurrencyInput
                      className="form-control pl-2"
                      allowNegativeValue={false}
                      name="cashValue"
                      id="cashValue"
                      prefix='$'
                      intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                      {...register('cashValue')}
                      onValueChange={(value) => onChange({ name: 'cashValue', value })}
                      decimalScale={2}
                      maxLength={20}
                      placeholder="$ 0.00"
                      tabIndex="5" />
                  </FormGroup>
                </DetailRow>
              </Col>

              <Col lg={8}>
                <DetailRow title="Tesorería">
                  <FormGroup className="pr-4">
                    <WizardInput
                      className="pl-2"
                      type="select"
                      tag={CustomInput}
                      placeholder="Seleccionar"
                      id="cashSource"
                      name="cashSource"
                      options={treasuries}
                      onChange={(e) => onChange({ name: 'cashSource', value: e.target.value })}
                      errors={errors}
                      tabIndex="6"
                      innerRef={register({
                        required: true,
                      })}
                    />
                  </FormGroup>
                </DetailRow>
              </Col>
            </Row>
          </form>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default CashSection;
