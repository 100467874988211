import React, { useContext, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { PreliquidacionContext } from '../PreliquidacionContext';
import { idFormat } from '../../../helpers/tableFormatters';
import ClientInfoModal from '../modals/ClientInfoModal';
import ButtonIcon from '../../../components/common/ButtonIcon';

const ClientInfo = () => {
  const context = useContext(PreliquidacionContext);
  const { loading, liquidacion,  selected, handleUpdate } = context;

  const [editing, setEditing] = useState();

  const handleEdit = () => {
    setEditing(true);
  }

  const onEdit = (data) => {
    handleUpdate(data);
  }

  return (
    <>
    <ClientInfoModal 
      open={editing}
      data={selected}
      title="Edición Información del Cliente"
      toggle={() => setEditing(!editing)} 
      handleUpdate={onEdit}
    />
    <Card className={`my-3`} >
      <FalconCardHeader title="Información del Cliente">
        {!liquidacion && (
          <ButtonIcon onClick={handleEdit} icon={faEdit} transform="shrink-3 down-2" color="falcon-default" size="sm" className="mr-2">
            Editar
          </ButtonIcon>
        )}
      </FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {!loading && selected ? (
        <Row>
          <Col lg={12} xs={12}>
            <DetailRow title="Pagaduría">{selected.nomPagaduria || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg={6}>
            <DetailRow title="Identificación">
              {selected.tipoIdCliente} {idFormat(selected.idCliente)}
            </DetailRow>
            <DetailRow title="Teléfono">{selected.telefono || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg={6}>
            <DetailRow title="Razon Social o Nombre del Cliente" childrenClasses="text-uppercase">{selected.nomCliente || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Correo" childrenClasses="text-uppercase">{selected.correo || <span className='p-2'></span>}</DetailRow>
          </Col>
        </Row>
        ) : <></>}
      </CardBody>
    </Card>
    </>
  );
};

export default ClientInfo;
