import React, { Fragment, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PageHeader from '../../components/common/PageHeader';
import { formatDate, getTodayStr } from '../../helpers/dateFormatters';
import { simulatorServices } from '../../services';
import SimulatorDisplay from './SimulatorDisplay';
import SimulatorForm from './SimulatorForm';
import SimulatorPlanModal from './SimulatorPlanModal';

const SimulatorPage = (props) => {
  const [formData, setFormData] = useState({});
  const [fee, setFee] = useState();
  const [bonus, setBonus] = useState();
  const [planDate, setPlanDate] = useState();
  const [paymentsPlan, setPaymentsPlan] = useState();
  const [showPlan, setShowPlan] = useState();
  const [date, setDate] = useState(getTodayStr());
  const [selectedLine, setSelectedLine] = useState();

  const [disabledCutDate, setDisabledCutDate] = useState();
  const [lastDay, setLastDay] = useState();

  useEffect(() => {
    if (!fee || !planDate) {
      setPaymentsPlan(null);
      return;
    };
    calculatePlan(planDate);
  }, [fee])

  useEffect(() => {
    setSelectedLine(props.inLine)
  }, [props.inLine])

  useEffect(() => {
    if (!selectedLine) return;

    if (selectedLine == 1) {
      setDisabledCutDate(true);
      handleLibranzaCredits();
    } else {
      setLastDay(null);
      setDisabledCutDate(false)
    }
  }, [selectedLine, date])

  const handleFormError = () => {
    // setFormData();
    if (!props.handleSubmit) return;
    props.handleSubmit();
  }

  const handleLibranzaCredits = () => {
    if (!date) return;

    const tempDate = date.replaceAll("-", "/")

    const selectedDate = new Date(tempDate);
    const day = selectedDate.getDate();
    const month = selectedDate.getMonth();
    const year = selectedDate.getFullYear();

    const templastDay = day > 10
      ? new Date(year, month + 2, 0)
      : new Date(year, month + 1, 0)

    setLastDay(`${formatDate(templastDay)}#${uuidv4()}`);
  }

  const handleCalculate = async data => {
    setFormData(data)
    const resp = await simulatorServices.calculateFee(data);
    if (resp.error) return;
    setFee(resp.objResponse.valorCuota);
    setBonus(resp.objResponse.valorPrima);
    if (!props.handleSubmit) return;
    props.handleSubmit({ ...data, cuota: resp.objResponse.valorCuota });
  }

  const handleSolicitar = (event) => {
    if (!formData.amount || !formData.bonus || !formData.rate || !formData.term) {
      event.preventDefault();
      return;
    }
    window.simulacion = { ...formData, date };
    window.addTab({
      "tarea": "C02",
      "nombre": "Solicitud de Crédito",
      "icono": "eye",
      "tareas": null,
      "formulario": {
        "ruta": "/Solicitud"
      }
    });
  }

  const handleCalculatePlan = date => {
    setPlanDate(date);
    if (props.onChangeDate) {
      props.onChangeDate(date);
    }
    if (!fee) return;
    calculatePlan(date)
  }

  const calculatePlan = async date => {
    if (!date) return;
    const resp = await simulatorServices.getPaymentsPlan({ ...formData, line: selectedLine || 0 }, fee, date);
    if (resp.error) return;
    setPaymentsPlan(resp.objResponse)
  }

  const handleModal = (value, event) => {
    if (event) {
      event.preventDefault();
    }
    if (value && !planDate) return
    setShowPlan(value)
  }

  const handleFormDate = (date) => {
    setDate(date);
  }

  const handleSelectedLine = (value) => {
    setSelectedLine(value);
  }

  return (
    <Fragment>
      {paymentsPlan && (
        <SimulatorPlanModal plan={paymentsPlan} open={showPlan} collapseOneOpen={handleModal} />
      )}
      {!props.noHeader && (
        <PageHeader
          title="Simulador"
          className="mb-3"
        />
      )}
      <div className={`mb-3 w-100 ${props.noShadow ? 'shadow-none' : ''}`} style={{ backgroundColor: `${props.bgColor || 'white'}` }}>
        <SimulatorForm
          handleSelectedLine={handleSelectedLine}
          handleCalculate={handleCalculate}
          handleFormError={handleFormError}
          bonus={bonus}
          vertical={!!props.vertical}
          requiredMark={!!props.requiredMark}
          defaultData={props.defaultData}
          invisibleFields={props.invisibleFields}
          handleFormDate={handleFormDate}
          date={date}
        />
      </div>
      <SimulatorDisplay
        fee={fee}
        planDate={planDate}
        lastDay={props.lastDay || lastDay}
        minDate={props.minDate || date}
        handleCalculatePlan={handleCalculatePlan}
        handleClickPlan={handleModal}
        vertical={!!props.vertical}
        requiredMark={!!props.requiredMark}
        disabledDate={props.disabledDate || disabledCutDate}
        handleSolicitar={handleSolicitar}
      />
    </Fragment>
  )

}

export default SimulatorPage;
