import React from 'react';
import { Card, Input, Button, ModalBody, ModalFooter, Label, Form, CustomInput } from 'reactstrap';
import { urlApi } from '../../configuracion/serverConfig';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';

class ParamsForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
    };

    this.state = {
      EstadoForm: false,
      DataEdict: this.initialdata,
    };
  }

  componentDidMount() {
    const cols = this.props.table.columns;
    for (let index = 1; index < cols.length; index++) {
      const item = cols[index];
      let value = this.props.data[item.columnName];
      value = item.type == "REF" || item.type == "ENUM" && value ? item.options.find(i => i.value == value)
        : !value && item.type == "CHECK" ? '0' : value;
      this.setState(state => ({
        ...state,
        [item.columnName]: value
      }));
    }
  }

  onClick = e => {
    this.props.MostrarFormulario('Cancelar');
  };

  onInputchange = data => {
    if (data) {
      const name = data.target.name;
      const value = data.target.value;
      this.setState(state => ({
        ...state,
        [name]: value
      }));
    }
  };

  onSelect = (val, col) => {
    if (val) {
      const name = col.columnName;
      const value = val;
      let state = this.state;
      state[name] = value;
      if (this.props.keyTable == "Tesorerias") {
        if (col.columnName == "id_pais") {
          if (this.state.id_departamento) {
            state.id_departamento = null;
          }
        }
        if (col.columnName == "id_pais" || col.columnName == "id_departamento") {
          if (this.state.id_municipio) {
            state.id_municipio = null;
          }
        }
      }
      this.setState(state);
    }
  };

  agregar = e => {
    e.preventDefault();
    let ObjParam = {};
    ObjParam.id = this.props.data.id;
    const cols = this.props.table.columns;
    for (let index = 1; index < cols.length; index++) {
      const item = cols[index];
      ObjParam[item.columnName] = item.type == "REF" || item.type == "ENUM" ? this.state[item.columnName]?.value : this.state[item.columnName];
    }

    if (ObjParam.id) {
      fetch(urlApi + '/params/data/' + this.props.keyTable, {
        method: 'PUT',
        body: JSON.stringify(ObjParam),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
          if (data.status === 0) {
            cogoToast.success("El registro fue actualizado correctamente", { position: 'bottom-right', heading: 'Actualizado' });
            this.props.MostrarFormulario('Guardado');
          } else {
            cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
          }
        })
        .catch(err => console.log('err', err));
    } else {
      fetch(urlApi + '/params/data/' + this.props.keyTable, {
        method: 'POST',
        body: JSON.stringify(ObjParam),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
          if (data.status === 0) {
            cogoToast.success("El registro fue agregado correctamente", { position: 'bottom-right', heading: 'Agregado' });
            this.props.MostrarFormulario('Guardado');
          } else {
            cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
          }
        })
        .catch(err => console.log('err', err));
    }
  };

  CheckedEstado = (name) => {
    const state = {};
    state[name] = this.state[name] == '1' ? '0' : '1';
    this.setState(state);
  };

  getOptions = (col) => {
    if (this.props.keyTable == "Tesorerias" && col.columnName == "id_departamento") {
      if (this.state.id_pais) {
        return col.options.filter(i => i.record[2] == this.state.id_pais.value);
      }
      return [];
    }
    if (this.props.keyTable == "Tesorerias" && col.columnName == "id_municipio") {
      if (this.state.id_departamento) {
        return col.options.filter(i => i.record[2] == this.state.id_departamento.value);
      }
      return [];
    }
    return col.options;
  }

  render() {
    const fields = [];
    const cols = this.props.table.columns;
    for (let index = 1; index < cols.length; index++) {
      const item = cols[index];
      fields.push(
        <div className="row pt-3 " key={index}>
          <div className="flex col-md-12">
            <Label>{item.name}</Label>
            {item.type == "CHECK" ?
              <CustomInput
                type="switch"
                name={item.columnName}
                id={item.columnName}
                checked={this.state[item.columnName] == '1'}
                onChange={() => this.CheckedEstado(item.columnName)}
              /> : item.type == "REF" || item.type == "ENUM" ?
                <Select
                  className=""
                  style="fullWidth-select"
                  name={item.columnName}
                  onChange={val => this.onSelect(val, item)}
                  options={this.getOptions(item)}
                  value={this.state[item.columnName]}
                /> :
                <Input
                  type="text"
                  name={item.columnName}
                  defaultValue={this.state[item.columnName]}
                  onChange={this.onInputchange}
                />
            }
          </div>
        </div>);
    }
    return (
      <>
        <div>
          <Card>
            <div className="right-panel roe-box-shadow">
              <div className="">
                <Form onSubmit={this.agregar}>
                  <ModalBody>
                    <div className="form-group">
                      {fields}
                    </div>
                  </ModalBody>

                  <ModalFooter className="no-border">
                    <div className="row col-md-12">

                      <div className="col-md-10 text-right">
                        <Button
                          className="mr-1" color="primary" size="sm" outline
                          style={{ 'height': '36px' }}
                          type="submit"
                        >
                          <i className="mr-1">
                            {' '}
                            <FontAwesomeIcon icon={faSave} />
                          </i>
                          Guardar
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          className="mr-1" color="primary" size="sm" outline
                          style={{ 'height': '36px' }}
                          onClick={this.onClick}
                        >
                          <i className="mr-1">
                            {' '}
                            <FontAwesomeIcon icon={faUndo} />
                          </i>
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  </ModalFooter>

                </Form>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  }
}
export default ParamsForm;
