import React, { useState, useContext, forwardRef, useEffect } from 'react';
import {
  Input
} from 'reactstrap';

export default forwardRef(({ value, onClick }, ref) => (
  <Input
    onClick={onClick}
    ref={ref}
    placeholder="Rango de fechas"
    value={value}
  />
));