import React, { useState, useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import {
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
  FormGroup,
  Button,
} from 'reactstrap';
import cogoToast from 'cogo-toast';
import MainTable from '../../../components/tables/MainTable';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import { InfoMovesContext } from '../InfoMovesContext';
import { refFormat } from '../../../helpers/tableFormatters';
import { currencyFormat } from '../../../helpers/tableFormatters';
import { formatDate } from '../../../helpers/dateFormatters';
import ExampleCustomInput from '../../../components/input/ExampleCustomInput'
import WizardInput from '../../../components/auth/wizard/WizardInput';
import { EnvironmentContext } from '../../../context/Context';
import { informesServices } from '../../../services';
import { dateConceptFormatter, clientFormatter } from './MoveCaja';
import ExcelExport from '../../../components/excel/ExcelExport';
import PdfExport from '../../../components/pdf/table/export/PdfExport';

export const headers = [
  { label: "Movimiento", key: "movimiento", type: "string" },
  { label: "Fecha", key: "fecha", type: "string"  },
  { label: "Concepto", key: "nombreConcepto", type: "string"  },
  { label: "Identificación", key: "identificacionTercero", type: "string"  },
  { label: "Tercero", key: "nombreTercero", type: "string"  },
  { label: "Débito", key: "debito", type: "money"  },
  { label: "Crédito", key: "credito", type: "money"  },
];

const MoveInit = () => {
  const pageSize = 25;
  const context = useContext(InfoMovesContext);
  const environment = useContext(EnvironmentContext);
  const { loading, initMoves, handleInitSubmit, handleLoading } = context;

  /* Pagination control */
  const [totalData, setTotalData] = useState();
  const [listDataPag, setListDataPag] = useState({});
  const [pagesLoaded, setPagesLoaded] = useState([1]);

  /* Fields */
  const [listPagadurias, setListPagadurias] = useState([]);
  const [pagaduriaSelected, setPagaduriaSelected] = useState();
  const [dateRange, setDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 8)), new Date()]);
  const [parsedDateRange, setParsedDateRange] = useState([]);
  const [startDate, endDate] = dateRange;


  useEffect(() => {
    if(!environment) return;
    parsePagadurias();
  }, [environment])

  useEffect(() => {
    if(!dateRange || !handleDateRange) return;
    handleDateRange(dateRange);
  }, dateRange)

  const columns = [
    {
      dataField: 'movimiento',
      text: 'Movimiento',
      headerClasses: 'border-0',
      align: 'left',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField) => refFormat(dataField),
    },
    {
      dataField: 'fecha',
      text: 'Fecha / Concepto',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => dateConceptFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'nombreTercero',
      text: 'Tercero',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => clientFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'debito',
      text: 'Débito',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'credito',
      text: 'Crédito',
      headerClasses: 'border-0 text-right pr-3',
      align: 'right',
      classes: 'border-0 py-2 align-middle pr-3',
      sort: true,
      formatter: (value) => currencyFormat(value),
    }
  ];

  const parsePagadurias = () => {
    if(!environment.pagadurias) return;
    const tempListPagadurias = environment.pagadurias.map(pagaduria => ({
      value: pagaduria.id,
      label: pagaduria.nombreComercial || pagaduria.razonSocial
    }));

    setListPagadurias(tempListPagadurias);
  };

  const handleDateRange = (dates) => {
    if(!dates[0] || !dates[1]) return;
    const range = [];
    range[0] = dates[0] ? formatDate(dates[0]) : "";
    range[1] = dates[1] ? formatDate(dates[1]) : "";
    setParsedDateRange(range);
  }

  const onChangePagaduria = data => {
    if(!environment || !environment.pagadurias) return;
    if(!data || !data.value) {
      setPagaduriaSelected(null)
      return;
    }
    const pagaduriaIndex = environment.pagadurias.findIndex(p => p.id == data.value);
    setPagaduriaSelected(environment.pagadurias[pagaduriaIndex])
  }

  const onSubmit = async () => {

    if(!pagaduriaSelected || !parsedDateRange) {
      cogoToast.warn("Por favor completar los campos faltantes para la busqueda",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
    }

    const idPagaduria =  pagaduriaSelected.id;
    const startDate = parsedDateRange[0]
    const endDate = parsedDateRange[1]

    const response = await informesServices.getInitMoves({startDate, endDate, idPagaduria, page: 1, size: pageSize});
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar los movimientos' });
        handleInitSubmit([])
      return;
    }

    const { filas } = response.metadata || {filas: pageSize} 
    setTotalData(filas);
    handleInitSubmit(response.objResponse)
    const tempListDataPag = {};
    tempListDataPag['1'] = response.objResponse; 
    setListDataPag(tempListDataPag);
  }

  const handlePagination = async page => {
    if(pagesLoaded.includes(page) || initMoves.length < pageSize) return;
    handleLoading(true);
    setPagesLoaded([...pagesLoaded, page]);

    const idPagaduria =  pagaduriaSelected.id;
    const startDate = parsedDateRange[0]
    const endDate = parsedDateRange[1]

    const response = await informesServices.getInitMoves({startDate, endDate, idPagaduria, page, size: pageSize});
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar los movimientos' });
        handleInitSubmit([])
        handleLoading(false);
      return;
    }

    const temp = [];
    const tempListDataPag = listDataPag;
    tempListDataPag[String(page)] = response.objResponse;

    const maxPageLoaded = [...pagesLoaded, page].sort((a,b) => b - a)[0];
    Array.from({length: maxPageLoaded}, (_, i) => i + 1).map(p => {

      if(tempListDataPag[String(p)]){
        temp.push(...tempListDataPag[String(p)])
      } else {
        temp.push(...Array.from({length: pageSize}, (_, i) => ""))
      }
    })
    handleInitSubmit(temp);
    setListDataPag(tempListDataPag);
    handleLoading(false);
  }


  const getExportData = (type) => {
    return new Promise(async (resolve, reject) => {
      if(!initMoves || !initMoves[0] || !pagaduriaSelected){
        cogoToast.warn("No existen datos a exportar",
          { position: 'bottom-left', heading: 'Sin Datos' });
        reject(false);
        return;
      }

      const idPagaduria = pagaduriaSelected.id;
      const startDate = parsedDateRange[0]
      const endDate = parsedDateRange[1]
      const response = await informesServices.getInitMoves({startDate, endDate, idPagaduria});
      if (!response || response.error) {
        cogoToast.error(response.msg,
          { position: 'bottom-left', heading: 'Error al cargar los movimientos' });
        reject(false);
        return;
      }

      resolve(response.objResponse);
    })
  }
      
      
  return (
    <>
      <Card className={`my-3`} >
        <CardBody className="bg-light border-top px-4">
          <Row>
            <Col lg={4} className="mx-2">
              <DetailRow title="Rango de Fechas">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  locale="es"
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  customInput={<ExampleCustomInput />}
                />
              </DetailRow>
              
            </Col>
            <Col lg={3} className="mx-2">
              <DetailRow title="Pagaduría">
                <FormGroup>
                  <WizardInput
                    className="pl-2"
                    type="select"
                    tag={CustomInput}
                    value={pagaduriaSelected ? pagaduriaSelected.id : ''}
                    placeholder="Seleccionar"
                    id="pagaduria"
                    name="pagaduria"
                    options={listPagadurias}
                    onChange={(e) => onChangePagaduria({ name: 'pagaduria', value: e.target.value })}
                    errors={[]}
                  />
                </FormGroup>
              </DetailRow>
            </Col>
            <Col lg={2} className="mx-2">
              <DetailRow title="-">
                <Button onClick={onSubmit} transform="shrink-3 down-2" color="primary" >
                  Buscar 
                </Button>
              </DetailRow>
            </Col>
            <Col lg={2} className="mx-2">
              <DetailRow title="-" titleClass="text-white">
                <ExcelExport 
                  className="mr-2"
                  data={[]} 
                  headers={headers} 
                  fileName="informe-movimientos" 
                  async 
                  getData={getExportData} 
                  disabled={!initMoves || !initMoves[0]}/>
                <PdfExport 
                  data={[]} 
                  headers={headers} 
                  fileName="informe-movimientos" 
                  async 
                  getData={getExportData} 
                  disabled={!initMoves || !initMoves[0]}/>
              </DetailRow>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <MainTable
        title="Movimientos"
        data={initMoves}
        totalData={totalData}
        pageSize={pageSize}
        columns={columns}
        handlePagination={handlePagination}
        keyField="id"
        className="px-2"
        loading={loading}
        noSearch
        noAdd
        customPagination
      />
    </>
  )
}

export default MoveInit