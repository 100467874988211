import React, { useContext, useState } from 'react';
import { Row, Card, CardBody, Col, FormGroup } from 'reactstrap';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import CreditInfo from './sections/CreditInfo';
import ClientInfo from './sections/ClientInfo';
import { DesembolsoContext } from './DesembolsoContext';
import AsideLayout from './sections/AsideLayout'
import Deductions from './sections/Deductions';
import CashSection from './sections/CashSection';
import WireTransferSection from './sections/WireTransferSection';
import ChequeSection from './sections/ChequeSection';
import { useForm } from 'react-hook-form';
import DetailRow from '../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import WizardInput from '../../components/auth/wizard/WizardInput';
import CustomInput from 'reactstrap/lib/CustomInput';
import CurrencyInput from 'react-currency-input-field';

const options = [
  {
    label: 'CC-Cédula de Ciudadania',
    value: 'CC'},
    {label: 'CE-Cédula de Extranjería',
    value: 'CE'},
    {label: 'PS-Pasaporte',
    value: 'PS'},
];

const PrestamoDetails = ({tercero}) => {
  const context = useContext(DesembolsoContext);
  const { loading, isInit, selected } = context;
  
  const { register, errors } = useForm(/*{
    defaultValues: {
      wireTransferValue: 0,
    }
  }*/);

  const [ pagoTercero, setPagoTercero ] = useState(false);

  const cambiarPagoTercero = (ev) => {
    setPagoTercero(tercero.pagoTercero = ev.target.checked);
  };

  const changeTercero = (input) => {
    tercero[input.name] = input.value;
  };

  return (
    <>
      <ContentWithAsideLayout aside={<AsideLayout></AsideLayout>} isStickyAside={false}>
          <CreditInfo />
          <ClientInfo />
          {!isInit && (
            <>
              <Deductions />

    <Card className={`my-3`} >
      <FalconCardHeader title="Pago a Tercero Autorizado">
      <div className="p-0 pb-0" >
                  <FormGroup className="text-center m-0 d-flex align-items-center justify-content-center">
                  <input 
                    type="checkbox"
                    id="pagoTercero"
                    tabIndex="14"
                    checked={tercero.pagoTercero}
                    onChange={(ev) => cambiarPagoTercero(ev)}
                  />
                  <label for="pagoTercero" className='m-0 ml-3'>Autorizar</label>
                  </FormGroup>
                </div>
      </FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {selected ? (
          <form>
          <Row form>
          <Col>
          <label>Tipo de Identificacion*</label>
              <WizardInput
                    type="select"
                    tag={CustomInput}
                    placeholder="Tipo de Identificacion"
                    name="tipoDocumento"
                    value={tercero.tipoDocumento}
                    errors={errors}
                    options={options}
                    innerRef={register({
                      required: 'Tipo Identificacion',
                    })}
                    disabled={!pagoTercero}
                    onChange={(ev) => changeTercero(ev.target)}
                  />
                </Col>
            
            <Col>
              <WizardInput
                type="text"
                label="Numero Identificación*"
                value={tercero.numeroIdentificacion}
                // placeholder="Name"
                name="numeroIdentificacion"
                innerRef={register({
                  required: 'Name is required',
                  minLength: {
                    value: 2,
                    message: 'Min length 2'
                  }
                })}
                disabled={!pagoTercero}
                errors={errors}
                onChange={(ev) => changeTercero(ev.target)}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <WizardInput
                type="text"
                label="Nombres*"
                value={tercero.nombres}
                name="nombres"
                errors={errors}
                disabled={!pagoTercero}
                onChange={(ev) => changeTercero(ev.target)}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Apellidos*"
                value={tercero.apellidos}
                name="apellidos"
                errors={errors}
                disabled={!pagoTercero}
                onChange={(ev) => changeTercero(ev.target)}
              />
            </Col>
          </Row>
          </form>
        ) : <></>}
      </CardBody>
    </Card>
              
              <CashSection />
              <WireTransferSection />
              <ChequeSection />
            </>
          )}
      </ContentWithAsideLayout>
    </>
  )
}

export default PrestamoDetails