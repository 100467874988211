import React, { useContext, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, CustomInput, FormGroup, Label, Row, Spinner } from 'reactstrap';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import SimulatorPage from '../../simulator/SimulatorPage';
import { SolicitudContext } from '../SolicitudContext';

const GeneralConditions = () => {
  const [selectedLine, setSelectedLine] = useState();
  const { register, triggerValidation, errors, setValue, getValues } = useForm();
  const {
    date,
    formCreditInfo,
    creditLinesOptions,
    creditSublinesOptions,
    disabledCutDate,
    onSave,
    lastDay,
    handleCreditForm,
    handleCutDate,
    handleCreditInfoForm,
    loadCreditSublines,
    onChangeLine,
    loading } = useContext(SolicitudContext);

  const onChange = async e => {
    const { name, value } = e.target;
    if (name === 'creditLine') {
      setSelectedLine(value);
      loadCreditSublines(value);
      onChangeLine(value);
      setValue('creditSubline', null)
    }
    setValue(name, value);
    const formCreditInfo = getValues();
    await triggerValidation();
    const isValid = Object.keys(errors).length < 1;
    handleCreditInfoForm(isValid ? formCreditInfo : null);
  }

  return (
    <Card className={`my-3`} style={{ backgroundColor: "#1b97ebd1", color: "white" }} >
      <FalconCardHeader title="Condiciones Generales"></FalconCardHeader>
      <CardBody className="border-top">
        <form onChange={onChange}>
          <Row>
            <Col xl={12}>
              <FormGroup>
                <Label for="creditLine">
                  <span className='mr-1 text-danger'>*</span>
                  Línea de Crédito
                </Label>
                <WizardInput
                  type="select"
                  tag={CustomInput}
                  placeholder="Seleccionar"
                  id="creditLine"
                  name="creditLine"
                  options={creditLinesOptions.map(item => (
                    { label: item.nombre, value: item.id }
                  ))}
                  errors={errors}
                  innerRef={register({
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
            <Col xl={12}>
              <FormGroup>
                <Label for="creditSubline">
                  <span className='mr-1 text-danger'>*</span>
                  Sublínea de Crédito
                </Label>
                <WizardInput
                  type="select"
                  tag={CustomInput}
                  placeholder="Seleccionar"
                  id="creditSubline"
                  name="creditSubline"
                  options={creditSublinesOptions.map(item => (
                    { label: item.nombre, value: item.id }
                  ))}
                  errors={errors}
                  innerRef={register({
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
            <Col xl={12}>
              <FormGroup>
                <Label for="creditNumber">
                  <span className='mr-1 text-danger'>*</span>
                  Nro. del Crédito
                </Label>
                {/* <Input
                  id="creditNumber"
                  name="creditNumber"
                  {...register("creditNumber", {
                    required: true,
                  })}
                /> */}
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  allowDecimals={false}
                  disableGroupSeparators={true}
                  // defaultValue={term}
                  name="creditNumber"
                  id="creditNumber"
                  // onValueChange={(v) => setTerm(v)}
                  {...register("creditNumber", {
                    required: true,
                  })}
                  decimalsLimit={0}
                  maxLength={20}
                  placeholder="0" />
              </FormGroup>
            </Col>
          </Row>
        </form>
        <SimulatorPage
          handleSubmit={handleCreditForm}
          inLine={selectedLine}
          onChangeDate={handleCutDate}
          minDate={date}
          lastDay={lastDay}
          bgColor="transparent"
          invisibleFields={{ date: true, creditLine: true }}
          disabledDate={disabledCutDate}
          vertical
          noShadow
          noHeader
          requiredMark />
        <Row className="mt-4">
          <Col lg={6} xs={12}>
            <Button className="w-100" transform="shrink-3 down-2" color="falcon-default" >
              {loading ? (
                <Spinner size="sm" color="primary" />
              ) : "Cancelar"}
            </Button>
          </Col>
          <Col lg={6} xs={12}>
            <Button onClick={onSave} className="w-100" transform="shrink-3 down-2" color="primary" >
              {loading ? (
                <Spinner size="sm" color="light" />
              ) : "Guardar"}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
};

export default GeneralConditions;
